import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Alert} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Clipboard from 'react-clipboard.js';
import {CodingQuestionDto, CodingQuestionExample} from '../../scaffold';
import MarkdownRender from '../Shared/MarkdownRender';

function QuestionExample(props: {example: CodingQuestionExample}) {
  return (
    <div className="question-example ">
      <div className="example-row">
        <div className="example-row-head">Input</div>
        <div className="example-row-content">
          <TextArea disabled value={props.example.input || ''} autoSize />
          <Clipboard className="clipboard text-maya-blue massa-hover-azure" component="a" data-clipboard-text={props.example.input}>
            复制一下
          </Clipboard>
        </div>
      </div>
      <div className="example-row">
        <div className="example-row-head">Output</div>
        <div className="example-row-content">
          <TextArea disabled value={props.example.output || ''} autoSize />
          <Clipboard className="clipboard text-maya-blue massa-hover-azure" component="a" data-clipboard-text={props.example.output}>
            复制一下
          </Clipboard>
        </div>
      </div>
      {props.example.note && (
        <div className="example-row ">
          <div className="example-row-head">Note</div>
          <div className="example-row-content p-20">
            <MarkdownRender md={props.example.note} className={'smallMarkdown'} />
          </div>
        </div>
      )}
    </div>
  );
}

export default function CodingQuestionContentSection(props: {question: CodingQuestionDto}) {
  return (
    <React.Fragment>
      <h3 style={{marginLeft: 5, marginBottom: 0}}>题目描述</h3>
      <MarkdownRender md={props.question.description || ''} />
      {props.question.inputFormat && (
        <React.Fragment>
          <h3 style={{marginLeft: 5, marginBottom: 0}}>输入格式</h3>
          <MarkdownRender md={props.question.inputFormat} />
          {props.question.inputFileName && (
            <Alert
              showIcon
              style={{marginTop: -15, marginBottom: 20, width: 500}}
              message={'本题采用文件输入，输入文件名为: ' + props.question.inputFileName}
            />
          )}
        </React.Fragment>
      )}
      {props.question.outputFormat && (
        <React.Fragment>
          <h3 style={{marginLeft: 5, marginBottom: 0}}>输出格式</h3>
          <MarkdownRender md={props.question.outputFormat} />

          {props.question.outputFileName && (
            <Alert
              showIcon
              style={{marginTop: -15, marginBottom: 20, width: 500}}
              message={'本题采用文件输出，输出文件名为: ' + props.question.outputFileName}
            />
          )}
        </React.Fragment>
      )}
      {props.question.dataRange && (
        <React.Fragment>
          <h3 style={{marginLeft: 5, marginBottom: 0}}>数据范围</h3>
          <MarkdownRender md={props.question.dataRange} />
        </React.Fragment>
      )}
      {props.question.examples?.map((example, i) => (
        <div key={i}>
          <h3 style={{marginLeft: 5, marginBottom: 0}}>样例数据 {i + 1}</h3>
          <QuestionExample example={example} />
        </div>
      ))}
      <Alert showIcon message={`本题时间限制: ${props.question.limitTime} ms，空间限制: ${props.question.limitMemory} MB`} />
    </React.Fragment>
  );
}
