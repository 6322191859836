import React from 'react';
import {ITabLayoutProps, ITabLayoutRouteConfig, ITabLayoutRouteConfigMenu} from './TabLayout';
import './TabLayoutSidebar.less';
import {LogoutOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {useTabLayout} from './TabLayoutProvider';
import {Divider, Popover, Typography} from 'antd';

function SidebarItem(props: {menu: ITabLayoutRouteConfigMenu; goTo: (path: string) => void}) {
  const {menu, goTo} = props;
  function click() {
    if (menu.path) {
      goTo(menu.path);
    }
  }
  const item = (
    <div className={'TabLayoutSidebarItem'} onClick={click}>
      <div className={'TabLayoutSidebarItemIcon'}>{menu.icon}</div>
      <div className={'TabLayoutSidebarItemTitle'}>{menu.title}</div>
    </div>
  );
  return !!menu.submenus ? (
    <Popover
      placement={'right'}
      content={
        <div className={'TabLayoutSidebarPopover'}>
          {menu.submenus.map(submenu => (
            <div key={submenu.title}>
              <Typography.Text type={'secondary'} className={'TabLayoutSidebarPopoverSubtitle'}>
                {submenu.title}
              </Typography.Text>
              {submenu.submenus?.map(item => (
                <div key={item.path} className={'TabLayoutSidebarLinkItem'} onClick={() => item.path && goTo(item.path)}>
                  <Typography.Text style={{fontSize: 16}}>{item.title}</Typography.Text>
                </div>
              ))}
            </div>
          ))}
        </div>
      }>
      {item}
    </Popover>
  ) : (
    item
  );
}

export default function TabLayoutSidebar(props: {routeConfig: ITabLayoutRouteConfig; logout?: () => any}) {
  const tabLayout = useTabLayout();
  function goTo(path: string) {
    if (path.includes('http://')) {
      window.open(path, '_blank');
    } else {
      tabLayout.goTo(path);
    }
  }
  return (
    <div className={'TabLayoutSidebar'}>
      <div>
        {props.routeConfig.menus.map(menu => (
          <SidebarItem menu={menu} key={menu.title} goTo={goTo} />
        ))}
      </div>
      <div className={'TabLayoutSidebarItem'} onClick={props.logout}>
        <div className={'TabLayoutSidebarItemIcon'}>
          <LogoutOutlined style={{color: '#cf1322'}} />
        </div>
        <div className={'TabLayoutSidebarItemTitle'}>注销</div>
      </div>
    </div>
  );
}
