import React from 'react';
import {Avatar, Button, Divider, Input, message, Popover, Space, Typography} from 'antd';
import {DashboardOutlined, NotificationTwoTone} from '@ant-design/icons';
import useAuth from '../../utils/AuthContext';
import Text from 'antd/lib/typography/Text';
import {Config} from '../../config';
import {useInput} from 'react-hanger';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {UserApi} from '../../scaffold';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useCluster} from '../../utils/ClusterProvider';
export default function LayoutHeader() {
  const auth = useAuth();
  const cluster = useCluster();
  const password = useInput();
  const updatePassword = useOpenApiFpRequest(UserApi, UserApi.prototype.userUpdatePasswordPost);
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', padding: 10, alignItems: 'center'}}>
      <Space>
        <img src={cluster.cluster?.icon} style={{height: 34}} alt={'logo'} className={'m-l-5'} />
        <Text style={{fontSize: 18, fontWeight: 'bold'}}>{cluster.cluster?.title}</Text>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <NotificationTwoTone twoToneColor="#fa541c" style={{marginLeft: 10, fontSize: 18}} />
          <Typography.Text style={{fontSize: 10, color: '#fa541c', marginLeft: 5}}>暂无公告</Typography.Text>
        </div>
      </Space>
      <Space>
        <Popover
          title={'修改密码'}
          content={
            <Space>
              <Input.Password {...password.eventBind} placeholder={'请输入新密码'} />
              <Button
                danger
                loading={updatePassword.loading}
                onClick={() => {
                  updatePassword
                    .request({
                      userUpdatePasswordParams: {
                        password: password.value,
                        id: auth.currentUser?.id || 0,
                      },
                    })
                    .then(() => message.success('修改成功'))
                    .catch(CommonApiErrorHandler);
                }}>
                确定修改
              </Button>
            </Space>
          }
          trigger={'click'}>
          <a>
            <Avatar style={{backgroundColor: '#fa541c'}} className={'m-r-10'}>
              {auth.currentUser?.name}
            </Avatar>
            <Typography.Text strong={true}>{auth.currentUser?.name}</Typography.Text>
          </a>
        </Popover>

        <Divider type={'vertical'} />
        <div style={{display: 'flex', margin: '0 10px 0 10px', alignItems: 'center', cursor: 'pointer'}}>
          <DashboardOutlined className={'m-r-10'} />v{Config.version}
        </div>
      </Space>
    </div>
  );
}
