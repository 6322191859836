import {useTabLayout} from '../TabLayout/TabLayoutProvider';

export default function useQuickRouter() {
  const tabLayout = useTabLayout();
  return {
    paperPreview(id: number) {
      return tabLayout.goTo('/manage/paper/preview/' + id);
    },
  };
}
