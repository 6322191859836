import React from 'react';
import './TabLayoutHeader.less';
import {useTabLayout} from './TabLayoutProvider';
import {CloseOutlined} from '@ant-design/icons';
import {Tooltip, Typography} from 'antd';
import {useLocation} from 'react-router-dom';
import {ITabLayoutRouteConfigRouteItem} from './TabLayout';
export interface ITabLayoutHeaderProps {
  customHeader?: any;
  routes: ITabLayoutRouteConfigRouteItem[];
}
export default function TabLayoutHeader(props: ITabLayoutHeaderProps) {
  const tabLayout = useTabLayout();
  const location = useLocation();
  return (
    <div className={'TabLayoutHeader'}>
      <div>{props.customHeader}</div>
      <div className={'TabLayoutHeaderTabs'}>
        {tabLayout.currentTabList.map((tab, index) => {
          const isActive = location.pathname === tab.path;
          const routeItem = props.routes.find(route => route.path === tab.path);
          let title = tabLayout.titleOfPath[tab.path];
          if (!title && routeItem) {
            title = routeItem.title || '';
          }
          return (
            <div key={tab.path} onClick={() => tabLayout.goTo(tab.path)} className={'AppTab ' + (isActive ? 'AppTabActive' : '')}>
              <Tooltip title={title || tab.title} placement={'bottom'} mouseEnterDelay={0.5}>
                <Typography.Text style={{width: 0, flex: 1}} ellipsis className={'mr-sm'}>
                  {title || tab.title}
                </Typography.Text>
              </Tooltip>
              {index !== 0 && (
                <CloseOutlined
                  className={'AppTabClose'}
                  onClick={e => {
                    e.stopPropagation();
                    tabLayout.closeTab(tab.path);
                  }}
                />
              )}
            </div>
          );
        })}
        {/*<div  className={'AppTabsPlus'} onClick={() => appLayout.onNavClick('/links')}>*/}
        {/*  <PlusCircleFilled />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
