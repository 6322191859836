import React, {useEffect} from 'react';

import {Button, Divider, Space, Spin} from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {CourseArticleApi} from '../../scaffold';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import Flex from '../Shared/Flex';
import MarkdownRender from '../Shared/MarkdownRender';
import PdfViewer from '../Shared/PdfViewer';

export default function CourseArticleDetail(props: {detailId: number; solved: () => any}) {
  const queryByIdHook = useOpenApiFpRequest(CourseArticleApi, CourseArticleApi.prototype.courseArticleFindGet);
  const article = queryByIdHook.data;
  useEffect(() => {
    if (props.detailId) {
      queryByIdHook.request({id: props.detailId}).catch(CommonApiErrorHandler);
    }
  }, [props.detailId]);

  return (
    <div>
      {queryByIdHook.loading ? (
        <Flex align={'center'} justify={'center'} style={{height: 500}}>
          <Spin size={'large'} tip={'加载中'} />
        </Flex>
      ) : (
        <div>
          {article && (
            <div>
              <Flex className={'p-20 b-b-1'} justify={'space-between'} align={'center'}>
                <div>
                  <Title level={3} style={{marginBottom: 5}}>
                    {article.topic}
                  </Title>
                </div>
              </Flex>
              <div className={'p-20 bb'}>
                {article.content && <MarkdownRender md={article.content || ''} />}
                {article.pdfUrl && <PdfViewer url={article.pdfUrl} />}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
