import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {CodingSubmissionApi, CodingSubmissionDto, CourseApi, CourseLessonDto, ExamApi, QuestionApi, QuestionType} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useList, useMount} from 'react-use';
import {Button, Card, Col, Divider, Input, message, Modal, PageHeader, Popover, Row, Space} from 'antd';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import Text from 'antd/lib/typography/Text';
import SortableList from '../../../components/Shared/SortableList';
import {useInput} from 'react-hanger';
import CourseLessonManage from '../../../components/Course/CourseLessonManage';

export default function ManageCourseLessonPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseFindGet);
  const saveCourseState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseSavePost);
  const addLessonState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseAddLessonPost);
  const tabLayout = useTabLayout();
  const [lessonList, lessonListHook] = useList<CourseLessonDto>([]);
  const [selectedLesson, setSelectedLesson] = useState<CourseLessonDto>();
  const addLessonName = useInput('');

  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle('课程编辑: ' + res?.name);
        lessonListHook.set(res.lessons || []);
        if (selectedLesson) {
          setSelectedLesson(res.lessons?.find(i => i.id === selectedLesson.id));
        }
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader
        title={'课程编辑: ' + findState.data?.name}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button onClick={refresh}>刷新</Button>
          </Space>
        }
      />
      <Row className={'p-20'} style={{overflowY: 'auto'}} gutter={20}>
        <Col span={8}>
          <Card
            title={'章节列表'}
            loading={findState.loading}
            style={{padding: 0}}
            extra={
              <Popover
                title={'添加章节'}
                trigger={'click'}
                content={
                  <Space>
                    <Input placeholder={'输入新章节名称'} {...addLessonName.eventBind} />
                    <Button
                      loading={addLessonState.loading}
                      onClick={() => {
                        addLessonState
                          .request({
                            addLessonParams: {
                              id: findState.data?.id || 0,
                              lessonName: addLessonName.value,
                            },
                          })
                          .then(() => {
                            addLessonName.clear();
                            message.success('添加成功');
                            refresh();
                          })
                          .catch(CommonApiErrorHandler);
                      }}>
                      添加
                    </Button>
                  </Space>
                }>
                <Button>添加章节</Button>
              </Popover>
            }>
            <SortableList
              list={lessonList}
              afterSort={newList => {
                lessonListHook.set(newList);
                if (findState.data) {
                  saveCourseState
                    .request({
                      saveCourseParams: {
                        id: findState.data.id,
                        name: findState.data.name || '',
                        description: findState.data.description || '',
                        isHide: !!findState.data.isHide,
                        isPublic: !!findState.data.isPublic,
                        lessonSortIds: newList.map(i => i.id as number),
                      },
                    })
                    .then(() => {
                      message.success('排序成功');
                      refresh();
                    })
                    .catch(CommonApiErrorHandler);
                }
              }}
              renderItem={(lesson, index) => (
                <div className={'p-10 b-1'} style={{flex: 1}} onClick={() => setSelectedLesson(lesson)}>
                  <Text style={{fontSize: 16}}>
                    第 {index + 1} 章: {lesson.name}
                  </Text>
                </div>
              )}
            />
          </Card>
        </Col>
        <Col span={16}>
          {selectedLesson && (
            <CourseLessonManage index={lessonList.indexOf(selectedLesson) + 1} lesson={selectedLesson} refresh={refresh} loading={findState.loading} />
          )}
        </Col>
      </Row>
    </div>
  );
}
