import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {CodingSubmissionApi, CodingSubmissionDto, ExamApi, QuestionApi, QuestionType, UserApi} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useList, useMount} from 'react-use';
import {Button, Card, Divider, Modal, PageHeader, Select, Space, Spin} from 'antd';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import CodingSubmissionTable from '../../../components/CodingSubmission/Table';
import BatchActionProgress, {useBatchProgress} from '../../../components/Shared/BatchAction';
import {useBoolean} from 'react-hanger';
import Text from 'antd/lib/typography/Text';
import Flex from '../../../components/Shared/Flex';

export default function ManageCodingSubmissionPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionFindCodingQuestionGet);
  const tabLayout = useTabLayout();
  const [selectedList, setSelectedList] = useState<CodingSubmissionDto[]>([]);
  const resubmitState = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionResubmitPost);
  async function remove(submission: CodingSubmissionDto) {
    await resubmitState.request({findParams: {id: submission.id || 0}});
  }
  const [groupId, setGroupId] = useState<number>();
  const listGroup = useOpenApiFpRequest(UserApi, UserApi.prototype.userListGroupGet);
  const analyseUserSolved = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionAnalyseUserSolvedGet);
  const batchProgressState = useBatchProgress({rows: selectedList, action: remove});
  const isResubmit = useBoolean(false);
  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle('提交记录: ' + res?.topic);
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
    listGroup.requestSync({pi: 1, ps: 999});
  });

  useEffect(() => {
    if (groupId && findState.data?.id) analyseUserSolved.requestSync({groupId, codingQuestionId: findState.data?.id});
  }, [groupId, findState.data?.id]);

  return (
    <div>
      <PageHeader
        title={'提交记录: ' + findState.data?.topic}
        style={{background: 'white'}}
        extra={
          <Space>
            <Select value={groupId} onChange={setGroupId} placeholder={'筛选班级'} style={{width: 200}} allowClear>
              {listGroup.data?.map(g => (
                <Select.Option value={g.id as number} key={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
            {selectedList.length > 0 && (
              <Button danger onClick={isResubmit.setTrue}>
                重测选中提交
              </Button>
            )}
            <Button onClick={refresh}>刷新</Button>
          </Space>
        }
      />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        {!groupId && (
          <Card className={'m-b-15'} loading={findState.loading}>
            {findState.data && <CodingSubmissionTable query={{codingQuestionId: findState.data?.id}} selectedList={selectedList} onChange={setSelectedList} />}
          </Card>
        )}
        {groupId && (
          <Spin spinning={analyseUserSolved.loading}>
            <Flex className={'m-b-15'} style={{flexWrap: 'wrap'}}>
              {analyseUserSolved.data &&
                analyseUserSolved.data.map((item, i) => {
                  const detail = item.details && item.details[0];
                  return (
                    <Card size={'small'} key={i} className={'m-5'} style={{width: 180}}>
                      <Flex justify={'space-between'} align={'center'}>
                        <div>
                          <Text>{item.user?.name}</Text>
                          <div>
                            <Text type={'secondary'} style={{fontSize: 12}}>
                              {item.user?.username}
                            </Text>
                          </div>
                        </div>
                        {detail?.isSolved && (
                          <Text strong style={{color: 'green'}}>
                            已完成
                          </Text>
                        )}
                        {!detail?.isSolved && detail?.submittedCount !== 0 && (
                          <Text strong style={{color: 'orange'}}>
                            尝试 {detail?.submittedCount} 次
                          </Text>
                        )}
                        {!detail?.isSolved && detail?.submittedCount === 0 && (
                          <Text strong style={{color: 'gray'}}>
                            未提交
                          </Text>
                        )}
                      </Flex>
                    </Card>
                  );
                })}
            </Flex>
          </Spin>
        )}

        <Modal title={'批量重测'} visible={isResubmit.value} footer={false} onCancel={isResubmit.setFalse}>
          <BatchActionProgress
            state={batchProgressState}
            onProgressText={'正在请求重测'}
            successText={`成功请求重测 ${selectedList.length} 项提交`}
            onSuccess={() => {
              refresh();
              isResubmit.setFalse();
              setSelectedList([]);
              batchProgressState.init();
            }}>
            <div className={'massa-dialog-subtitle'}>
              <Text type={'secondary'}>
                即将重测 <a>{selectedList.length} 条提交</a>
              </Text>
            </div>
            <div className={'massa-dialog-footer'}>
              <Button onClick={batchProgressState.start} danger className={'m-r-5'}>
                确认
              </Button>
              <Button onClick={isResubmit.setFalse}>取消</Button>
            </div>
          </BatchActionProgress>
        </Modal>
      </div>
    </div>
  );
}
