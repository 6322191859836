import React from 'react';
import {CourseLessonItemDto, CourseLessonItemType} from '../../scaffold';
import article from '../../assets/article-icon.png';
import example from '../../assets/example-icon.png';
import question from '../../assets/question-icon.png';
export default function CourseLessonDetailIcon(props: {detail: CourseLessonItemDto}) {
  if (props.detail.type === CourseLessonItemType.Article) {
    return (
      <div className="DetailItemIcon DetailItemIconArticle">
        <img src={article} alt="" />
      </div>
    );
  }
  if (props.detail.type === CourseLessonItemType.ExampleCodingQuestion) {
    return (
      <div className="DetailItemIcon DetailItemIconExample">
        <img src={example} alt="" />
      </div>
    );
  }
  return (
    <div className="DetailItemIcon DetailItemIconQuestion">
      <img src={question} alt="" />
    </div>
  );
}
