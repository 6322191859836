import React from 'react';
import {useAuthContext} from 'geestack-toolbox';
import {IUser} from '../../hooks/User';
import {PageHeader, Result} from 'antd';

const WorkingIndexPage: React.FunctionComponent<any> = () => {
  // const auth = useAuthContext<IUser>();
  return (
    <div>
      <PageHeader title={'正在开发中'} style={{background: 'white'}} />
      <Result status="500" title="正在开发中" subTitle="本页面正在开发中" />
    </div>
  );
};

export default WorkingIndexPage;
