import React, {useEffect, useState} from 'react';
import {Button, Spin} from 'antd';
import {CourseApi, CourseDto, CourseLessonItemType} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import CourseLock from './Lock';
import Flex from '../Shared/Flex';
import LessonItem from './LessonItem';

export default function CourseDetailPanel(props: {
  course: CourseDto;
  detailMode: boolean;
  isBlur: boolean;
  goToDetail: (type: CourseLessonItemType, id: number) => any;
  setIsActive: (v: boolean) => any;
}) {
  const [isActive, setIsActive] = useState(false);
  const queryByIdHook = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseFindGet);
  function refresh() {
    setIsActive(false);
    props.setIsActive(false);
    queryByIdHook
      .request({id: props.course.id})
      .then(() => {
        setIsActive(true);
        props.setIsActive(true);
      })
      .catch(CommonApiErrorHandler);
  }
  useEffect(() => {
    refresh();
  }, [props.course]);

  return (
    <div className={`CourseDetailPanel ${props.detailMode ? 'CourseDetailPanelDetailMode ' : ''} ${props.isBlur ? 'massa-blur' : ''}`}>
      <CourseLock isActive={isActive} course={props.course} refresh={refresh} />
      {queryByIdHook.loading && (
        <Flex align={'center'} justify={'center'} style={{height: 500}}>
          <Spin size={'large'} tip={'加载中'} />
        </Flex>
      )}
      {isActive && queryByIdHook.data && (
        <React.Fragment>
          <div className="LessonListHead">
            <strong>已完成:</strong> <strong> {queryByIdHook.data.solvedCount} / </strong> <span>{queryByIdHook.data.totalCount}</span>
          </div>
          <div className={'LessonList'}>
            {queryByIdHook.data.lessons?.map((lesson, i) => (
              <LessonItem isBlur={props.isBlur} lesson={lesson} key={lesson.id} index={i + 1} goToDetail={props.goToDetail} />
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
