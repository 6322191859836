import * as React from 'react';
export default function UploadButton(props: {
  id: string;
  label?: string;
  loading: boolean;
  accept?: string;
  multiple?: boolean;
  upload: (formData: FormData) => any;
}) {
  function upload() {
    const formData = new FormData(document.getElementById(props.id) as HTMLFormElement);
    props.upload(formData);
  }
  return (
    <div id="upload-button">
      <div className="ant-btn file ant-btn-primary">
        {props.loading && <span>上传中</span>}
        {!props.loading && <span>{props.label ? props.label : '上传文件'}</span>}
        {!props.loading && (
          <form id={props.id}>
            <input onChange={() => upload()} type="file" multiple={props.multiple} name="file" accept={props.accept ? props.accept : ''} />
          </form>
        )}
      </div>
    </div>
  );
}
