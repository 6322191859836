import * as React from 'react';
import 'react-markdown-editor-lite/lib/index.css';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownParser from '../../utils/MarkdownParser';
import useAuth from '../../utils/AuthContext';
import {useAxios} from '../../Http/AxiosProvider';
import {PluginProps} from 'react-markdown-editor-lite';
import {FileOutlined, LoadingOutlined} from '@ant-design/icons';
import {useBoolean} from 'react-hanger';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {Upload} from 'antd';

interface CounterState {
  num: number;
}

const Counter = (props: PluginProps) => {
  const axios = useAxios();
  const loading = useBoolean(false);
  function beforeUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    loading.setTrue();
    axios.instance
      .post<string>('User/UploadFile', formData)
      .then(res => {
        props.editor.insertText(`[下载: ${file.name}](${res.data})`);
        console.log(res.data);
      })
      .finally(loading.setFalse)
      .catch(CommonApiErrorHandler);
    return false;
  }

  return (
    <span className="button button-type-counter" title="Counter">
      <Upload beforeUpload={beforeUpload} showUploadList={false} disabled={loading.value}>
        {loading.value ? <LoadingOutlined /> : <FileOutlined />}
      </Upload>
    </span>
  );
};

Counter.align = 'left';
Counter.pluginName = 'counter';
MdEditor.use(Counter);
export default function ExMdEditor(props: {value?: string; onChange?: (text: string) => any; height?: string}) {
  const mdParser = MarkdownParser;
  const axios = useAxios();
  function handleImageUpload(file: any, callback: any) {
    const reader = new FileReader();
    reader.onload = async () => {
      const formData = new FormData();
      formData.append('file', file);
      const res = await axios.instance.post<string>('User/UploadImage', formData);
      callback(res.data);
    };
    reader.readAsDataURL(file);
  }
  return (
    <MdEditor
      value={props.value}
      style={{height: props.height || '600px'}}
      renderHTML={text => mdParser.render(text)}
      htmlClass={'markdown-here-wrapper'}
      onChange={({text}) => {
        if (props.onChange) props.onChange(text);
      }}
      config={{
        view: {
          menu: true,
          md: true,
          html: true,
          fullScreen: true,
        },
        imageUrl: 'https://octodex.github.com/images/minion.png',
      }}
      onImageUpload={(file, callback) => handleImageUpload(file, callback)}
    />
  );
}
