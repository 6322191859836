import React from 'react';
import {QuestionDto, QuestionType} from '../../scaffold';
import FillQuestionDisplay from './FillQuestionDisplay';
import EssayQuestionDisplay from './EssayQuestionDisplay';
import OperateQuestionDisplay from './OperateQuestionDisplay';
import ChoiceQuestionDisplay from './ChoiceQuestionDisplay';
import Text from 'antd/lib/typography/Text';
import CodingQuestionDisplay from './CodingQuestionDisplay';

export interface IQuestionDisplayProps<T> {
  question: T;
  hideRightAnswer?: boolean;
}
export default function QuestionDisplay(props: IQuestionDisplayProps<QuestionDto>) {
  const item = props.question;
  if (item.type === QuestionType.编程题) return <CodingQuestionDisplay question={props.question} />;
  if (item.type === QuestionType.填空题) return <FillQuestionDisplay {...props} />;
  if (item.type === QuestionType.论述题) return <EssayQuestionDisplay {...props} />;
  if (item.type === QuestionType.操作题) return <OperateQuestionDisplay {...props} />;
  return <ChoiceQuestionDisplay {...props} />;
}
