import React from 'react';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import {Button} from 'antd';
import {CaretLeftOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {CourseDto} from '../../scaffold';

export default function CourseSideList(props: {
  courseList: CourseDto[];
  selectedCourse: CourseDto;
  onSelectCourse: (c: CourseDto) => any;
  detailMode: boolean;
  isBlur: boolean;
  goBack: () => any;
}) {
  const history = useHistory();

  return (
    <div className={`CourseSideList ${props.detailMode ? 'CourseListDetailMode ' : ''} ${props.isBlur ? 'massa-blur' : ''}`}>
      {props.courseList.map(course => (
        <div
          key={course.id}
          className={'CourseListItem ' + (props.selectedCourse.id === course.id ? 'CourseListItemSelected' : '')}
          onClick={() => {
            if (props.selectedCourse.id !== course.id) props.onSelectCourse(course);
            else props.goBack();
          }}>
          <Title ellipsis={!props.detailMode} level={3}>
            {course.name}
          </Title>
          <Text ellipsis={!props.detailMode} type={'secondary'}>
            {course.description}
          </Text>
        </div>
      ))}
      <div className={'GoBack'}>
        <a onClick={props.goBack}>
          回到目录
          <CaretLeftOutlined />
        </a>
      </div>
    </div>
  );
}
