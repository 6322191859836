import React from 'react';
import {ExamApi, ExamDto} from '../../scaffold';
import {Button, message} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';
import moment from 'moment';

export default function CreateSubmissionBtn(props: {exam: ExamDto}) {
  const createState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examCreateSubmissionPost);
  const tabLayout = useTabLayout();
  return (
    <Button
      loading={createState.loading}
      onClick={() => {
        createState
          .request({
            examSubmissionCreateParams: {
              examId: props.exam.id || 0,
            },
          })
          .then(res => {
            tabLayout.goTo('/app/exam/submit/' + res.id);
          })
          .catch(CommonApiErrorHandler);
      }}>
      参加考试
    </Button>
  );
}
