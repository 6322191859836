import React, {useState} from 'react';
import {ExamApi, ExamSubmissionDto, QuestionType, SubmitQuestionDetailDto} from '../../scaffold';
import {Button, Divider, InputNumber, Space} from 'antd';
import Text from 'antd/lib/typography/Text';
import QuestionDisplay from '../Question/QuestionDisplay';
import MarkdownRender from '../Shared/MarkdownRender';
import QuestionSubmissionDetail from './QuestionSubmissionDetail';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';

export default function QuestionSubmissionMarkDetail(props: {info: SubmitQuestionDetailDto; submission: ExamSubmissionDto; index: number; refresh: () => any}) {
  const [score, setScore] = useState(props.info.scoreGet || 0);
  const mark = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examMarkSubmissionPost);
  return (
    <div>
      <QuestionSubmissionDetail info={props.info} submission={props.submission} index={props.index} />
      <Space className={'m-t-10'}>
        <Text>手动赋分</Text>
        <InputNumber value={score} onChange={setScore as any} step={0.5} min={0} />
        <Button
          loading={mark.loading}
          onClick={() => {
            mark
              .request({examMarkSubmissionParams: {submissionId: props.submission.id || 0, scoreGet: score, questionId: props.info.questionId || 0}})
              .then(props.refresh)
              .catch(CommonApiErrorHandler);
          }}>
          确定
        </Button>
      </Space>
    </div>
  );
}
