import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Checkbox, Divider, Form, Input, message, PageHeader, Radio, Select, Space, Table, Tag} from 'antd';
import {ExamAnalyseQuestionItem, ExamApi, ExamDto, ExamSubmissionDto, PaperApi, QuestionDto} from '../../../scaffold';
import {useList, useMount} from 'react-use';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import {useParams} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import QuestionDisplay from '../../../components/Question/QuestionDisplay';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {useBoolean, useInput} from 'react-hanger';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import ExamSubmissionKeywordFilter from '../../../utils/keywordFiliter/ExamSubmission';
import ExamGroupSubmission from '../../../components/Exam/GroupSubmission';
import Text from 'antd/lib/typography/Text';

type QuestionDtoWithAnalyse = QuestionDto & ExamAnalyseQuestionItem & {percent: number};

export default function ManageExamAnalysePage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findExamState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindGet);
  const findPaperState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperFindGet);
  const analyseState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examAnalyseGet);
  const tabLayout = useTabLayout();
  const [groupId, setGroupId] = useState<number>();

  function refresh() {
    if (groupId)
      analyseState
        .request({examId: findExamState.data?.id || 0, groupId})
        .then()
        .catch();
  }

  useMount(() => {
    findExamState
      .request({id})
      .then(res => {
        tabLayout.setTitle('考试分析:' + res.name);
        findPaperState
          .request({id: res.paperId})
          .then(refresh)
          .catch(CommonApiErrorHandler);
      })
      .catch(CommonApiErrorHandler);
  });

  useEffect(() => {
    if (findExamState.data?.id) refresh();
  }, [groupId]);

  const questionList = useMemo(() => {
    const qList: QuestionDtoWithAnalyse[] = [];
    findPaperState.data?.questionDetailGroups?.forEach(g => {
      g.questions?.forEach(q => {
        const analyseItem = analyseState.data?.find(a => a.questionId === q.id) || {questionId: 0, rightCount: 0, userCount: 0};
        qList.push({...q, ...analyseItem, percent: parseFloat((((analyseItem.rightCount || 0) / (analyseItem.userCount || 0)) * 100).toFixed(2)) || 0});
      });
    });
    return qList;
  }, [findPaperState.data, analyseState.data]);

  return (
    <div>
      <PageHeader title={`考试分析: ${findExamState.data?.name}`} style={{background: 'white'}} />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findExamState.loading}>
          <Space>
            <Select value={groupId} onChange={setGroupId} placeholder={'筛选班级'} style={{width: 200}} allowClear>
              {findExamState.data?.groups?.map(g => (
                <Select.Option value={g.id as number} key={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
          </Space>
          <Divider />

          <Table size={'small'} bordered pagination={false} dataSource={questionList} rowKey={'id'}>
            <Table.Column title={'题号'} render={(_, item, i) => i + 1} />
            <Table.Column<QuestionDtoWithAnalyse> title={'答题数'} render={(_, item) => item.userCount} />
            <Table.Column<QuestionDtoWithAnalyse> title={'答对数'} render={(_, item) => item.rightCount} />
            <Table.Column<QuestionDtoWithAnalyse> title={'正确率'} render={(_, item) => item.percent} />
          </Table>
          <Divider />
          {questionList.map((question, qIndex) => (
            <div key={qIndex}>
              <QuestionDisplay question={question} />
              <Text strong>正确率: {question.percent}%</Text>
              <Divider />
            </div>
          ))}
        </Card>
      </div>
    </div>
  );
}
