import React, {useEffect} from 'react';
import {GroupDto, UserApi, UserDto, UserRole} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {message, Table} from 'antd';
import Text from 'antd/lib/typography/Text';
import Flex from '../Shared/Flex';
import {UserSelectedBtn} from './SelectTable';
import {useInput} from 'react-hanger';
import UserKeywordFilter from '../../utils/keywordFiliter/User';
import SearchAndRefresh from '../Shared/SearchAndRefresh';
import CommonApiErrorHandler from '../../utils/HttpInstance';

export default function GroupDetail(props: {group: GroupDto}) {
  const findState = useOpenApiFpRequest(UserApi, UserApi.prototype.userFindGroupGet);
  const listState = useOpenApiFpRequest(UserApi, UserApi.prototype.userListGroupStudentGet);
  const addState = useOpenApiFpRequest(UserApi, UserApi.prototype.userAddGroupStudentPost);
  const removeState = useOpenApiFpRequest(UserApi, UserApi.prototype.userRemoveGroupStudentPost);
  function refresh() {
    findState.request({id: props.group.id}).then(res => {
      listState.requestSync({id: props.group.id});
    });
  }
  useEffect(() => {
    refresh();
  }, [props.group]);
  async function add(users: UserDto[]) {
    for (const user of users) {
      await addState.request({adjustGroupStudentParams: {groupId: props.group.id || 0, studentId: user.id || 0}});
    }
    message.success('添加成功');
    refresh();
  }
  const group = findState.data;
  const keyword = useInput('');
  const displayRows = listState.data?.filter(i => UserKeywordFilter(i, keyword.value));

  return (
    <div>
      <div className={'m-b-10'}>
        <Text type={'secondary'}>{group?.name} 内的学生:</Text>
      </div>
      <Flex justify={'space-between'}>
        <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={findState.loading} />
        <UserSelectedBtn role={UserRole.学生} onConfirm={add}>
          添加学生
        </UserSelectedBtn>
      </Flex>
      <Table size={'small'} className={'m-t-10'} bordered dataSource={displayRows || []} rowKey={'id'} loading={findState.loading}>
        <Table.Column title={'编号'} dataIndex={'id'} />
        <Table.Column title={'姓名'} dataIndex={'name'} />
        <Table.Column title={'学号'} dataIndex={'username'} />
        <Table.Column<UserDto>
          title={'操作'}
          render={(_, item) => (
            <a
              onClick={() => {
                removeState
                  .request({adjustGroupStudentParams: {groupId: group?.id || 0, studentId: item.id || 0}})
                  .then(() => {
                    message.success('移除成功');
                    refresh();
                  })
                  .catch(CommonApiErrorHandler);
              }}>
              移除
            </a>
          )}
        />
      </Table>
    </div>
  );
}
