import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {ITabLayoutRouteConfig, TabLayout} from '../TabLayout/TabLayout';
import {CarryOutTwoTone, CarTwoTone} from '@ant-design/icons';
import LayoutHeader from '../components/layouts/LayoutHeader';
import AppIndexPage from './app/working';
import TabLayoutProvider from '../TabLayout/TabLayoutProvider';

import useAuthCheck from '../utils/useAuthCheck';
import {useMount} from 'react-use';
import useAuth from '../utils/AuthContext';
import {UserRole} from '../scaffold';
import ManageExamListPage from './manage/exam/list';
import AppExamListPage from './app/exam/list';
import AppExamSubmitPage from './app/exam/submit';
import WorkingIndexPage from './app/working';
import AppExamUserPage from './app/exam/user';
import AppExamSubmissionResultPage from './app/exam/result';
import AppCodingQuestionDetailPage from './app/codingQuestion/detail';
import AppCourseListPage from './app/course/list';
import AppHomePage from './app/home';
import {useCluster} from '../utils/ClusterProvider';
import AppSystemNoticeDetailPage from './app/system/notice';

function AppPage() {
  const {path, url} = useRouteMatch();
  const checkAuth = useAuthCheck();
  const cluster = useCluster();
  const auth = useAuth();
  const routeConfig: ITabLayoutRouteConfig = {
    menus: [
      {title: '首页', icon: <CarTwoTone />, path: `${path}/`},
      {title: '课程', icon: <CarTwoTone />, path: `${path}/course`},
      {
        title: '考试',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '考试管理',
            submenus: [
              {path: `${path}/exam/list`, title: '考试列表'},
              {path: `${path}/exam/user`, title: '考试记录'},
            ],
          },
        ],
      },
    ],
    routes: [
      {component: AppExamListPage, path: `${path}/exam/list`, title: '考试列表'},
      {component: AppExamUserPage, path: `${path}/exam/user`, title: '考试记录'},
      {component: AppExamSubmitPage, path: `${path}/exam/submit/:id`, title: '答题'},
      {component: AppSystemNoticeDetailPage, path: `${path}/system/notice/:id`, title: '答题'},
      {component: AppCourseListPage, path: `${path}/course`, title: '课程'},
      {component: AppExamSubmissionResultPage, path: `${path}/exam/result/:id`, title: '查看考卷'},
      {component: AppCodingQuestionDetailPage, path: `${path}/codingQuestion/:id`, title: '题目详情'},
      {component: WorkingIndexPage, path: `${path}/working`, title: '正在开发中'},
      {component: AppHomePage, path: `${path}/`, title: '首页'},
    ],
  };
  if (cluster.cluster?.nameEn === 'SFLS') {
    routeConfig.menus.push({
      title: '解密打榜',
      icon: <CarTwoTone />,
      path: `http://exam.sfls.cn/codec/auth.asp?token=${auth.token}`,
    });
  }
  const history = useHistory();
  function logout() {
    localStorage.removeItem('Application-Token');
    history.push('/login');
  }
  useMount(() => {
    if (!auth.currentUser) checkAuth.check();
    else if (auth.currentUser?.role !== UserRole.学生) {
      history.push('/login');
    }
  });
  return (
    <TabLayoutProvider basePath={path}>
      {checkAuth.isChecking && <div>自动登录中</div>}
      {!checkAuth.isChecking && auth.currentUser && <TabLayout routeConfig={routeConfig} customHeader={<LayoutHeader />} logout={logout} />}
    </TabLayoutProvider>
  );
}
export default AppPage;
