import React, {useEffect, useState} from 'react';
import {Alert, Avatar, Button, Col, Divider, Row, Skeleton, Tooltip} from 'antd';
import SubmissionStatusCell from './SubmissionStatusCell';
import {
  CodingLanguage,
  CodingSubmissionApi,
  CodingSubmissionDto,
  CodingSubmissionStatus,
  DataPointDetailWithScore,
  SubmissionTaskWithScore,
} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import useAuth from '../../utils/AuthContext';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import Flex from '../Shared/Flex';
import CodeInput from '../Shared/CodeInput';

function DataPointDetailWithScoreRow(props: {dataPoint: DataPointDetailWithScore; task: SubmissionTaskWithScore}) {
  const {dataPoint, task} = props;
  return (
    <tr>
      <td>{dataPoint.dataPointId}</td>
      <td>
        {dataPoint.message ? (
          <Tooltip title={dataPoint.message}>
            <SubmissionStatusCell status={dataPoint.status as CodingSubmissionStatus} isOutDate={false} />
            <div> </div>
          </Tooltip>
        ) : (
          <SubmissionStatusCell status={dataPoint.status as CodingSubmissionStatus} isOutDate={false} />
        )}
      </td>
      <td>
        {task.unionMode ? (
          <span>联合计分 / {dataPoint.score} 分</span>
        ) : (
          <span>
            {dataPoint.scoreGet} / {dataPoint.score} 分
          </span>
        )}
      </td>
      <td>
        {dataPoint.memoryUsage || dataPoint.memoryUsage === 0 ? dataPoint.memoryUsage + 'kb' : ''}
        <Divider type={'vertical'} />
        {dataPoint.timeUsage || dataPoint.timeUsage === 0 ? dataPoint.timeUsage + 'ms' : ''}
      </td>
    </tr>
  );
}

export default function SubmissionDetail(props: {submissionId: number; solved?: () => any}) {
  const [submission, setSubmission] = useState<CodingSubmissionDto>();
  const [code, setCode] = useState('');
  const [errorInfo, setErrorInfo] = useState('');
  const [language, setLanguage] = useState<CodingLanguage>(CodingLanguage.Cpp);
  const queryByIdHook = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionFindByIdGet);
  const submitHook = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionSubmitPost);
  const auth = useAuth();
  const isSelf = auth.currentUser && queryByIdHook.data && auth.currentUser.id === queryByIdHook.data.submitterId;
  function refresh(id: number) {
    const originStatus = queryByIdHook.data?.status;
    queryByIdHook
      .request({id})
      .then(res => {
        if (res) {
          setSubmission(res);
          setCode(res.code || '');
          setLanguage(res.language || CodingLanguage.Cpp);
          setErrorInfo(res.errorInfo || '');
          if (res.status === '答案正确' && res.status !== originStatus) {
            props.solved && props.solved();
          }
        }
      })
      .catch(CommonApiErrorHandler);
  }

  function reSubmit() {
    if (submission) {
      submitHook
        .request({submitCodingParams: {code, language, codingQuestionId: submission.codingQuestionId || 0}})
        .then(res => {
          refresh(res.id || 0);
        })
        .catch(CommonApiErrorHandler);
    }
  }

  useEffect(() => {
    refresh(props.submissionId);
  }, [props.submissionId]);

  return (
    <Skeleton loading={queryByIdHook.loading}>
      {submission && (
        <React.Fragment>
          <div className={'massa-card mb-md'} style={{position: 'relative'}}>
            <Flex align={'center'} justify={'space-between'}>
              <table className="massa-table" style={{flex: 1, width: 0}}>
                <tbody>
                  <tr>
                    <td>
                      <strong>评测结果</strong>
                    </td>
                    <td>
                      <SubmissionStatusCell status={submission.status as CodingSubmissionStatus} isOutDate={!!submission.isOutDate} />
                    </td>
                    <td>
                      <strong>提交人</strong>
                    </td>
                    <td>{submission.submitter?.name}</td>
                    <td>
                      <strong>提交时间</strong>
                    </td>
                    <td>{submission.createdTime}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>最大时间占用</strong>
                    </td>
                    <td>{submission.timeUsage} ms</td>
                    <td>
                      <strong>最多内存占用</strong>
                    </td>
                    <td>{submission.memoryUsage} Kb</td>
                    <td>
                      <strong>得分</strong>
                    </td>
                    <td>
                      {submission.scoreGet} / {submission.totalScore}
                    </td>
                  </tr>
                  {submission.errorInfo && (
                    <tr>
                      <td style={{width: 120}}>
                        <strong>错误信息</strong>
                      </td>
                      <td colSpan={5} className="text-liner-orange">
                        {submission.errorInfo}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Button className={'m-l-10'} loading={queryByIdHook.loading} onClick={() => submission?.id && refresh(submission.id)}>
                刷新评测状态
              </Button>
              <div
                style={{
                  position: 'absolute',
                  left: 25,
                  top: 40,
                  opacity: 0.1,
                  fontSize: 30,
                  transform: 'rotate(-30deg)',
                }}>
                <SubmissionStatusCell status={submission.status as CodingSubmissionStatus} isOutDate={!!submission.isOutDate} />
              </div>
            </Flex>
          </div>
          <Divider />
          {submission.tasks?.map(task => (
            <div className={'massa-card m-b-10'} key={task.name}>
              <div className={'p-l-5 p-r-5 p-t-10'}>
                <h3>{task.name}</h3>
              </div>
              <Row gutter={20}>
                <Col span={12}>
                  <table className="massa-table">
                    <thead className="b-b-1">
                      <tr>
                        <th>编号</th>
                        <th>评测结果</th>
                        <th>得分</th>
                        <th>内存/时间占用</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task.dataPoints
                        ?.filter((_, i) => i % 2 === 0)
                        .map(d => (
                          <DataPointDetailWithScoreRow key={d.dataPointId} dataPoint={d} task={task} />
                        ))}
                    </tbody>
                  </table>
                </Col>
                <Col span={12}>
                  <table className="massa-table">
                    <thead className="b-b-1">
                      <tr>
                        <th>编号</th>
                        <th>评测结果</th>
                        <th>得分</th>
                        <th>内存/时间占用</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task.dataPoints
                        ?.filter((_, i) => i % 2 === 1)
                        .map(d => (
                          <DataPointDetailWithScoreRow key={d.dataPointId} dataPoint={d} task={task} />
                        ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          ))}
          <Divider />
          <div className={'massa-card p-md'}>
            {submission.status !== '答案正确' && isSelf && <Alert showIcon className={'m-b-10'} message={'直接在下面的代码框修改代码即可重新提交'} />}
            <CodeInput
              errorInfo={errorInfo}
              code={code}
              language={language}
              onChange={value => {
                setCode(value.code);
                setLanguage(value.language);
                setErrorInfo('');
              }}
            />
            {code !== submission.code && isSelf && (
              <Button className={'mt-md'} size={'large'} type={'primary'} onClick={reSubmit}>
                再次评测
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
    </Skeleton>
  );
}
