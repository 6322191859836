import React, {useEffect, useState} from 'react';
import {useAuthContext} from 'geestack-toolbox';
import {IUser} from '../../hooks/User';
import {Button, Drawer, Form, Input, message, PageHeader, Space, Table} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {GroupDto, UserApi} from '../../scaffold';
import {useMount} from 'react-use';
import {useBoolean, useInput} from 'react-hanger';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import GroupDetail from '../../components/User/GroupDetail';

export default function ManageGroupPage() {
  const listState = useOpenApiFpRequest(UserApi, UserApi.prototype.userListGroupGet);
  function refresh() {
    listState.requestSync({});
  }
  useMount(() => refresh());

  const isDialogSave = useBoolean(false);
  const isDialogDetail = useBoolean(false);
  const name = useInput('');
  const [selected, setSelected] = useState<GroupDto>();
  useEffect(() => {
    if (selected) {
      name.setValue(selected.name || '');
    } else {
      name.clear();
    }
  }, [selected]);

  const saveState = useOpenApiFpRequest(UserApi, UserApi.prototype.userSaveGroupPost);
  function save() {
    saveState
      .request({
        saveGroupParams: {
          id: selected?.id,
          name: name.value,
        },
      })
      .then(() => {
        message.success('保存成功');
        refresh();
        isDialogSave.setFalse();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <div>
      <PageHeader
        title={'班级管理'}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button
              type={'primary'}
              onClick={() => {
                isDialogSave.setTrue();
                setSelected(undefined);
              }}>
              添加班级
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <Table size={'small'} bordered dataSource={listState.data} rowKey={'id'} loading={listState.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} />
          <Table.Column title={'班级名称'} dataIndex={'name'} />
          <Table.Column title={'人数'} dataIndex={'studentCount'} />
          <Table.Column<GroupDto>
            title={'操作'}
            render={(_, item) => (
              <Space>
                <a
                  onClick={() => {
                    isDialogSave.setTrue();
                    setSelected(item);
                  }}>
                  编辑
                </a>
                <a
                  onClick={() => {
                    isDialogDetail.setTrue();
                    setSelected(item);
                  }}>
                  查看学生
                </a>
              </Space>
            )}
          />
        </Table>
      </div>
      <Drawer width={700} title={selected ? '编辑班级' : '创建班级'} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
        <Form layout={'vertical'}>
          <Form.Item label={'班级名称'} required>
            <Input {...name.eventBind} placeholder={'请输入班级名称'} />
          </Form.Item>
        </Form>
        <Button type={'primary'} onClick={save} loading={saveState.loading}>
          保存
        </Button>
      </Drawer>
      <Drawer width={700} title={selected?.name} visible={isDialogDetail.value} onClose={isDialogDetail.setFalse}>
        {selected && <GroupDetail group={selected} />}
      </Drawer>
    </div>
  );
}
