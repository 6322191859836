import React, {useEffect, useState} from 'react';
import {GroupDto, UserApi} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Button, Drawer, message, Table} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {useInput, useBoolean} from 'react-hanger';
import SearchAndRefresh from '../Shared/SearchAndRefresh';
import GroupKeywordFilter from '../../utils/keywordFiliter/Group';

export interface IGroupSelectedTableProps {
  value: GroupDto[];
  onChange: (v: GroupDto[]) => any;
}
export interface IGroupSelectedBtnProps extends ButtonProps {
  onConfirm: (Groups: GroupDto[]) => Promise<any>;
}
export function GroupSelectedBtn(props: IGroupSelectedBtnProps) {
  const {onConfirm, ...btnProps} = props;
  const [value, setValue] = useState<GroupDto[]>([]);
  const isDialog = useBoolean(false);
  const loading = useBoolean(false);
  async function onSelect() {
    try {
      loading.setTrue();
      await props.onConfirm(value);
      loading.setFalse();
      setValue([]);
      isDialog.setFalse();
    } catch (e) {
      message.error(e.message);
      loading.setFalse();
    }
  }
  return (
    <React.Fragment>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        {props.children}
      </Button>
      <Drawer width={600} title={'请选择班级'} visible={isDialog.value} onClose={isDialog.setFalse}>
        <GroupSelectedTable value={value} onChange={setValue} />
        <Button type={'primary'} loading={loading.value} onClick={onSelect} disabled={value.length === 0}>
          确定选择 {value.length} 个
        </Button>
      </Drawer>
    </React.Fragment>
  );
}

export default function GroupSelectedTable(props: IGroupSelectedTableProps) {
  const listState = useOpenApiFpRequest(UserApi, UserApi.prototype.userListGroupGet);
  const keyword = useInput('');
  function refresh() {
    listState.requestSync({});
  }
  useEffect(() => {
    refresh();
  }, []);

  const displayRows = listState.data?.filter(i => GroupKeywordFilter(i, keyword.value));
  return (
    <div>
      <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
      <Table
        size={'small'}
        bordered
        className={'m-t-10'}
        dataSource={displayRows || []}
        rowKey={'id'}
        loading={listState.loading}
        rowSelection={{
          selectedRowKeys: props.value.map(i => i.id as number),
          onChange(_, rows) {
            props.onChange(rows);
          },
        }}>
        <Table.Column title={'班级名称'} dataIndex={'name'} />
        <Table.Column title={'人数'} dataIndex={'studentCount'} />
      </Table>
    </div>
  );
}
