import React, {useEffect, useState} from 'react';
import {QuestionApi, QuestionDto, QuestionType} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Button, Drawer, Input, message, Radio, Space, Table} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {useBoolean, useInput} from 'react-hanger';
import SearchAndRefresh from '../Shared/SearchAndRefresh';
import QuestionKeywordFilter from '../../utils/keywordFiliter/Question';
import QuestionDisplay from './QuestionDisplay';
import useExAntdTable from '../../utils/useExAntdTable';
import AntDTableUtils from '../../utils/AntDTableUtils';

export interface IQuestionSelectedTableProps {
  value: QuestionDto[];
  onChange: (v: QuestionDto[]) => any;
  questionType?: QuestionType;
}
export interface IQuestionSelectedBtnProps extends ButtonProps {
  onConfirm: (Questions: QuestionDto[]) => Promise<any>;
  questionType?: QuestionType;
}
export function QuestionSelectedBtn(props: IQuestionSelectedBtnProps) {
  const {onConfirm, questionType, ...btnProps} = props;
  const [value, setValue] = useState<QuestionDto[]>([]);
  const isDialog = useBoolean(false);
  const loading = useBoolean(false);
  async function onSelect() {
    try {
      loading.setTrue();
      await props.onConfirm(value);
      loading.setFalse();
      setValue([]);
      isDialog.setFalse();
    } catch (e) {
      message.error(e.message);
      loading.setFalse();
    }
  }
  return (
    <React.Fragment>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        {props.children}
      </Button>
      <Drawer width={1000} title={'请选择题目'} visible={isDialog.value} onClose={isDialog.setFalse}>
        <QuestionSelectedTable value={value} questionType={questionType} onChange={setValue} />
        <Button type={'primary'} loading={loading.value} onClick={onSelect} disabled={value.length === 0}>
          确定选择 {value.length} 题
        </Button>
      </Drawer>
    </React.Fragment>
  );
}

export default function QuestionSelectedTable(props: IQuestionSelectedTableProps) {
  const listState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSearchQuestionGet);
  const keyword = useInput('');
  const [type, setType] = useState<QuestionType>(props.questionType || QuestionType.选择题);
  const searchHook = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSearchQuestionGet);

  const exAntDTable = useExAntdTable({
    request: params => {
      const tableParams = AntDTableUtils.makeParams(params);
      return searchHook.request({
        ...tableParams,
        keywords: keyword.value,
        type,
      });
    },
    deps: [type],
  });
  function refresh() {
    exAntDTable.refresh();
  }
  return (
    <div>
      <Space>
        <Space>
          <Input.Search {...keyword.eventBind} onSearch={refresh} placeholder={'输入关键词检索'} />
          <Button onClick={refresh} loading={searchHook.loading}>
            刷新
          </Button>
        </Space>
        {!props.questionType && (
          <Radio.Group value={type} onChange={e => setType(e.target.value)}>
            <Radio.Button value={QuestionType.选择题}>选择题</Radio.Button>
            <Radio.Button value={QuestionType.填空题}>填空题</Radio.Button>
            <Radio.Button value={QuestionType.论述题}>论述题</Radio.Button>
            <Radio.Button value={QuestionType.操作题}>操作题</Radio.Button>
            <Radio.Button value={QuestionType.编程题}>编程题</Radio.Button>
          </Radio.Group>
        )}
      </Space>
      <Table
        size={'small'}
        bordered
        className={'m-t-10'}
        {...exAntDTable.tableProps}
        pagination={exAntDTable.pagination}
        rowKey={'id'}
        loading={listState.loading}
        rowSelection={{
          selectedRowKeys: props.value.map(i => i.id as number),
          onChange(_, rows) {
            props.onChange(rows);
          },
        }}>
        <Table.Column title={'编号'} dataIndex={'id'} width={100} />
        <Table.Column<QuestionDto> title={'题干'} render={(_, item) => <QuestionDisplay question={item} key={item.id} />} />
      </Table>
    </div>
  );
}
