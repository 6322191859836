import React from 'react';
import {ChoiceQuestionDto, UserRole} from '../../scaffold';
import MarkdownRender from '../Shared/MarkdownRender';
import {IQuestionDisplayProps} from './QuestionDisplay';
import useAuth from '../../utils/AuthContext';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';

export default function CodingQuestionDisplay(props: IQuestionDisplayProps<ChoiceQuestionDto>) {
  const {question} = props;
  const labels = 'ABCDEFGHIJKLMN';
  const auth = useAuth();
  const tabLayout = useTabLayout();
  return (
    <div>
      <MarkdownRender className={'smallMarkdown'} md={'**[编程题]** ' + question.topic || ''} />
      {auth.currentUser?.role === UserRole.学生 && <a onClick={() => tabLayout.goTo('/app/codingQuestion/' + props.question.id)}>前往查看题目</a>}
      {auth.currentUser?.role !== UserRole.学生 && <a onClick={() => tabLayout.goTo('/manage/codingQuestion/' + props.question.id)}>前往查看题目</a>}
    </div>
  );
}
