import React, {useEffect, useState} from 'react';
import {CodingQuestionDto, CourseApi, CourseArticleDto, CourseLessonDto, CourseLessonItemDto, CourseLessonItemType, QuestionType} from '../../scaffold';
import {Button, Card, Drawer, Input, message, Popconfirm, Popover, Space, Tooltip} from 'antd';
import {QuestionSelectedBtn} from '../Question/SelectTable';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import SortableList from '../Shared/SortableList';
import Flex from '../Shared/Flex';
import CourseLessonDetailIcon from './DetailIcon';
import Text from 'antd/lib/typography/Text';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {DeleteTwoTone, SearchOutlined, EditTwoTone, FlagOutlined, CodeOutlined} from '@ant-design/icons';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';
import {useInput} from 'react-hanger';
import {CourseArticleSelectedBtn} from './ArticleSelectTable';
import CourseArticleSaveForm from './ArticleSaveForm';

export default function CourseLessonManage(props: {index: number; lesson: CourseLessonDto; refresh: () => any; loading: boolean}) {
  const {lesson} = props;
  const addQuestionState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseAddCodingQuestionPost);
  const addArticleState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseAddArticlePost);
  const updateLessonState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseUpdateLessonPost);
  const deleteLessonState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseDeleteLessonPost);
  const removeQuestion = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseRemoveCodingQuestionPost);
  const removeArticle = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseRemoveArticlePost);
  const [items, setItems] = useState<CourseLessonItemDto[]>([]);
  const updateLessonName = useInput('');
  const [selectedArticle, setSelectedArticle] = useState<CourseArticleDto>();
  const tabLayout = useTabLayout();
  useEffect(() => {
    setItems(lesson.items || []);
  }, [lesson]);
  async function addQuestions(questions: CodingQuestionDto[]) {
    try {
      for (const question of questions) {
        await addQuestionState.request({updateLessonCodingQuestion: {lessonId: lesson.id || 0, codingQuestionId: question.id || 0}});
      }
      props.refresh();
    } catch (e) {
      message.error(e.message);
    }
  }
  async function addArticles(articles: CourseArticleDto[]) {
    try {
      for (const article of articles) {
        await addArticleState.request({updateLessonArticle: {lessonId: lesson.id || 0, articleId: article.id || 0}});
      }
      props.refresh();
    } catch (e) {
      message.error(e.message);
    }
  }
  return (
    <Card
      title={`第 ${props.index} 章: ${lesson.name}`}
      loading={props.loading}
      extra={
        <Space>
          <QuestionSelectedBtn questionType={QuestionType.编程题} onConfirm={addQuestions}>
            添加题目
          </QuestionSelectedBtn>
          <CourseArticleSelectedBtn onConfirm={addArticles}>添加教案</CourseArticleSelectedBtn>
          <Popover
            title={'修改章节名称'}
            trigger={'click'}
            content={
              <Space>
                <Input placeholder={'修改章节名称'} {...updateLessonName.eventBind} />
                <Button
                  loading={updateLessonState.loading}
                  onClick={() => {
                    updateLessonState
                      .request({
                        updateLessonParams: {
                          lessonId: lesson.id || 0,
                          name: updateLessonName.value,
                          sortIds: lesson.sortIds,
                        },
                      })
                      .then(() => {
                        message.success('修改成功');
                        props.refresh();
                      })
                      .catch(CommonApiErrorHandler);
                  }}>
                  修改
                </Button>
              </Space>
            }>
            <Button loading={updateLessonState.loading}>修改章节名称</Button>
          </Popover>
          <Popconfirm
            title={'确认要删除章节吗'}
            onConfirm={() => {
              deleteLessonState
                .request({deleteLessonParams: {lessonId: lesson.id || 0, id: lesson.courseId || 0}})
                .then(props.refresh)
                .catch(CommonApiErrorHandler);
            }}>
            <Button danger loading={deleteLessonState.loading}>
              删除章节
            </Button>
          </Popconfirm>
        </Space>
      }>
      <Flex justify={'space-between'}>
        <SortableList
          style={{flex: 1}}
          list={items || []}
          afterSort={newList => {
            setItems(newList);
            const sortIdList = newList.map(item => {
              if (item.type === CourseLessonItemType.CodingQuestion) return 'CodingQuestion-' + item.codingQuestion?.id;
              if (item.type === CourseLessonItemType.ExampleCodingQuestion) return 'ExampleCodingQuestion-' + item.codingQuestion?.id;
              return 'Article-' + item.courseArticle?.id;
            });
            updateLessonState
              .request({
                updateLessonParams: {
                  lessonId: lesson.id || 0,
                  name: lesson.name || '',
                  sortIds: sortIdList,
                },
              })
              .then(() => {
                message.success('排序成功');
                props.refresh();
              })
              .catch(CommonApiErrorHandler);
          }}
          renderItem={item => (
            <Flex className={'p-5'} style={{height: 46}}>
              <Space>
                <CourseLessonDetailIcon detail={item} />
                <Text strong style={{fontSize: 16}}>
                  {item.codingQuestion?.topic || item.courseArticle?.topic}
                </Text>
              </Space>
            </Flex>
          )}
        />
        <div>
          {lesson.items?.map((item, i) => (
            <Flex className={'p-5'} key={i} style={{height: 46}}>
              <Space>
                {item.type == CourseLessonItemType.ExampleCodingQuestion && (
                  <Tooltip title={'切换为习题'}>
                    <CodeOutlined
                      style={{fontSize: 20}}
                      onClick={() => {
                        if (!lesson.sortIds) return;
                        const index = lesson.sortIds.findIndex(id => id == `ExampleCodingQuestion-${item.codingQuestion?.id}`);
                        const idList = [...(lesson.sortIds || [])];
                        if (index >= 0) {
                          idList.splice(index, 1, `CodingQuestion-${item.codingQuestion?.id}`);
                        } else {
                          idList.push(`CodingQuestion-${item.codingQuestion?.id}`);
                        }
                        updateLessonState
                          .request({
                            updateLessonParams: {
                              lessonId: lesson.id || 0,
                              name: lesson.name || '',
                              sortIds: idList,
                            },
                          })
                          .then(() => {
                            message.success('调整成功');
                            props.refresh();
                          })
                          .catch(CommonApiErrorHandler);
                      }}
                    />
                  </Tooltip>
                )}
                {item.type == CourseLessonItemType.CodingQuestion && (
                  <Tooltip title={'切换为例题'}>
                    <FlagOutlined
                      style={{fontSize: 20}}
                      onClick={() => {
                        if (!lesson.sortIds) return;
                        const index = lesson.sortIds.findIndex(id => id == `CodingQuestion-${item.codingQuestion?.id}`);
                        const idList = [...(lesson.sortIds || [])];
                        if (index >= 0) {
                          idList.splice(index, 1, `ExampleCodingQuestion-${item.codingQuestion?.id}`);
                        } else {
                          idList.push(`ExampleCodingQuestion-${item.codingQuestion?.id}`);
                        }
                        updateLessonState
                          .request({
                            updateLessonParams: {
                              lessonId: lesson.id || 0,
                              name: lesson.name || '',
                              sortIds: idList,
                            },
                          })
                          .then(() => {
                            message.success('调整成功');
                            props.refresh();
                          })
                          .catch(CommonApiErrorHandler);
                      }}
                    />
                  </Tooltip>
                )}
                {item.codingQuestion && (
                  <Tooltip title={'题目详情'}>
                    <a onClick={() => tabLayout.goTo('/manage/codingQuestion/' + item.codingQuestion?.id)}>
                      <SearchOutlined style={{fontSize: 20}} />
                    </a>
                  </Tooltip>
                )}
                {item.courseArticle && (
                  <Tooltip title={'教案详情'}>
                    <a onClick={() => setSelectedArticle(item.courseArticle)}>
                      <SearchOutlined style={{fontSize: 20}} />
                    </a>
                  </Tooltip>
                )}
                <Tooltip title={'移出课程'}>
                  <DeleteTwoTone
                    twoToneColor={'#E73C3C'}
                    style={{fontSize: 20}}
                    onClick={() => {
                      if (item.codingQuestion) {
                        removeQuestion
                          .request({updateLessonCodingQuestion: {lessonId: lesson.id || 0, codingQuestionId: item.codingQuestion?.id || 0}})
                          .then(props.refresh)
                          .catch(CommonApiErrorHandler);
                      }
                      if (item.courseArticle) {
                        removeArticle
                          .request({updateLessonArticle: {lessonId: lesson.id || 0, articleId: item.courseArticle?.id || 0}})
                          .then(props.refresh)
                          .catch(CommonApiErrorHandler);
                      }
                    }}
                  />
                </Tooltip>
              </Space>
            </Flex>
          ))}
        </div>
      </Flex>
      <Drawer width={700} title={'编辑教案'} destroyOnClose visible={!!selectedArticle} onClose={() => setSelectedArticle(undefined)}>
        <CourseArticleSaveForm
          exist={selectedArticle}
          onSuccess={() => {
            props.refresh();
            setSelectedArticle(undefined);
          }}
        />
      </Drawer>
    </Card>
  );
}
