import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Form, Input, message, PageHeader, Radio, Space} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import LiveExMdEditor from '../../../components/Shared/LiveExMdEditor';
import {ChoiceQuestionScoreType, PaperApi, PaperQuestionDetailGroupDto, PaperQuestionGroup, UserRole} from '../../../scaffold';
import {useList, useMount} from 'react-use';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import {useParams} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import PaperQuestionDetailGroupInput from '../../../components/Paper/PaperQuestionDetailGroupInput';
import Text from 'antd/lib/typography/Text';
import useQuickRouter from '../../../utils/useQuickRouter';

export default function PaperEditPage() {
  const name = useInput('');
  const description = useInput('');
  const isRandomOption = useBoolean(false);
  const [choiceQuestionScoreType, setChoiceQuestionScoreType] = useState<ChoiceQuestionScoreType>(ChoiceQuestionScoreType.不给分);
  const [groups, groupsState] = useList<PaperQuestionDetailGroupDto>([]);
  const totalScore = useMemo(() => {
    let total = 0;
    groups.forEach(g => (total += g.eachScore * g.pickCount));
    return total.toFixed(1);
  }, [groups]);
  const params = useParams() as {id: string} | undefined;
  const existId = params?.id ? parseInt(params.id) : undefined;
  const findState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperFindGet);
  const tabLayout = useTabLayout();
  function refresh() {
    if (existId) {
      findState
        .request({id: existId})
        .then(res => {
          name.setValue(res.name || '');
          description.setValue(res.description || '');
          isRandomOption.setValue(!!res.isRandomOption);
          setChoiceQuestionScoreType(res.choiceQuestionScoreType || ChoiceQuestionScoreType.不给分);
          groupsState.set(res.questionDetailGroups || []);
          tabLayout.setTitle('编辑试卷:' + res.name);
        })
        .catch(CommonApiErrorHandler);
    }
  }

  useMount(() => {
    if (existId) {
      refresh();
    }
  });

  const saveState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperSavePost);

  function save() {
    saveState
      .request({
        savePaperParams: {
          id: existId,
          name: name.value,
          description: description.value,
          isRandomOption: isRandomOption.value,
          choiceQuestionScoreType,
          questionGroups: groups.map(g => ({...g, questionIds: g.questions?.map(i => i.id || 0) || []})),
        },
      })
      .then(res => {
        message.success('保存成功');
        if (!existId) {
          tabLayout.closeTab('/manage/paper/edit/');
          tabLayout.goTo('/manage/paper/edit/' + res.id);
        } else {
          refresh();
        }
      })
      .catch(CommonApiErrorHandler);
  }
  const quickRouter = useQuickRouter();

  return (
    <div>
      <PageHeader
        title={existId ? `编辑试卷: ${findState.data?.name}` : '新建试卷'}
        style={{background: 'white'}}
        extra={
          <Space>
            {existId && (
              <React.Fragment>
                <Button loading={findState.loading} onClick={refresh}>
                  刷新
                </Button>
                <Button onClick={() => quickRouter.paperPreview(existId)}>预览试卷</Button>
              </React.Fragment>
            )}
            <Button loading={saveState.loading} onClick={save} type={'primary'}>
              保存试卷
            </Button>
          </Space>
        }
      />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card title={'基本设置'} className={'m-b-15'} loading={findState.loading}>
          <Form layout={'vertical'}>
            <Form.Item label={'试卷名称'} required>
              <Input {...name.eventBind} placeholder={'请输入试卷名称'} />
            </Form.Item>
            <Form.Item label={'试卷描述'}>
              <LiveExMdEditor {...description.valueBind} placeholder={`将会在答题时显示在试卷最前面`} style={{background: '#EEEFF3', padding: '5px 15px'}} />
            </Form.Item>
            <Form.Item label={'选择题选项是否打乱顺序'} required>
              <Radio.Group value={isRandomOption.value} onChange={e => isRandomOption.setValue(e.target.value)}>
                <Radio value={false}>保持原序</Radio>
                <Radio value={true}>打乱顺序</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={'多选题赋分策略'} required>
              <Radio.Group value={choiceQuestionScoreType} onChange={e => setChoiceQuestionScoreType(e.target.value)}>
                <Radio value={ChoiceQuestionScoreType.不给分}>少选不给分</Radio>
                <Radio value={ChoiceQuestionScoreType.给一半分}>少选给一半分</Radio>
                <Radio value={ChoiceQuestionScoreType.给一半分且向下取整}>少选给一半分且向下取整</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Card>
        <Card title={'试卷题目'} loading={findState.loading} extra={<Text strong>试卷总分: {totalScore} 分</Text>}>
          {groups.map((group, index) => (
            <PaperQuestionDetailGroupInput
              value={group}
              onChange={v => groupsState.updateAt(index, v)}
              index={index}
              key={index}
              onDelete={() => groupsState.removeAt(index)}
            />
          ))}
          <Button onClick={() => groupsState.push({isRandomQuestion: false, questionIds: [], questions: [], pickCount: 0, eachScore: 0})}>添加一个组</Button>
        </Card>
      </div>
    </div>
  );
}
