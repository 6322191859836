import React from 'react';
import {ChoiceQuestionDto, EssayQuestionDto, FillQuestionDto} from '../../scaffold';
import MarkdownRender from '../Shared/MarkdownRender';
import Flex from '../Shared/Flex';
import Text from 'antd/lib/typography/Text';
import {IQuestionDisplayProps} from './QuestionDisplay';

export default function EssayQuestionDisplay(props: IQuestionDisplayProps<EssayQuestionDto>) {
  const {question} = props;
  return (
    <div>
      <MarkdownRender className={'smallMarkdown'} md={'**[论述题]** ' + question.topic || ''} />
      {!props.hideRightAnswer && (
        <div className={'m-t-5'}>
          <Text strong>参考答案: </Text>
          <MarkdownRender className={'smallMarkdown'} md={question.rightAnswer || ''} />
        </div>
      )}
    </div>
  );
}
