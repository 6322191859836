import React from 'react';
import {PaperQuestionDetailGroupDto, QuestionDto} from '../../scaffold';
import Flex from '../Shared/Flex';
import Title from 'antd/lib/typography/Title';
import {Button, Divider, Empty, Form, InputNumber, Popconfirm, Space} from 'antd';
import {QuestionSelectedBtn} from '../Question/SelectTable';
import QuestionDisplay from '../Question/QuestionDisplay';
import SortableList from '../Shared/SortableList';

export default function PaperQuestionDetailGroupInput(props: {
  value: PaperQuestionDetailGroupDto;
  onChange: (v: PaperQuestionDetailGroupDto) => any;
  index: number;
  onDelete: () => any;
}) {
  async function addQuestions(questions: QuestionDto[]) {
    props.onChange({...props.value, questions: [...(props.value.questions || []), ...questions]});
  }
  function removeQuestion(questionId: number) {
    const qList = [...(props.value.questions || [])];
    qList.splice(
      qList.findIndex(i => i.id === questionId),
      1,
    );
    props.onChange({...props.value, questions: qList});
  }
  return (
    <div>
      <Flex>
        <div style={{width: 250, flexShrink: 0}}>
          <Title level={4}>第 {props.index + 1} 组</Title>
          <Form>
            <Form.Item label={'拾取数量'}>
              <InputNumber value={props.value.pickCount} min={0} onChange={e => props.onChange({...props.value, pickCount: e as number})} />
              <span className={'m-l-5'}>题</span>
            </Form.Item>
            <Form.Item label={'每题得分'}>
              <InputNumber value={props.value.eachScore} min={0} step={0.5} onChange={e => props.onChange({...props.value, eachScore: e as number})} />
              <span className={'m-l-5'}>分</span>
            </Form.Item>
            <Form.Item label={'组内总分'}>{props.value.eachScore * props.value.pickCount} 分</Form.Item>
          </Form>
          <Space>
            <QuestionSelectedBtn onConfirm={addQuestions}>添加组内题目</QuestionSelectedBtn>
            <Popconfirm title={'是否删除分组'} onConfirm={props.onDelete}>
              <Button danger>删除分组</Button>
            </Popconfirm>
          </Space>
        </div>
        <div style={{flex: 1}}>
          {props.value.questions?.length === 0 && <Empty description={'组内还没有题目，请添加题目'} />}
          <SortableList
            list={props.value.questions || []}
            afterSort={newList => {
              props.onChange({...props.value, questions: newList});
            }}
            renderItem={(question, index) => (
              <Flex justify={'space-between'} style={{flex: 1}} className={'p-10'}>
                <QuestionDisplay question={question} />
                <Button type={'link'} danger onClick={() => removeQuestion(question.id || 0)}>
                  移除
                </Button>
              </Flex>
            )}
          />
        </div>
      </Flex>
      <Divider />
    </div>
  );
}
