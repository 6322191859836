import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import useQuickRouter from '../../../utils/useQuickRouter';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {ExamApi, ExamDto} from '../../../scaffold';
import {useBoolean, useInput} from 'react-hanger';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import {useMount} from 'react-use';
import {Button, Drawer, PageHeader, Space, Table} from 'antd';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import ExamSaveForm from '../../../components/Exam/SaveForm';
import ExamSetGroups from '../../../components/Exam/SetGroups';
import CreateSubmissionBtn from '../../../components/Exam/CreateSubmissionBtn';

export default function AppExamListPage() {
  const listState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSearchGet);
  const keyword = useInput('');
  const displayRows = listState.data?.list?.filter(i => PaperKeywordFilter(i, keyword.value));

  function refresh() {
    listState.requestSync({pi: 1, ps: 9999});
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader title={'考试列表'} style={{background: 'white'}} />
      <div className={'p-20'}>
        <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
        <Table size={'small'} className={'m-t-10'} bordered dataSource={displayRows || []} rowKey={'id'} loading={listState.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} width={100} />
          <Table.Column title={'考试名称'} dataIndex={'name'} />
          <Table.Column<ExamDto> title={'操作'} width={300} render={(_, item) => <Space>{item.isAllowSubmit && <CreateSubmissionBtn exam={item} />}</Space>} />
        </Table>
      </div>
    </div>
  );
}
