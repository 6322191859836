import React from 'react';
import {Col, Divider, Row} from 'antd';
import Flex from '../components/Shared/Flex';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import ClusterList from '../ClusterList';

export default function WrongClusterPage() {
  return (
    <div>
      <div>
        <Flex align={'center'} justify={'center'} style={{paddingTop: 40, paddingBottom: 40}}>
          <img src="/images/cleanUp.svg" alt="" style={{height: 60}} />
          <div className={'ml-md'}>
            <Title className={'mb-sm'}>域名输入有误</Title>
            <Text>请在下方选择你想要进入的系统</Text>
            <br />
          </div>
        </Flex>
        {/*<Divider>学校</Divider>*/}
        <div style={{width: 1024, margin: '0 auto'}}>
          <Row gutter={20}>
            {ClusterList.map(cluster => (
              <Col span={8} key={cluster.name}>
                <div className={'massa-shadow-item'} onClick={() => window.open('http://' + cluster.hostnames[0])}>
                  <img src={cluster.icon} alt="" style={{height: 60, marginRight: 15, flexShrink: 0, width: 60}} />
                  <div>
                    <Title level={4} style={{marginBottom: 5}}>
                      {cluster.name}
                    </Title>
                    <Text type={'secondary'}>{cluster.hostnames[0]}</Text>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {/*<Divider>机构</Divider>*/}
        {/*<Row gutter={20}>*/}
        {/*  {orgSiteList.map(site => (*/}
        {/*    <Col span={8} key={site.link}>*/}
        {/*      <div className={'massa-shadow-item'} onClick={() => window.open(site.link)}>*/}
        {/*        <img src={site.logo} alt="" style={{height: 60, marginRight: 15}} />*/}
        {/*        <div>*/}
        {/*          <Title level={4} style={{marginBottom: 5}}>*/}
        {/*            {site.name}*/}
        {/*          </Title>*/}
        {/*          <Text type={'secondary'}>{site.link}</Text>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </Col>*/}
        {/*  ))}*/}
        {/*</Row>*/}
      </div>
      <Flex justify={'center'} className={'p-20'}>
        &copy; 2021{' '}
        <a className="massa-hover-a" href="http://beian.miit.gov.cn">
          沪ICP备18032283号-2
        </a>
      </Flex>
    </div>
  );
}
