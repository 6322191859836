import React, {useEffect} from 'react';
import useAuth from './AuthContext';
import {useOpenApiFpRequest} from '../Http/useOpenApiRequest';
import {AuthorizeApi, UserDto} from '../scaffold';
import CommonApiErrorHandler from './HttpInstance';
import {useHistory} from 'react-router-dom';
import {useBoolean} from 'react-hanger';

export default function useAuthCheck() {
  const auth = useAuth();
  const history = useHistory();
  const checkState = useOpenApiFpRequest(AuthorizeApi, AuthorizeApi.prototype.authorizeHeartbeatGet);
  const isChecking = useBoolean(false);
  function check() {
    const token = localStorage.getItem('Application-Token');
    if (token) {
      isChecking.setTrue();
      checkState
        .request({body: token})
        .then(res => {
          auth.setCurrentUser(res.user as UserDto);
          auth.setToken(token);
          setTimeout(() => {
            isChecking.setFalse();
          }, 1000);
        })
        .catch(() => {
          history.push('/login');
          isChecking.setFalse();
        });
    } else {
      history.push('/login');
    }
  }

  return {
    isChecking: isChecking.value,
    check,
  };
}
