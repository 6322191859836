import React from 'react';
import {Card, Col, PageHeader, Result, Row, Space, Table} from 'antd';
import {Config} from '../../config';
import {useCluster} from '../../utils/ClusterProvider';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {CourseApi, CourseDto, ExamApi, ExamDto, SystemApi} from '../../scaffold';
import {useMount} from 'ahooks';
import Title from 'antd/lib/typography/Title';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';
import Flex from '../../components/Shared/Flex';
import CreateSubmissionBtn from '../../components/Exam/CreateSubmissionBtn';

export default function AppHomePage() {
  const cluster = useCluster();
  const listCourse = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseSearchGet);
  const listExam = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSearchGet);
  const listNotice = useOpenApiFpRequest(SystemApi, SystemApi.prototype.systemListNoticeGet);
  const tabLayout = useTabLayout();
  useMount(() => {
    listCourse.requestSync({pi: 1, ps: 5});
    listExam.requestSync({pi: 1, ps: 5});
    listNotice.requestSync({pi: 1, ps: 5});
  });
  return (
    <div>
      <PageHeader title={cluster.cluster?.title} style={{background: 'white'}} />
      <Row className={'p-20'} gutter={30}>
        <Col span={8}>
          <Flex justify={'space-between'} align={'center'}>
            <Title level={2}>通知</Title>
          </Flex>
          <Table loading={listCourse.loading} dataSource={listNotice.data || []} pagination={false}>
            <Table.Column title={'通知标题'} dataIndex={'topic'} />
            <Table.Column<CourseDto> title={''} width={80} render={(_, item) => <a onClick={() => tabLayout.goTo('/app/system/notice/' + item?.id)}>详情</a>} />
          </Table>
          <div className={'text-center m-t-10'}>
            <a onClick={() => tabLayout.goTo('/app/course')}>查看更多</a>
          </div>
        </Col>
        <Col span={8}>
          <Flex justify={'space-between'} align={'center'}>
            <Title level={2}>课程</Title>
          </Flex>
          <Table loading={listCourse.loading} dataSource={listCourse.data?.list || []} pagination={false}>
            <Table.Column title={'课程名称'} dataIndex={'name'} />
            <Table.Column<CourseDto> title={''} width={80} render={(_, item) => <a onClick={() => tabLayout.goTo('/app/course?id=' + item?.id)}>详情</a>} />
          </Table>
          <div className={'text-center m-t-10'}>
            <a onClick={() => tabLayout.goTo('/app/course')}>查看更多</a>
          </div>
        </Col>
        <Col span={8}>
          <Flex justify={'space-between'} align={'center'}>
            <Title level={2}>考试</Title>
          </Flex>
          <Table loading={listExam.loading} dataSource={listExam.data?.list || []} pagination={false}>
            <Table.Column title={'考试名称'} dataIndex={'name'} />
            <Table.Column<ExamDto>
              title={'操作'}
              width={100}
              render={(_, item) => <Space>{item.isAllowSubmit && <CreateSubmissionBtn exam={item} />}</Space>}
            />
          </Table>
          <div className={'text-center m-t-10'}>
            <a onClick={() => tabLayout.goTo('/app/exam/list')}>查看更多</a>
          </div>
        </Col>
      </Row>
    </div>
  );
}
