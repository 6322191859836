import React from 'react';
import {ExamSubmissionDto, QuestionType, SubmitQuestionDetailDto, UserRole} from '../../scaffold';
import {Drawer, Space} from 'antd';
import Text from 'antd/lib/typography/Text';
import QuestionDisplay from '../Question/QuestionDisplay';
import MarkdownRender from '../Shared/MarkdownRender';
import CodingSubmissionTable from '../CodingSubmission/Table';
import {useBoolean} from 'react-hanger';
import useAuth from '../../utils/AuthContext';

export default function QuestionSubmissionDetail(props: {info: SubmitQuestionDetailDto; submission: ExamSubmissionDto; index: number}) {
  const info = props.info;
  const index = props.index;
  const score = props.submission?.paper?.questionDetailGroups?.find(g => g.questionIds.includes(info.questionId as number))?.eachScore || 0;
  const showList = useBoolean(false);
  const auth = useAuth();
  let color = '#EEEFF3';
  if (info.isMarked) {
    if (info.scoreGet === 0) color = '#f8bebe';
    if (info.scoreGet === score && info.scoreGet > 0) color = '#9ece74';
    if ((info.scoreGet as number) < score && (info.scoreGet as number) > 0) color = '#dcb68c';
  }
  return (
    <div key={index}>
      <Space>
        <Text strong>第 {index + 1} 题</Text>
        <Text type={'secondary'}>本题 {score} 分</Text>
        {(info.scoreGet || 0) >= 0 && <Text strong>得分 {info.scoreGet} 分</Text>}
      </Space>
      {info.question && (
        <QuestionDisplay question={info.question} hideRightAnswer={!props.submission.exam?.isShowAnswer && auth.currentUser?.role === UserRole.学生} />
      )}
      <Space className={'m-t-10'} style={{background: color, padding: '10px', width: '100%'}}>
        <Text strong>学生答题: </Text>
        {info.question?.type === QuestionType.论述题 && <MarkdownRender md={info.userInput || ''} className={'smallMarkdown'} />}
        {info.question?.type === QuestionType.选择题 && <Text strong>{info.userInput || '未答题'}</Text>}
        {info.question?.type === QuestionType.填空题 && <Text strong>{info.userInput || '未答题'}</Text>}
        {info.question?.type === QuestionType.编程题 && (
          <a onClick={showList.setTrue}>
            <Text strong>查看提交记录</Text>
          </a>
        )}
      </Space>
      <Drawer title={'提交记录'} footer={false} visible={showList.value} width={1000} destroyOnClose onClose={showList.setFalse}>
        <CodingSubmissionTable query={{submitterId: props.submission.studentId, codingQuestionId: info.question?.id}} />
      </Drawer>
    </div>
  );
}
