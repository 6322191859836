import React, {useEffect} from 'react';
import {Button, Checkbox, Form, Input, message, Radio} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import {ChoiceQuestionDto, EssayQuestionDto, FillQuestionDto, QuestionApi, UserApi, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useList} from 'react-use';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import Flex from '../Shared/Flex';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

export default function EssayQuestionSaveForm(props: {exist?: EssayQuestionDto; onSuccess: () => any}) {
  const keyword = useInput('');
  const topic = useInput('');
  const rightAnswer = useInput('');
  useEffect(() => {
    if (props.exist) {
      keyword.setValue(props.exist.keywords || '');
      topic.setValue(props.exist.topic || '');
      rightAnswer.setValue(props.exist.rightAnswer || '');
    } else {
      keyword.clear();
      topic.clear();
      rightAnswer.clear();
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSaveEssayQuestionPost);

  function save() {
    saveState
      .request({
        saveEssayQuestionParams: {
          id: props.exist?.id,
          keywords: keyword.value,
          topic: topic.value,
          rightAnswer: rightAnswer.value,
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'关键词（用于搜索）'}>
        <Input {...keyword.eventBind} placeholder={'请输入关键词，可以是知识点'} />
      </Form.Item>
      <Form.Item label={'题干'} required>
        <LiveExMdEditor {...topic.valueBind} placeholder={`请输入题干`} style={{background: '#EEEFF3', padding: '5px 15px'}} />
      </Form.Item>
      <Form.Item label={'参考答案'} required>
        <LiveExMdEditor {...rightAnswer.valueBind} placeholder={`请输入题干`} style={{background: '#EEEFF3', padding: '5px 15px'}} />
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
