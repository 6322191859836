import React, {useContext, useState} from 'react';
import {Button, Drawer, message, Modal, Result, Space} from 'antd';
import {CodingLanguage, CodingQuestionDto, CodingSubmissionApi, CodingSubmissionDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import useAuth from '../../utils/AuthContext';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import CodeInput from '../Shared/CodeInput';
import SubmissionDetail from '../CodingSubmission/SubmissionDetail';
import CodingSubmissionTable from '../CodingSubmission/Table';
import {useBoolean} from 'react-hanger';

export default function CodingQuestionAnswerSection(props: {question: CodingQuestionDto; solved?: () => any}) {
  const [code, setCode] = useState('');
  const [language, setLanguage] = useState<CodingLanguage>(CodingLanguage.Cpp);
  const searchHook = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionSearchGet);
  const [recentSubmission, setRecentSubmission] = useState<CodingSubmissionDto>();
  const submitState = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionSubmitPost);
  const showList = useBoolean(false);
  const auth = useAuth();
  function queryRecent() {
    if (!auth.currentUser) return message.info('请登录后操作');
    searchHook
      .request({
        codingQuestionId: props.question.id,
        submitterId: auth.currentUser.id,
        pi: 1,
        ps: 5,
      })
      .then(res => {
        if (!res.list) return;
        if (res.list.length === 0) {
          message.info('没有最近的提交记录');
        } else {
          setRecentSubmission(res.list[0]);
        }
      })
      .catch(CommonApiErrorHandler);
  }

  function submit() {
    if (!auth.currentUser) return message.info('请登录后操作');
    submitState
      .request({
        submitCodingParams: {
          codingQuestionId: props.question.id || 0,
          code,
          language,
        },
      })
      .then(res => {
        setRecentSubmission(res);
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <div>
      {props.question.hasDataPoint ? (
        <div>
          <CodeInput
            code={code}
            language={language}
            onChange={value => {
              setCode(value.code);
              setLanguage(value.language);
            }}
          />
          <Space className={'m-t-10'}>
            <Button size={'large'} disabled={!code} onClick={submit} type={'primary'} loading={submitState.loading}>
              提交代码
            </Button>
            <Button size={'large'} onClick={showList.setTrue}>
              查看我最近的提交
            </Button>
          </Space>
        </div>
      ) : (
        <Result status="404" title="暂无数据" subTitle="这道题目还没有数据，因此无法提交答案进行评测" />
      )}
      <Drawer title={'提交记录:' + recentSubmission?.id} footer={false} width={900} visible={!!recentSubmission} onClose={() => setRecentSubmission(undefined)}>
        {recentSubmission?.id && <SubmissionDetail solved={props.solved} submissionId={recentSubmission.id} />}
      </Drawer>
      <Drawer title={'最近的提交记录'} footer={false} visible={showList.value} width={1000} destroyOnClose onClose={showList.setFalse}>
        <CodingSubmissionTable query={{submitterId: auth.currentUser?.id, codingQuestionId: props.question.id}} />
      </Drawer>
    </div>
  );
}
