import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, InputNumber, message, Radio, Select} from 'antd';
import {useNumber, useBoolean, useInput} from 'react-hanger';
import {ChoiceQuestionDto, EssayQuestionDto, CourseApi, CourseDto, FillQuestionDto, PaperApi, QuestionApi, UserApi, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';

export default function CourseSaveForm(props: {exist?: CourseDto; onSuccess: () => any}) {
  const name = useInput('');
  const description = useInput('');
  const isHide = useBoolean(false);
  const isPublic = useBoolean(false);
  useEffect(() => {
    if (props.exist) {
      name.setValue(props.exist.name || '');
      description.setValue(props.exist.description || '');
      isHide.setValue(!!props.exist.isHide);
      isPublic.setValue(!!props.exist.isPublic);
    } else {
      name.clear();
      description.clear();
      isHide.setFalse();
      isPublic.setFalse();
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseSavePost);

  function save() {
    saveState
      .request({
        saveCourseParams: {
          id: props.exist?.id,
          name: name.value,
          description: description.value,
          isHide: isHide.value,
          isPublic: isPublic.value,
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'课程名称'} required>
        <Input {...name.eventBind} placeholder={'请输入课程名称'} />
      </Form.Item>
      <Form.Item label={'备注'}>
        <Input {...description.eventBind} placeholder={'请输入备注'} />
      </Form.Item>
      <Form.Item label={'是否隐藏'} required>
        <Radio.Group value={isHide.value} onChange={e => isHide.setValue(e.target.value)}>
          <Radio value={true}>学生不可见此课程</Radio>
          <Radio value={false}>学生可见此课程</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'所有人可进入'} required>
        <Radio.Group value={isPublic.value} onChange={e => isPublic.setValue(e.target.value)}>
          <Radio value={true}>所有人可进入</Radio>
          <Radio value={false}>需要配置班级</Radio>
        </Radio.Group>
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
