import React from 'react';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';
import CacheRoute, {CacheSwitch} from 'react-router-cache-route';
import './TabLayout.less';
import TabLayoutSidebar from './TabLayoutSidebar';
import TabLayoutHeader from './TabLayoutHeader';
import {useTabLayout} from './TabLayoutProvider';

export interface ITabLayoutRouteConfigMenu {
  title: string;
  icon?: any;
  submenus?: ITabLayoutRouteConfigMenu[];
  path?: string;
}
export interface ITabLayoutRouteConfigRouteItem {
  path: string;
  component: React.FunctionComponent;
  title?: string;
}
export interface ITabLayoutRouteConfig {
  menus: ITabLayoutRouteConfigMenu[];
  routes: ITabLayoutRouteConfigRouteItem[];
}
export interface ITabLayoutProps {
  routeConfig: ITabLayoutRouteConfig;
  logout?: () => any;
  customHeader?: any;
}
export function TabLayout(props: ITabLayoutProps) {
  const tabLayout = useTabLayout();

  function logout() {
    tabLayout.clear();
    props.logout && props.logout();
  }

  return (
    <div className={'TabLayout'}>
      <TabLayoutSidebar routeConfig={props.routeConfig} logout={logout} />
      <div className={'TabLayoutMain'}>
        <TabLayoutHeader customHeader={props.customHeader} routes={props.routeConfig.routes} />
        <div className={'TabLayoutContent'}>
          <CacheSwitch>
            {props.routeConfig.routes.map(route => (
              <CacheRoute
                key={route.path}
                path={route.path}
                component={route.component}
                cacheKey={(routeProps: any) => routeProps.pathname}
                multiple
                saveScrollPosition
                exact
              />
            ))}
          </CacheSwitch>
        </div>
      </div>
    </div>
  );
}
