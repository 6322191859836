import React, {useEffect} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {ExamSubmissionDto, UserApi} from '../../scaffold';
import Flex from '../Shared/Flex';
import {Card} from 'antd';
import Text from 'antd/lib/typography/Text';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';

export default function ExamGroupSubmission(props: {groupId: number; submissionList: ExamSubmissionDto[]}) {
  const tabLayout = useTabLayout();
  const listState = useOpenApiFpRequest(UserApi, UserApi.prototype.userListGroupStudentGet);
  function refresh() {
    listState.requestSync({id: props.groupId});
  }
  useEffect(() => {
    refresh();
  }, [props.groupId]);
  return (
    <Flex style={{flexWrap: 'wrap'}}>
      {listState.data?.map(user => {
        const exist = props.submissionList.find(i => i.studentId === user.id);
        return (
          <Card key={user.id} className={'m-5'}>
            <Text strong>{user.name}</Text>
            <div>
              <Text type={'secondary'}>{user.username}</Text>
            </div>
            {exist && <a onClick={() => tabLayout.goTo('/manage/exam/submission/mark/' + exist.id)}>得分: {exist.scoreGet} 分</a>}
            {!exist && <Text>未考试</Text>}
          </Card>
        );
      })}
    </Flex>
  );
}
