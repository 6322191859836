import React from 'react';
import {useAuthContext} from 'geestack-toolbox';
import {IUser} from '../../hooks/User';
import {PageHeader, Result} from 'antd';

const ManageHomePage: React.FunctionComponent<any> = () => {
  // const auth = useAuthContext<IUser>();
  return (
    <div>
      <PageHeader title={'管理员后台'} style={{background: 'white'}} />
    </div>
  );
};

export default ManageHomePage;
