import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, InputNumber, message, Radio, Select} from 'antd';
import {useNumber, useBoolean, useInput} from 'react-hanger';
import {
  ChoiceQuestionDto,
  EssayQuestionDto,
  ExamApi,
  ExamDto,
  FillQuestionDto,
  NoticeDto,
  PaperApi,
  QuestionApi,
  SystemApi,
  UserApi,
  UserDto,
} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useList} from 'react-use';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import Flex from '../Shared/Flex';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import ExMdEditor from '../Shared/ExMdEditor';

export default function NoticeSaveForm(props: {exist?: NoticeDto; onSuccess: () => any}) {
  const topic = useInput('');
  const content = useInput('');
  const isHide = useBoolean(false);
  const [publishedDate, setPublishedDate] = useState<Date>(new Date());
  useEffect(() => {
    if (props.exist) {
      topic.setValue(props.exist.topic || '');
      content.setValue(props.exist.content || '');
      isHide.setValue(!!props.exist.isHide);
      setPublishedDate(moment(props.exist.publishedDate).toDate() || new Date());
    } else {
      topic.clear();
      content.clear();
      isHide.setFalse();
      setPublishedDate(new Date());
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(SystemApi, SystemApi.prototype.systemSaveNoticePost);

  function save() {
    saveState
      .request({
        saveNoticeParams: {
          id: props.exist?.id,
          topic: topic.value,
          content: content.value,
          isHide: isHide.value,
          publishedDate: moment(publishedDate).format('YYYY-MM-DD'),
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'通知标题'} required>
        <Input {...topic.eventBind} placeholder={'请输入通知标题'} />
      </Form.Item>
      <Form.Item label={'通知内容'}>
        <ExMdEditor {...content.valueBind} />
      </Form.Item>
      <Form.Item label={'是否隐藏'} required>
        <Radio.Group value={isHide.value} onChange={e => isHide.setValue(e.target.value)}>
          <Radio value={true}>学生不可见</Radio>
          <Radio value={false}>学生可见</Radio>
        </Radio.Group>
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
