import React, {useState} from 'react';
import {Button, Input, Modal, Typography} from 'antd';
import './login.less';
import {Link, useHistory} from 'react-router-dom';
import useAuth from '../../utils/AuthContext';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {AuthorizeApi, NoticeDto, SystemApi, UserDto, UserRole} from '../../scaffold';
import {useInput} from 'react-hanger';
import {Config} from '../../config';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useCluster} from '../../utils/ClusterProvider';
import {useMount} from 'ahooks';
import Flex from '../../components/Shared/Flex';
import moment from 'moment';
import MarkdownRender from '../../components/Shared/MarkdownRender';
import bgImg from '../../assets/background.png';
import loginImg from '../../assets/loginPanel.png';
const LoginPage: React.FunctionComponent<any> = props => {
  const auth = useAuth();
  const cluster = useCluster();
  const username = useInput('');
  const password = useInput('');
  const history = useHistory();
  const loginState = useOpenApiFpRequest(AuthorizeApi, AuthorizeApi.prototype.authorizeLoginPost);
  const listNotice = useOpenApiFpRequest(SystemApi, SystemApi.prototype.systemListNoticeGet);
  const [notice, setNotice] = useState<NoticeDto>();
  useMount(() => {
    listNotice.requestSync({pi: 1, ps: 5});
  });
  function login() {
    loginState
      .request({
        authorizeLoginParams: {
          username: username.value,
          password: password.value,
        },
      })
      .then(res => {
        localStorage.setItem('Application-Token', res.body as string);
        auth.setCurrentUser(res.user as UserDto);
        auth.setToken(res.body as string);
        if (res.user?.role === UserRole.教师 || res.user?.role === UserRole.管理员) {
          history.push('/manage/');
        } else {
          history.push('/app/');
        }
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <div className={'LoginPage'}>
      <img src={bgImg} className={'BgImg'} />
      <h1 className={'PageTitle'}>{cluster.cluster?.title}</h1>
      <Modal title={notice?.topic} footer={false} onCancel={() => setNotice(undefined)} visible={!!notice}>
        {notice && <MarkdownRender md={notice.content || ''} />}
      </Modal>
      {/*<div className={'Panel'}>*/}
      {/*  <video autoPlay src={'http://zoloft-public.oss-cn-hangzhou.aliyuncs.com/coding.mp4'} loop muted />*/}
      <div className={'NoticeList'}>
        <div style={{fontSize: 20, color: 'white', marginBottom: 10}}>最近通知:</div>
        {listNotice.data?.map(item => (
          <Flex key={item.id} justify={'space-between'} style={{cursor: 'pointer', marginBottom: 5}} onClick={() => setNotice(item)}>
            <span>{item.topic}</span>
            <span>{moment(item.publishedDate).format('YYYY-MM-DD')}</span>
          </Flex>
        ))}
      </div>
      {/*  <div className={'wave'} />*/}
      <img src={loginImg} className={'LoginFormBg'} />
      <div className={'LoginForm'}>
        <div>
          <div style={{textAlign: 'center', margin: 20, fontSize: 28, color: 'white', fontWeight: 'bold'}}>用 户 登 录</div>
          <Input size={'large'} placeholder={'用户名'} className={'m-b-20'} {...username.eventBind} />
          <Input.Password size={'large'} placeholder={'密码'} className={'m-b-20'} {...password.eventBind} />
          <Button block size={'large'} type={'primary'} className={'m-b-10'} onClick={login} loading={loginState.loading}>
            登录
          </Button>
          <Link to={'/app/a'}>
            <Typography.Text type={'secondary'} style={{fontSize: 12, color: 'white'}}>
              版本号：v{Config.version}
            </Typography.Text>
          </Link>
        </div>
      </div>
      {/*</div>*/}
    </div>
  );
};
export default LoginPage;
