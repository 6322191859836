import React, {useState} from 'react';
import {Button, Drawer, PageHeader, Space, Table, Tag} from 'antd';
import ImportUserExcel from '../../../components/User/ImportExcel';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {CourseApi, CourseDto, PaperApi, PaperDto, QuestionDto, QuestionType, UserApi, UserDto, UserRole} from '../../../scaffold';
import {useHistory} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useBoolean, useInput} from 'react-hanger';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import {useMount} from 'react-use';
import CourseSaveForm from '../../../components/Course/SaveForm';
import useQuickRouter from '../../../utils/useQuickRouter';
import CourseSetGroups from '../../../components/Course/SetGroups';

export default function ManageCourseListPage() {
  const history = useHistory();
  const tabLayout = useTabLayout();
  const quickRouter = useQuickRouter();
  const listState = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseSearchGet);

  const keyword = useInput('');
  const displayRows = listState.data?.list?.filter(i => PaperKeywordFilter(i, keyword.value));

  const isDialogSave = useBoolean(false);
  const isDialogGroup = useBoolean(false);
  const [selected, setSelected] = useState<CourseDto>();

  function refresh() {
    listState.requestSync({pi: 1, ps: 9999});
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader
        title={'课程列表'}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button
              type={'primary'}
              onClick={() => {
                isDialogSave.setTrue();
                setSelected(undefined);
              }}>
              添加课程
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
        <Table size={'small'} className={'m-t-10'} bordered dataSource={displayRows || []} rowKey={'id'} loading={listState.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} width={100} />
          <Table.Column title={'课程名称'} dataIndex={'name'} />
          <Table.Column<CourseDto>
            width={80}
            align={'center'}
            title={'是否隐藏'}
            render={(_, item) => (item.isHide ? <Tag color={'red'}>隐藏</Tag> : <Tag color={'green'}>可见</Tag>)}
          />
          <Table.Column<CourseDto>
            width={130}
            align={'center'}
            title={'所有人可进入?'}
            render={(_, item) => (item.isPublic ? <Tag color={'green'}>所有人可进入</Tag> : <Tag color={'red'}>需要配置班级</Tag>)}
          />
          <Table.Column<CourseDto>
            title={'操作'}
            width={330}
            render={(_, item) => (
              <Space>
                <a
                  onClick={() => {
                    isDialogSave.setTrue();
                    setSelected(item);
                  }}>
                  编辑
                </a>
                <a onClick={() => tabLayout.goTo('/manage/course/edit/' + item.id)}>管理课程内容</a>
                <a
                  onClick={() => {
                    isDialogGroup.setTrue();
                    setSelected(item);
                  }}>
                  配置班级
                </a>
                {/*<a onClick={() => quickRouter.paperPreview(item.paperId || 0)}>预览试卷</a>*/}
                {/*<a onClick={() => tabLayout.goTo('/manage、/course/analyse/' + item.id)}>考试分析</a>*/}
              </Space>
            )}
          />
        </Table>
        <Drawer width={700} title={(selected ? '编辑' : '添加') + '课程'} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
          <CourseSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        </Drawer>
        <Drawer width={700} title={'参与班级'} destroyOnClose visible={isDialogGroup.value} onClose={isDialogGroup.setFalse}>
          {selected && <CourseSetGroups course={selected} />}
        </Drawer>
      </div>
    </div>
  );
}
