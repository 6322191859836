import React, {useState} from 'react';
import {CodingQuestionDto, QuestionApi, QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest, QuestionDto, QuestionType, UserDto} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {Button, Card, Drawer, Input, List, PageHeader, Select, Space, Table, Tag} from 'antd';
import {CodingQuestionSelectedBtn} from '../../../components/Question/SelectCodingQuestionTable';
import {useList} from 'react-use';
import {useArray} from 'react-hanger';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
interface QuestionItem {
  id: number;
  topic: string;
  status: '等待复制' | '复制中' | '已完成复制' | '复制失败';
}
export default function ManageImportCodingQuestionPage() {
  const tabLayout = useTabLayout();
  const questionList = useArray<QuestionItem>([]);
  const copyHook = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionCopyCodingQuestionPost);
  const toCopyList = questionList.value.filter(i => i.status !== '已完成复制');
  async function startCopy() {
    const list = JSON.parse(JSON.stringify(toCopyList)) as QuestionItem[];
    for (const item of list) {
      try {
        questionList.modifyById(item.id, {...item, status: '复制中'});
        await copyHook.request({findParams: {id: item.id}});
        questionList.modifyById(item.id, {...item, status: '已完成复制'});
      } catch (e) {
        questionList.modifyById(item.id, {...item, status: '复制失败'});
        CommonApiErrorHandler(e);
      }
    }
  }

  return (
    <div>
      <PageHeader
        title={'从致远导入编程题'}
        style={{background: 'white'}}
        extra={
          <Space>
            <CodingQuestionSelectedBtn
              clusterId={2}
              onConfirm={async v => {
                const list = [...questionList.value];
                v.forEach(i => {
                  if (!list.find(j => j.id === i.id)) {
                    list.push({id: i.id || 0, topic: i.topic || '', status: '等待复制'});
                  }
                });
                questionList.setValue(list);
              }}
              type={'primary'}>
              选择题目
            </CodingQuestionSelectedBtn>
          </Space>
        }
      />
      <Card
        className={'m-20'}
        title={'已选择题目'}
        extra={
          <Space>
            <Button disabled={toCopyList.length === 0} onClick={startCopy} loading={copyHook.loading}>
              开始复制
            </Button>
          </Space>
        }>
        <List
          pagination={false}
          dataSource={questionList.value}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta title={item.topic} />
              {item.status === '等待复制' && <Tag>{item.status}</Tag>}
              {item.status === '复制中' && <Tag color={'warning'}>{item.status}</Tag>}
              {item.status === '已完成复制' && <Tag color={'success'}>{item.status}</Tag>}
              {item.status === '复制失败' && <Tag color={'error'}>{item.status}</Tag>}
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}
