import React, {useEffect} from 'react';
import {ExamApi, ExamDto, GroupDto} from '../../scaffold';
import {Button, Space, Table} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useMount} from 'react-use';
import {GroupSelectedBtn} from '../User/SelectGroupTable';
export default function ExamSetGroups(props: {exam: ExamDto}) {
  const findState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindGet);
  const setGroupsState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSetGroupsPost);
  const tabLayout = useTabLayout();
  function refresh() {
    findState
      .request({id: props.exam.id})
      .then(res => {
        tabLayout.setTitle('预览试卷:' + res.name);
      })
      .catch(CommonApiErrorHandler);
  }

  useEffect(() => {
    if (props.exam) refresh();
  }, [props.exam]);

  function remove(groupId: number) {
    const groupIds = findState.data?.groups?.map(i => i.id as number) || [];
    groupIds.splice(groupIds.indexOf(groupId), 1);
    setGroupsState
      .request({examSetGroupParams: {id: props.exam.id || 0, groupIds}})
      .then(refresh)
      .catch(CommonApiErrorHandler);
  }

  async function add(groups: GroupDto[]) {
    const groupIds = findState.data?.groups?.map(i => i.id as number) || [];
    await setGroupsState.request({examSetGroupParams: {id: props.exam.id || 0, groupIds: [...groupIds, ...groups.map(i => i.id as number)]}});
    refresh();
  }

  return (
    <div>
      <Table className={'m-b-15'} dataSource={findState.data?.groups || []} loading={findState.loading}>
        <Table.Column title={'编号'} dataIndex={'id'} />
        <Table.Column title={'班级名称'} dataIndex={'name'} />
        <Table.Column<GroupDto> title={'操作'} render={(_, item) => <a onClick={() => remove(item.id || 0)}>移除</a>} />
      </Table>
      <GroupSelectedBtn onConfirm={add} type={'primary'}>
        添加班级
      </GroupSelectedBtn>
    </div>
  );
}
