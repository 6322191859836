import * as React from 'react';
import {Typography} from 'antd';
import {
  BuildTwoTone,
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  CloseCircleTwoTone,
  DatabaseTwoTone,
  SyncOutlined,
  ToolTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import {CodingSubmissionStatus} from '../../scaffold';

const {Text, Title, Paragraph} = Typography;
export default function SubmissionStatusCell(props: {status: CodingSubmissionStatus; isOutDate: boolean}) {
  let textColor = '#cf1322';
  if (props.status === '答案正确') textColor = '#389e0d';
  if (props.status === '正在评测') textColor = '#faad14';
  if (props.status === CodingSubmissionStatus.评测机故障) textColor = 'rgba(0, 0, 0, 0.85)';
  if (props.status === '编译失败') textColor = '#531dab';
  return (
    <Text style={{color: textColor}}>
      {props.status === '答案正确' && <CheckCircleTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '答案错误' && <CloseCircleTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '部分正确' && <CloseCircleTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '运行超时' && <ClockCircleTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '内存超出' && <DatabaseTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '编译失败' && <ToolTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '运行时错误' && <BuildTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === CodingSubmissionStatus.评测机故障 && <WarningTwoTone twoToneColor={textColor} className={'m-r-5'} />}
      {props.status === '正在评测' && <SyncOutlined className={'m-r-5'} />}
      <strong>{props.status}</strong>
      {props.isOutDate && <WarningTwoTone twoToneColor={'#faad14'} className={'m-l-5'} />}
    </Text>
  );
}
