import React from 'react';
import {ChoiceQuestionDto, FillQuestionDto, QuestionDto} from '../../scaffold';
import MarkdownRender from '../Shared/MarkdownRender';
import Flex from '../Shared/Flex';
import Text from 'antd/lib/typography/Text';
import {IQuestionDisplayProps} from './QuestionDisplay';
import {Popover, Space} from 'antd';

export default function FillQuestionDisplay(props: IQuestionDisplayProps<FillQuestionDto>) {
  const {question} = props;
  return (
    <div>
      <MarkdownRender className={'smallMarkdown'} md={'**[填空]** ' + question.topic || ''} />
      {!props.hideRightAnswer && (
        <div className={'m-t-5'}>
          <Text strong>
            正确答案:{' '}
            {question?.rightAnswers && (
              <Space>
                {question.rightAnswers[0]}
                {question.rightAnswers.length > 1 && (
                  <Popover
                    trigger={'click'}
                    content={
                      <div>
                        {question.rightAnswers.map((r, i) => (
                          <div key={i}>{r}</div>
                        ))}
                      </div>
                    }>
                    <a>显示更多答案</a>
                  </Popover>
                )}
              </Space>
            )}
          </Text>
        </div>
      )}
    </div>
  );
}
