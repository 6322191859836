import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Checkbox, Divider, Form, Input, message, PageHeader, Radio, Select, Space, Table, Tag} from 'antd';
import {ExamApi, ExamDto, ExamSubmissionDto, UserApi} from '../../../scaffold';
import {useList, useMount} from 'react-use';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import {useParams} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import QuestionDisplay from '../../../components/Question/QuestionDisplay';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {useBoolean, useInput} from 'react-hanger';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import ExamSubmissionKeywordFilter from '../../../utils/keywordFiliter/ExamSubmission';
import ExamGroupSubmission from '../../../components/Exam/GroupSubmission';

export default function ManageExamUserPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const searchState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSearchSubmissionGet);
  const findUser = useOpenApiFpRequest(UserApi, UserApi.prototype.userFindGet);
  const tabLayout = useTabLayout();
  useMount(() => {
    findUser
      .request({id})
      .then(res => {
        tabLayout.setTitle(`考试记录: ${res?.name}`);
        searchState.request({userId: id || 0, pi: 1, ps: 9999}).catch();
      })
      .catch();
  });

  return (
    <div>
      <PageHeader title={`考试记录: ${findUser.data?.name}`} style={{background: 'white'}} />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={searchState.loading}>
          <Table size={'small'} className={'m-t-10'} bordered dataSource={searchState.data?.list || []} rowKey={'id'} loading={searchState.loading}>
            <Table.Column title={'编号'} dataIndex={'id'} width={100} />
            <Table.Column title={'考试名称'} dataIndex={['exam', 'name']} />
            <Table.Column title={'成绩'} dataIndex={['scoreGet']} />
            <Table.Column<ExamDto>
              title={'操作'}
              width={300}
              render={(_, item) => (
                <Space>
                  <a onClick={() => tabLayout.goTo('/manage/exam/submission/result/' + item.id)}>查看答卷</a>
                </Space>
              )}
            />
          </Table>
        </Card>
      </div>
    </div>
  );
}
