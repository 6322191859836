import React, {useEffect} from 'react';
import {Button, Checkbox, Form, Input, message, Radio} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import {ChoiceQuestionDto, FillQuestionDto, QuestionApi, UserApi, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useList} from 'react-use';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import Flex from '../Shared/Flex';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

export default function FillQuestionSaveForm(props: {exist?: FillQuestionDto; onSuccess: () => any}) {
  const keyword = useInput('');
  const topic = useInput('');
  const [rightAnswers, rightAnswersState] = useList<string>(['']);
  useEffect(() => {
    if (props.exist) {
      keyword.setValue(props.exist.keywords || '');
      topic.setValue(props.exist.topic || '');
      rightAnswersState.set(props.exist.rightAnswers || ['']);
    } else {
      keyword.clear();
      topic.clear();
      rightAnswersState.set(['']);
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSaveFillQuestionPost);

  function save() {
    saveState
      .request({
        saveFillQuestionParams: {
          id: props.exist?.id,
          keywords: keyword.value,
          topic: topic.value,
          rightAnswers,
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'关键词（用于搜索）'}>
        <Input {...keyword.eventBind} placeholder={'请输入关键词，可以是知识点'} />
      </Form.Item>
      <Form.Item label={'题干'} required>
        <LiveExMdEditor {...topic.valueBind} placeholder={`请输入题干`} style={{background: '#EEEFF3', padding: '5px 15px'}} />
      </Form.Item>
      <Form.Item label={'参考答案'} required>
        <div className={'p-5 m-b-5'} style={{backgroundColor: 'rgba(122,232,125,0.2)'}}>
          {rightAnswers.map((field, index) => (
            <Flex key={index} justify={'space-between'} align={'center'}>
              <Form.Item style={{marginBottom: 0, flex: 1}}>
                <Input value={field} onChange={e => rightAnswersState.updateAt(index, e.target.value)} placeholder={'请输入参考答案'} />
              </Form.Item>
              <Button style={{flexShrink: 0}} type={'link'} danger disabled={index === 0} onClick={() => rightAnswersState.removeAt(index)}>
                <DeleteOutlined />
                删除
              </Button>
            </Flex>
          ))}
          <Button onClick={() => rightAnswersState.push('')} style={{marginTop: 5}}>
            <PlusOutlined /> 添加一个参考答案
          </Button>
        </div>
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
