import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, InputNumber, message, Radio, Select} from 'antd';
import {useNumber, useBoolean, useInput} from 'react-hanger';
import {CourseArticleApi, CourseArticleDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import PdfUploader from '../Shared/PdfUploader';

export default function CourseArticleSaveForm(props: {exist?: CourseArticleDto; onSuccess: () => any}) {
  const topic = useInput('');
  const content = useInput('');
  const pdfUrl = useInput('');
  useEffect(() => {
    if (props.exist) {
      topic.setValue(props.exist.topic || '');
      content.setValue(props.exist.content || '');
      pdfUrl.setValue(props.exist.pdfUrl || '');
    } else {
      topic.clear();
      content.clear();
      pdfUrl.clear();
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(CourseArticleApi, CourseArticleApi.prototype.courseArticleSavePost);

  function save() {
    saveState
      .request({
        saveCourseArticleParams: {
          id: props.exist?.id,
          topic: topic.value,
          content: content.value,
          pdfUrl: pdfUrl.value,
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'教案名称'} required>
        <Input {...topic.eventBind} placeholder={'请输入教案名称'} />
      </Form.Item>
      <Form.Item label={'教案内容'}>
        <LiveExMdEditor {...content.valueBind} style={{background: '#EEEFF3', padding: '5px 15px'}} placeholder={'请输入教案内容'} />
      </Form.Item>
      <Form.Item label={'PDF 教案'}>
        <PdfUploader url={pdfUrl.value} onChange={pdfUrl.setValue} />
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
