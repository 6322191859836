import React from 'react';
import {CodingSubmissionDtoSearchResponseDto, UserRole} from '../scaffold';
import {useAntdTable} from 'ahooks';
import AntDTableUtils from './AntDTableUtils';
import {message} from 'antd';
import {TablePaginationConfig} from 'antd/lib/table';
import {Filter, Sorter} from '@ahooksjs/use-request/lib/antdTypes';
import {CombineService, PaginatedFormatReturn, PaginatedParams} from '@ahooksjs/use-request/lib/types';

export default function useExAntdTable<T>(props: {request: CombineService<PaginatedFormatReturn<T>, PaginatedParams>; deps: any[]}) {
  const antdTable = useAntdTable(props.request, {
    refreshDeps: [...props.deps],
    defaultPageSize: 15,
    onError: e => message.error(e.message),
  });
  const pagination: TablePaginationConfig = {
    ...antdTable.tableProps.pagination,
    size: 'default',
    defaultPageSize: 15,
    showTotal: total => `共 ${total} 条`,
    pageSizeOptions: ['15', '50', '200', '1000', '5000', '20000'],
  };

  return {
    ...antdTable,
    pagination,
  };
}
