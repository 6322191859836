import React, {useMemo, useState} from 'react';
import {Alert, Badge, Button, Input, InputNumber, Popconfirm, Popover, Space, Table, Tag, Tooltip} from 'antd';
import {EditOutlined, ExportOutlined, SearchOutlined} from '@ant-design/icons';
import filesize from 'filesize';
import {TableRowSelection} from 'antd/lib/table/interface';
import Text from 'antd/lib/typography/Text';
import {DataPointApi, DataPointDto, QuestionDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
export interface IDataPointTask {
  name: string;
  dataPointList: DataPointDto[];
}
export default function DataPointTaskManage(props: {task: IDataPointTask; question: QuestionDto; refresh: () => any; taskNameList: string[]}) {
  const batchDeleteState = useOpenApiFpRequest(DataPointApi, DataPointApi.prototype.dataPointBatchDeletePost);
  const batchUpdateState = useOpenApiFpRequest(DataPointApi, DataPointApi.prototype.dataPointBatchUpdatePost);
  const updateTaskState = useOpenApiFpRequest(DataPointApi, DataPointApi.prototype.dataPointUpdateTaskPost);
  const updateTaskNameState = useOpenApiFpRequest(DataPointApi, DataPointApi.prototype.dataPointUpdateTaskNamePost);
  const [score, setScore] = useState(10);
  const [selectedDataPointList, setSelectedDataPointList] = useState<DataPointDto[]>([]);
  const [taskName, setTaskName] = useState(props.task.name);
  const isUnionMode = (props.task.dataPointList[0] && props.task.dataPointList[0].unionMode) || false;
  const totalScore = useMemo(() => {
    if (isUnionMode) return props.task.dataPointList[0].score;
    let res = 0;
    props.task.dataPointList.forEach(i => (res += i.score || 0));
    return res;
  }, [props.task]);

  const rowSelection: TableRowSelection<DataPointDto> = {
    type: 'checkbox',
    onChange(_, selectedRows: DataPointDto[]) {
      setSelectedDataPointList(selectedRows);
    },
    selectedRowKeys: selectedDataPointList.map(p => p.id as number),
  };

  function batchDelete() {
    batchDeleteState
      .request({batchDeleteDataPointParams: {dataPointIds: selectedDataPointList.map(i => i.id || 0), questionId: props.question.id || 0}})
      .then(() => {
        props.refresh();
        setSelectedDataPointList([]);
      })
      .catch(CommonApiErrorHandler);
  }

  function batchUpdateScore() {
    batchUpdateState
      .request({
        batchUpdateDataPointParams: {
          dataPointIds: selectedDataPointList.map(i => i.id || 0),
          score,
          unionMode: isUnionMode,
          questionId: props.question.id || 0,
        },
      })
      .then(() => {
        props.refresh();
        setSelectedDataPointList([]);
      })
      .catch(CommonApiErrorHandler);
  }

  function updateTaskUnionMode(unionMode: boolean) {
    updateTaskState
      .request({
        updateTaskParams: {
          taskName,
          score,
          unionMode,
          questionId: props.question.id || 0,
        },
      })
      .then(props.refresh)
      .catch(CommonApiErrorHandler);
  }
  function updateTaskName() {
    updateTaskNameState
      .request({
        updateDataPointTaskNameParams: {
          questionId: props.question.id || 0,
          dataPointIds: props.task.dataPointList.map(i => i.id || 0),
          taskName: taskName,
        },
      })
      .then(props.refresh)
      .catch(CommonApiErrorHandler);
  }
  function batchUpdateTaskName(taskName: string) {
    updateTaskNameState
      .request({
        updateDataPointTaskNameParams: {
          questionId: props.question.id || 0,
          dataPointIds: selectedDataPointList.map(i => i.id || 0),
          taskName,
        },
      })
      .then(() => {
        props.refresh();
        setSelectedDataPointList([]);
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <div>
      <Space className={'m-b-10'}>
        <Popconfirm title="此操作将永久删除选中数据点, 是否继续?" onConfirm={batchDelete} okText="确定" cancelText="取消">
          <Button loading={batchDeleteState.loading} danger disabled={selectedDataPointList.length === 0}>
            删除选中
          </Button>
        </Popconfirm>
        {!isUnionMode && (
          <Popover
            title={'联合计分'}
            trigger={'click'}
            content={
              <div>
                <p>联合计分模式是指全组数据点全部正确时才得到分组的总分</p>
                <p>请输入本组的分数:</p>
                <Space>
                  <InputNumber value={score} onChange={e => setScore(e as number)} />
                  <Button type={'primary'} onClick={() => updateTaskUnionMode(true)}>
                    确定
                  </Button>
                </Space>
              </div>
            }>
            <Button loading={updateTaskState.loading}>切换计分模式为联合计分</Button>
          </Popover>
        )}
        {selectedDataPointList.length > 0 && (
          <Popover
            title={'移到其它分组'}
            content={props.taskNameList.map(task => (
              <div
                className={'hover pv-sm bb'}
                key={task}
                onClick={() => {
                  batchUpdateTaskName(task);
                }}>
                {task}
              </div>
            ))}>
            <Button loading={updateTaskState.loading}>移动到其他分组</Button>
          </Popover>
        )}

        {isUnionMode && (
          <Popover
            title={'独立计分'}
            trigger={'click'}
            content={
              <div>
                <p>独立计分模式是指做对一个数据点就得到该数据点的分数，</p>
                <p>请输入本组各个点的默认分数:</p>
                <Space>
                  <InputNumber value={score} onChange={e => setScore(e as number)} />
                  <Button type={'primary'} onClick={() => updateTaskUnionMode(false)}>
                    确定
                  </Button>
                </Space>
              </div>
            }>
            <Button loading={updateTaskState.loading}>切换计分模式为独立计分</Button>
          </Popover>
        )}
        {!isUnionMode && (
          <Popover
            title={'设置分数'}
            trigger={'click'}
            content={
              <div>
                <p>请输入分数:</p>
                <Space>
                  <InputNumber value={score} onChange={e => setScore(e as number)} />
                  <Button type={'primary'} onClick={() => batchUpdateScore()} disabled={selectedDataPointList.length === 0}>
                    确定
                  </Button>
                </Space>
              </div>
            }>
            <Button loading={batchUpdateState.loading} disabled={selectedDataPointList.length === 0}>
              修改选中分数
            </Button>
          </Popover>
        )}
        <Popover
          title={'请输入新名称'}
          trigger={'click'}
          content={
            <Space>
              <Input value={taskName} onChange={e => setTaskName(e.target.value)} />
              <Button type={'primary'} onClick={updateTaskName}>
                确定
              </Button>
            </Space>
          }>
          <Button loading={updateTaskState.loading}>修改分组名称</Button>
        </Popover>
      </Space>
      <Alert
        className={'m-b-10'}
        showIcon
        message={
          <Text>
            本组当前为 <Text strong>{isUnionMode ? '联合计分' : '独立计分'}</Text>, 总分为 {totalScore} 分
          </Text>
        }
      />
      <Table<DataPointDto>
        pagination={false}
        size={'small'}
        rowKey={'id'}
        className={'massa-table'}
        dataSource={props.task.dataPointList}
        rowSelection={rowSelection}>
        <Table.Column<DataPointDto>
          title={'编号'}
          render={(_, item) => (
            <Space>
              <Tooltip title={'首行前 30 位: ' + item.summary}>
                <a>
                  <SearchOutlined />
                </a>
              </Tooltip>
              {item.id}
            </Space>
          )}
        />
        <Table.Column<DataPointDto> title={'大小'} render={(_, item) => filesize(item.size || 0)} />
        <Table.Column<DataPointDto> title={'分值'} render={(_, item) => (isUnionMode ? '联合计分' : item.score + ' 分')} />
        <Table.Column<DataPointDto>
          title={'备注'}
          render={(_, item) => (
            <Space>
              <a>
                <EditOutlined />
              </a>
              {item.note}
            </Space>
          )}
        />
      </Table>
    </div>
  );
}
