import React, {useEffect, useState} from 'react';
import {CodingQuestionDto, QuestionApi, QuestionDto, QuestionType, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Button, Drawer, Input, message, Radio, Space, Table, Tag} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {useBoolean, useInput} from 'react-hanger';
import SearchAndRefresh from '../Shared/SearchAndRefresh';
import QuestionKeywordFilter from '../../utils/keywordFiliter/Question';
import QuestionDisplay from './QuestionDisplay';
import useExAntdTable from '../../utils/useExAntdTable';
import AntDTableUtils from '../../utils/AntDTableUtils';
import ManageDataPointBtn from './ManageDataPointBtn';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';

export interface ICodingQuestionSelectedBtnProps extends ButtonProps {
  onConfirm: (Questions: QuestionDto[]) => Promise<any>;
  clusterId?: number;
}
export function CodingQuestionSelectedBtn(props: ICodingQuestionSelectedBtnProps) {
  const {onConfirm, clusterId, ...btnProps} = props;
  const [value, setValue] = useState<QuestionDto[]>([]);
  const isDialog = useBoolean(false);
  const loading = useBoolean(false);
  async function onSelect() {
    try {
      loading.setTrue();
      await props.onConfirm(value);
      loading.setFalse();
      setValue([]);
      isDialog.setFalse();
    } catch (e) {
      message.error(e.message);
      loading.setFalse();
    }
  }
  return (
    <React.Fragment>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        {props.children}
      </Button>
      <Drawer width={1000} title={'请选择题目'} visible={isDialog.value} onClose={isDialog.setFalse}>
        <CodingQuestionSelectedTable value={value} clusterId={clusterId} onChange={setValue} />
        <Button type={'primary'} loading={loading.value} onClick={onSelect} disabled={value.length === 0}>
          确定选择 {value.length} 题
        </Button>
      </Drawer>
    </React.Fragment>
  );
}
export interface ICodingQuestionSelectedTableProps {
  value: QuestionDto[];
  onChange: (v: QuestionDto[]) => any;
  clusterId?: number;
}
export default function CodingQuestionSelectedTable(props: ICodingQuestionSelectedTableProps) {
  const listState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSearchQuestionGet);
  const keyword = useInput('');
  const searchHook = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionAdvanceSearchCodingQuestionGet);
  const exAntDTable = useExAntdTable({
    request: params => {
      const tableParams = AntDTableUtils.makeParams(params);
      return searchHook.request({
        ...tableParams,
        keywords: keyword.value,
        clusterId: props.clusterId,
      });
    },
    deps: [],
  });
  function refresh() {
    exAntDTable.refresh();
  }
  return (
    <div>
      <Space>
        <Space>
          <Input.Search {...keyword.eventBind} onSearch={refresh} placeholder={'输入关键词检索'} />
          <Button onClick={refresh} loading={searchHook.loading}>
            刷新
          </Button>
        </Space>
      </Space>
      <Table
        size={'small'}
        bordered
        className={'m-t-10'}
        {...exAntDTable.tableProps}
        pagination={exAntDTable.pagination}
        rowKey={'id'}
        loading={listState.loading}
        rowSelection={{
          selectedRowKeys: props.value.map(i => i.id as number),
          onChange(_, rows) {
            props.onChange(rows);
          },
        }}>
        <Table.Column title={'编号'} dataIndex={'id'} width={100} />
        <Table.Column<CodingQuestionDto> title={'题目标题'} dataIndex={'topic'} />
        <Table.Column<CodingQuestionDto> title={'所在课程'} render={(_, item) => item.courseNames?.map(name => <Tag key={name}>{name}</Tag>)} />
        <Table.Column<CodingQuestionDto>
          title={'数据'}
          width={70}
          align={'center'}
          render={(_, item) => (item.versionMd5 ? <Tag color={'green'}>有数据</Tag> : <Tag color={'red'}>无数据</Tag>)}
        />
      </Table>
    </div>
  );
}
