import React, {useEffect, useState} from 'react';
import {UserApi, UserDto, UserRole} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Button, Drawer, message, Table} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {useInput, useBoolean} from 'react-hanger';
import SearchAndRefresh from '../Shared/SearchAndRefresh';
import UserKeywordFilter from '../../utils/keywordFiliter/User';

export interface IUserSelectedTableProps {
  value: UserDto[];
  onChange: (v: UserDto[]) => any;
  role: UserRole;
}
export interface IUserSelectedBtnProps extends ButtonProps {
  role: UserRole;
  onConfirm: (users: UserDto[]) => Promise<any>;
}
export function UserSelectedBtn(props: IUserSelectedBtnProps) {
  const {role, ...btnProps} = props;
  const [value, setValue] = useState<UserDto[]>([]);
  const isDialog = useBoolean(false);
  const loading = useBoolean(false);
  async function onSelect() {
    try {
      loading.setTrue();
      await props.onConfirm(value);
      loading.setFalse();
      setValue([]);
      isDialog.setFalse();
    } catch (e) {
      message.error(e.message);
      loading.setFalse();
    }
  }
  return (
    <React.Fragment>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        {props.children}
      </Button>
      <Drawer width={600} title={'请选择' + UserRole[role]} visible={isDialog.value} onClose={isDialog.setFalse}>
        <UserSelectedTable value={value} onChange={setValue} role={role} />
        <Button type={'primary'} loading={loading.value} onClick={onSelect} disabled={value.length === 0}>
          确定选择 {value.length} 位
        </Button>
      </Drawer>
    </React.Fragment>
  );
}

export default function UserSelectedTable(props: IUserSelectedTableProps) {
  const listState = useOpenApiFpRequest(UserApi, UserApi.prototype.userSearchGet);
  const keyword = useInput('');
  function refresh() {
    listState.requestSync({userRole: props.role, pi: 1, ps: 9999});
  }
  useEffect(() => {
    refresh();
  }, []);

  const displayRows = listState.data?.list?.filter(i => UserKeywordFilter(i, keyword.value));
  return (
    <div>
      <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
      <Table
        size={'small'}
        bordered
        className={'m-t-10'}
        dataSource={displayRows || []}
        rowKey={'id'}
        loading={listState.loading}
        rowSelection={{
          selectedRowKeys: props.value.map(i => i.id as number),
          onChange(_, rows) {
            props.onChange(rows);
          },
        }}>
        <Table.Column title={'编号'} dataIndex={'id'} />
        <Table.Column title={'姓名'} dataIndex={'name'} />
        <Table.Column title={'学号'} dataIndex={'username'} />
      </Table>
    </div>
  );
}
