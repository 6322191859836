import React from 'react';
import {useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {ExamApi, QuestionType} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useMount} from 'react-use';
import {Card, Divider, PageHeader, Space} from 'antd';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import QuestionSubmissionDetail from '../../../components/Exam/QuestionSubmissionDetail';

export default function AppExamSubmissionResultPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindSubmissionGet);
  const tabLayout = useTabLayout();

  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle(`查看答卷: ${res?.student?.name}`);
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader title={`查看答卷: ${findState.data?.student?.name}`} style={{background: 'white'}} />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          {findState.data?.submitQuestionDetails?.map((info, index) => (
            <div key={index}>
              {findState.data && <QuestionSubmissionDetail info={info} submission={findState.data} index={index} />}
              <Divider />
            </div>
          ))}
        </Card>
      </div>
    </div>
  );
}
