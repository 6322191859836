import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, PageHeader, Popconfirm, Radio, Select, Space, Table, Tag} from 'antd';
import {
  CodingQuestionDto,
  ExamAnalyseCodingQuestionItemDto,
  ExamApi,
  ExamDto,
  ExamSubmissionDto,
  PaperApi,
  QuestionDto,
  QuestionSolvedDetail,
  QuestionType,
} from '../../../scaffold';
import {useParams} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useBoolean, useInput} from 'react-hanger';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import ExamSubmissionKeywordFilter from '../../../utils/keywordFiliter/ExamSubmission';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';

export default function ManageExamAnalyseCodingQuestionPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindGet);
  const findPaperState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperFindGet);
  const listState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examAnalyseCodingQuestionGet);
  const tabLayout = useTabLayout();
  const [groupId, setGroupId] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<ExamSubmissionDto[]>([]);
  const [questionList, setQuestionList] = useState<CodingQuestionDto[]>([]);
  const [limitTimes, setLimitTime] = useState<'全部显示' | '只显示第一次' | '只显示最后一次'>('全部显示');
  function refresh() {
    setSelectedRow([]);
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle('编程题排名:' + res.name);
        findPaperState.request({id: res.paperId || 0}).then(paper => {
          const qList: QuestionDto[] = [];
          paper.questionDetailGroups?.forEach(g => {
            g.questions?.forEach(q => {
              if (q.type === QuestionType.编程题) {
                qList.push(q);
              }
            });
          });
          setQuestionList(qList);
          if (groupId) {
            listState.requestSync({examId: id, groupId});
          } else {
            listState.setData([]);
          }
        });
      })
      .catch(CommonApiErrorHandler);
  }

  useEffect(() => {
    refresh();
  }, [groupId]);

  const keyword = useInput('');
  const displayList = useMemo(() => {
    if (listState.data) {
      if (limitTimes === '全部显示') return listState.data;
      const list = [...listState.data];
      list.sort((a, b) => {
        const res = moment(b.examSubmission?.createdTime).diff(a.examSubmission?.createdTime);
        return limitTimes === '只显示第一次' ? -res : res;
      });
      const finalList: ExamAnalyseCodingQuestionItemDto[] = [];
      list.forEach(i => {
        if (!finalList.find(j => j.user?.id === i.user?.id)) {
          finalList.push(i);
        }
      });
      finalList.sort((a, b) => (b.totalScoreGet || 0) - (a.totalScoreGet || 0));
      return finalList;
    }
    return [];
  }, [listState.data, limitTimes]);

  return (
    <div>
      <PageHeader title={`编程题排名: ${findState.data?.name}`} style={{background: 'white'}} />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          <Space>
            <Select value={groupId} onChange={setGroupId} placeholder={'筛选班级'} style={{width: 200}} allowClear>
              {findState.data?.groups?.map(g => (
                <Select.Option value={g.id as number} key={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
            <Button onClick={refresh} loading={listState.loading}>
              刷新
            </Button>
            <Radio.Group value={limitTimes} onChange={e => setLimitTime(e.target.value)}>
              <Radio value={'全部显示'}>显示全部提交</Radio>
              <Radio value={'只显示第一次'}>只显示第一次</Radio>
              <Radio value={'只显示最后一次'}>只显示最后一次</Radio>
            </Radio.Group>
          </Space>
          <Table size={'small'} className={'m-t-10'} bordered dataSource={displayList} pagination={{pageSize: 50}} loading={listState.loading}>
            <Table.Column title={'学生姓名'} dataIndex={['user', 'name']} />
            <Table.Column<ExamAnalyseCodingQuestionItemDto> title={'学生学号'} dataIndex={['user', 'username']} />
            <Table.Column<ExamAnalyseCodingQuestionItemDto>
              title={'总分'}
              render={(_, item) => item.totalScoreGet + ' 分'}
              sorter={(a, b) => (a.totalScoreGet || 0) - (b.totalScoreGet || 0)}
            />

            {questionList.map(q => (
              <Table.Column<ExamAnalyseCodingQuestionItemDto>
                title={q.topic}
                key={q.id}
                align={'center'}
                render={(_, item) => {
                  const exist = item.details?.find(i => i.id === q.id);
                  return <Text>{exist && exist.submittedCount ? `${exist.scoreGet} 分` : '未提交'}</Text>;
                }}
              />
            ))}
            <Table.Column<ExamAnalyseCodingQuestionItemDto> title={'进入考试时间'} width={200} render={(_, item) => item.examSubmission?.createdTime} />
            <Table.Column<ExamAnalyseCodingQuestionItemDto>
              title={'操作'}
              width={80}
              render={(_, item) => <a onClick={() => tabLayout.goTo('/manage/exam/submission/result/' + item.examSubmission?.id)}>查看答卷</a>}
            />
          </Table>
        </Card>
      </div>
    </div>
  );
}
