import * as React from 'react';
import 'react-markdown-editor-lite/lib/index.css';
import {Input} from 'antd';
const {TextArea} = Input;
import {CodingQuestionExample} from '../../scaffold';

export default function ExampleInput(props: {value?: CodingQuestionExample; disabled?: boolean; onChange?: (example: CodingQuestionExample) => any}) {
  return (
    <div className={'ExampleInput'}>
      <div className={'ExampleInputHead'}>输入:</div>
      <TextArea
        value={props.value?.input || ''}
        disabled={props.disabled}
        onChange={e => {
          if (props.onChange) {
            props.onChange({output: '', ...props.value, input: e.target.value});
          }
        }}
        placeholder="请输入 Input 样例"
        autoSize={{minRows: 3}}
      />
      <div className={'ExampleInputHead'}>输出:</div>
      <TextArea
        value={props.value?.output || ''}
        disabled={props.disabled}
        onChange={e => {
          if (props.onChange) {
            props.onChange({input: '', ...props.value, output: e.target.value});
          }
        }}
        placeholder="请输入 Input 样例"
        autoSize={{minRows: 3}}
      />
      {(!props.disabled || (props.value && props.value.note)) && (
        <div>
          <div className={'ExampleInputHead'}>说明:</div>
          <TextArea
            value={props.value?.note || ''}
            disabled={props.disabled}
            onChange={e => {
              if (props.onChange) {
                props.onChange({input: '', output: '', ...props.value, note: e.target.value});
              }
            }}
            placeholder="请输入备注"
            autoSize={{minRows: 3}}
          />
        </div>
      )}
    </div>
  );
}
