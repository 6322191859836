import React from 'react';
import {Button, Space, Upload} from 'antd';
import {useAxios} from '../../Http/AxiosProvider';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useBoolean} from 'react-hanger';
import PdfViewer from './PdfViewer';

export default function PdfUploader(props: {url: string; onChange: (v: string) => any}) {
  const axios = useAxios();
  const loading = useBoolean(false);
  function beforeUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    loading.setTrue();
    axios.instance
      .post<string>('User/UploadFile', formData)
      .then(res => {
        props.onChange(res.data);
      })
      .finally(loading.setFalse)
      .catch(CommonApiErrorHandler);
    return false;
  }
  return (
    <div>
      <Space>
        <Upload accept={'application/pdf'} beforeUpload={beforeUpload} showUploadList={false}>
          <Button className={'m-b-10'} type={'primary'} loading={loading.value}>
            {props.url ? '更换 Pdf' : '上传 Pdf'}
          </Button>
        </Upload>
        {props.url && (
          <Button className={'m-l-10 m-b-10'} href={props.url} target={'_blank'}>
            下载
          </Button>
        )}
      </Space>
      {props.url && <PdfViewer url={props.url} />}
    </div>
  );
}
