import React, {useEffect, useState} from 'react';
import Flex from '../../../components/Shared/Flex';
import {CourseApi, CourseDto, CourseLessonItemType} from '../../../scaffold';
import {useSearchParam} from 'react-use';
import {useBoolean} from 'react-hanger';
import {useHistory} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {useMount} from 'ahooks';
import CourseSideList from '../../../components/Course/SideList';
import CourseDetailPanel from '../../../components/Course/CourseDetailPanel';
import CourseQuestionDetail from '../../../components/Course/CourseQuestionDetail';
import CourseArticleDetail from '../../../components/Course/CourseArticleDetail';

export default function AppCourseListPage() {
  const queryDetailId = useSearchParam('detailId') || '0';
  const queryDetailType = useSearchParam('detailType');
  const queryId = useSearchParam('id') || '0';
  const listCourse = useOpenApiFpRequest(CourseApi, CourseApi.prototype.courseSearchGet);
  const [selectedCourse, setSelectedCourse] = useState<CourseDto>();
  useMount(() => {
    listCourse.request({pi: 1, ps: 999}).then(res => {
      if (res.list) {
        setSelectedCourse(res.list?.find(c => c.id === parseInt(queryId)) || res.list[0]);
      }
    });
  });

  const [isBlur, setIsBlur] = useState(false);
  const [detailId, setDetailId] = useState<number>(parseInt(queryDetailId));
  const [detailType, setDetailType] = useState<CourseLessonItemType>(queryDetailType as CourseLessonItemType);
  const detailMode = useBoolean(false);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  // useEffect(() => {
  //   setSelectedCourse(listCourse.data?.list?.find(c => c.id === parseInt(queryId)));
  // }, [queryId]);

  function goToDetail(type: CourseLessonItemType, id: number) {
    setDetailId(id);
    setDetailType(type);
    if (selectedCourse) {
      window.history.pushState(null, '', `/#/app/course?id=${selectedCourse.id}&detailId=${id}&detailType=${type}`);
    }
  }

  function playBlur() {
    if (!isBlur) {
      setIsBlur(true);
      setTimeout(() => {
        setIsBlur(false);
      }, 900);
    }
  }

  useEffect(() => {
    const origin = detailMode.value;
    detailMode.setValue(!!detailId);
    if (origin !== !!detailId) {
      playBlur();
    }
  }, [detailId, detailType]);
  return (
    <Flex style={{overflow: 'hidden', paddingLeft: 1, width: 'calc(100vw-100px)', position: 'relative'}}>
      {selectedCourse && (
        <React.Fragment>
          <CourseSideList
            detailMode={detailMode.value && isActive}
            isBlur={isBlur}
            goBack={() => {
              detailMode.setFalse();
              setDetailId(0);
            }}
            onSelectCourse={setSelectedCourse}
            courseList={listCourse.data?.list || []}
            selectedCourse={selectedCourse}
          />
          <CourseDetailPanel
            setIsActive={setIsActive}
            detailMode={detailMode.value && isActive}
            isBlur={isBlur}
            course={selectedCourse}
            goToDetail={goToDetail}
          />
          {isActive && (
            <div className={`massa-card CourseItemDetailPanel ${detailMode.value ? 'CourseItemDetailPanelDetailMode' : ''}`}>
              {(detailType === CourseLessonItemType.CodingQuestion || detailType === CourseLessonItemType.ExampleCodingQuestion) && (
                <CourseQuestionDetail
                  detailId={detailId}
                  solved={() => {
                    return goToDetail(detailType, detailId);
                  }}
                />
              )}
              {detailType === 'Article' && (
                <CourseArticleDetail
                  detailId={detailId}
                  solved={() => {
                    return goToDetail(detailType, detailId);
                  }}
                />
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </Flex>
  );
}
