import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-use';
import ClusterList, {ICluster} from '../ClusterList';
import WrongClusterPage from '../pages/wrongCluster';
import {useTitle} from 'ahooks';
export interface IClusterContext {
  cluster?: ICluster;
}
export const ClusterContext = React.createContext<IClusterContext>({});

export function useCluster() {
  return useContext(ClusterContext as React.Context<IClusterContext>);
}

export function ClusterProvider(props: {children: any}) {
  const [cluster, setCluster] = useState<ICluster>();
  const location = useLocation();
  useTitle(cluster?.title || '选择网址');

  useEffect(() => {
    console.log(location.host);
    ClusterList.forEach(cluster => {
      if (cluster.hostnames.includes(location.host || '')) {
        setCluster(cluster);
      }
    });
  }, [location.host]);
  return <ClusterContext.Provider value={{cluster}}>{cluster ? props.children : <WrongClusterPage />}</ClusterContext.Provider>;
}
