import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {ExamApi, ExamSubmissionDto, QuestionDto} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import {useList, useMount} from 'react-use';
import {Button, Card, Divider, message, PageHeader, Popconfirm, Space} from 'antd';
import QuestionSubmitInput from '../../../components/Exam/QuestionSubmitInput';
import Text from 'antd/lib/typography/Text';
import Countdown from 'antd/lib/statistic/Countdown';
import moment from 'moment';
export interface ExamQuestionSubmitInfo {
  questionId: number;
  question: QuestionDto;
  score: number;
  userInput: string;
}
export default function AppExamSubmitPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindSubmissionGet);
  const saveState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSaveSubmissionPost);
  const submitState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSubmitSubmissionPost);
  const tabLayout = useTabLayout();
  const location = useLocation();

  const [submitInfoList, submitInfoListState] = useList<ExamQuestionSubmitInfo>([]);

  function applySubmission(submission: ExamSubmissionDto) {
    submitInfoListState.set(
      submission.submitQuestionDetails?.map(i => {
        const score = submission.paper?.questionDetailGroups?.find(g => g.questionIds.includes(i.questionId as number))?.eachScore || 0;
        return {
          score,
          questionId: i.questionId as number,
          question: i.question as QuestionDto,
          userInput: i.userInput || '',
        };
      }) || [],
    );
  }

  async function save() {
    try {
      const res = await saveState.request({
        examSubmissionSaveParams: {
          submissionId: findState.data?.id || 0,
          questionItems: submitInfoList.map(i => ({questionId: i.questionId, userInput: i.userInput})),
        },
      });
      message.success('保存成功');
      applySubmission(res);
    } catch (e) {
      message.error(e.message);
    }
  }

  async function submit() {
    try {
      await save();
      await submitState.request({findParams: {id: findState.data?.id || 0}});
      message.success('交卷成功');
      tabLayout.closeTab(location.pathname);
      tabLayout.goTo('/app/exam/list');
    } catch (e) {
      message.error(e.message);
    }
  }

  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle('答题中:' + res.exam?.name);
        if (res.submittedTime) {
          message.warn('答题已交卷');
          tabLayout.closeTab(location.pathname);
          tabLayout.goTo('/app/exam/list');
        } else {
          applySubmission(res);
        }
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader
        title={`答题中: ${findState.data?.exam?.name}`}
        style={{background: 'white'}}
        extra={
          <Space>
            <Countdown
              title="剩余时间"
              value={findState.data?.expiredTime || ''}
              format="HH:mm:ss"
              onFinish={() => {
                message.success('时间已到，自动交卷.');
                submit();
              }}
            />
            <Divider type={'vertical'} />
            <Button loading={saveState.loading} onClick={save}>
              保存答卷
            </Button>
            <Popconfirm title={'是否要交卷？'} onConfirm={submit}>
              <Button loading={submitState.loading} danger>
                交卷
              </Button>
            </Popconfirm>
          </Space>
        }
      />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          {submitInfoList.map((info, index) => (
            <div key={index}>
              <Space>
                <Text strong>第 {index + 1} 题</Text>
                <Text type={'secondary'}>本题共 {info.score} 分</Text>
              </Space>
              <QuestionSubmitInput
                info={info}
                onChange={i => {
                  submitInfoListState.updateAt(index, i);
                  // setTimeout(() => {
                  //   save();
                  // }, 1000);
                }}
              />
              <Divider />
            </div>
          ))}
        </Card>
      </div>
    </div>
  );
}
