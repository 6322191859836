export interface ICluster {
  name: string;
  nameEn: string;
  hostnames: string[];
  title: string;
  icon?: string;
}

const ClusterList: ICluster[] = [
  {
    name: 'SCS',
    nameEn: 'SCS',
    hostnames: ['scs.noi.top', 'dev.scs.noi.top:3000'],
    title: 'SCS 平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/SCS-logo.png',
  },
  {
    name: '上外附中',
    nameEn: 'SFLS',
    hostnames: ['sfls.noi.top', 'dev.sfls.noi.top:3000'],
    title: '上外附中信息学科学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/SFLS-logo.png',
  },
  {
    name: '世外中学',
    nameEn: 'WFLMS',
    hostnames: ['wflms.noi.top', 'dev.wflms.noi.top:3000'],
    title: '世外在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/WFLMS-logo.png',
  },
  {
    name: '建青实验',
    nameEn: 'JQSY',
    hostnames: ['jqsy.noi.top', 'dev.jqsy.noi.top:3000'],
    title: '建青实验在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/JQSY-logo.png',
  },
  {
    name: '星河湾',
    nameEn: 'SSBS',
    hostnames: ['ssbs.noi.top', 'dev.ssbs.noi.top:3000'],
    title: '星河湾在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/SSBS-logo.png',
  },
  {
    name: '扬波中学',
    nameEn: 'YANGBO',
    hostnames: ['yangbo.noi.top', 'dev.yangbo.noi.top:3000'],
    title: '扬波中学在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/YANGBO-logo.png',
  },
  {
    name: '田家炳',
    nameEn: 'TJB',
    hostnames: ['tjb.noi.top', 'dev.tjb.noi.top:3000'],
    title: '田家炳在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/TJB-logo.png',
  },
  {
    name: '市北初北校',
    nameEn: 'HTZX',
    hostnames: ['htzx.noi.top', 'dev.htzx.noi.top:3000'],
    title: '市北初在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/HTZX-logo.png',
  },
  {
    name: '风华初级中学',
    nameEn: 'FH',
    hostnames: ['fh.noi.top', 'dev.fh.noi.top:3000'],
    title: '风华初级在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/FH-logo.jpeg',
  },
  {
    name: '彭浦三中',
    nameEn: 'PPSZ',
    hostnames: ['ppsz.noi.top', 'dev.ppsz.noi.top:3000'],
    title: '彭浦三中在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/PPSZ-logo.jpeg',
  },
  {
    name: '风范中学',
    nameEn: 'FF',
    hostnames: ['ff.noi.top', 'dev.ff.noi.top:3000'],
    title: '风范中学在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/FF-logo.png',
  },
  {
    name: '新中高级中学',
    nameEn: 'XZ',
    hostnames: ['xz.noi.top', 'dev.xz.noi.top:3000'],
    title: '新中在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/XZ-logo.jpg',
  },
  {
    name: '存志学校',
    nameEn: 'CZS',
    hostnames: ['czs.noi.top', 'dev.czs.noi.top:3000'],
    title: '存志在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/CZS-logo.jpg',
  },
  {
    name: '延安中学',
    nameEn: 'YANAN',
    hostnames: ['yanan.noi.top', 'dev.yanan.noi.top:3000'],
    title: '延安中学在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/YANAN-logo.jpg',
  },
  {
    name: '曹杨二中',
    nameEn: 'CYEZ',
    hostnames: ['cyez.noi.top', 'dev.cyez.noi.top:3000'],
    title: '曹杨二中在线学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/CYEZ-logo.png',
  },
  {
    name: '致远人工智能',
    nameEn: 'ZYAI',
    hostnames: ['zyai.noi.top', 'dev.zyai.noi.top:3000'],
    title: '上海市计算机学会编程平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/ZYAI-logo.png',
  },
  {
    name: '均成教育',
    nameEn: 'JCJY',
    hostnames: ['jcjy.noi.top', 'dev.jcjy.noi.top:3000'],
    title: '均成教育编程平台学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/JCJY-logo.jpg',
  },
  {
    name: '伯禹人工智能',
    nameEn: 'BOYU',
    hostnames: ['boyu.noi.top', 'dev.boyu.noi.top:3000'],
    title: '伯禹编程平台学习平台',
    icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/BOYU-logo.jpg',
  },
  // {
  //   name: '极栈科技',
  //   nameEn: 'GEESTACK',
  //   hostnames: ['geestack.noi.top', 'dev.geestack.noi.top:3000'],
  //   title: '极栈科技编程平台学习平台',
  //   icon: 'https://zoloft-public.oss-cn-hangzhou.aliyuncs.com/logos/GEESTACK-logo.jpg',
  // },
];

export default ClusterList;
