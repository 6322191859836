import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import React from 'react';
import arrayMove from 'array-move';
import {UnorderedListOutlined} from '@ant-design/icons';
import Flex from './Flex';

interface SortableItemProps<T> {
  i: number;
  item: T;
}
interface SortableContainerProps<T> {
  list: T[];
}
interface SortableListProps<T> {
  renderItem: (item: T, index: number) => any;
  list: T[];
  style?: React.CSSProperties;
  afterSort: (newList: T[]) => any;
}

export default function SortableList<T>(props: SortableListProps<T>) {
  const SortableItem = SortableElement<SortableItemProps<T>>((subProps: SortableItemProps<T>) => (
    <Flex align={'center'} className={'bb drag-able'} style={{background: 'white'}}>
      {props.renderItem(subProps.item, subProps.i)}
    </Flex>
  ));

  const SortableContainerComponent = SortableContainer<SortableContainerProps<T>>((subProps: SortableContainerProps<T>) => {
    return (
      <div>
        {subProps.list.map((item, index: number) => (
          <SortableItem key={JSON.stringify(item)} i={index} index={index} item={item} />
        ))}
      </div>
    );
  });

  return (
    <div style={props.style}>
      <SortableContainerComponent
        pressDelay={200}
        lockAxis={'y'}
        list={props.list}
        onSortEnd={res => {
          if (res.oldIndex !== res.newIndex) {
            props.afterSort(arrayMove(props.list, res.oldIndex, res.newIndex));
          }
        }}
      />
    </div>
  );
}
