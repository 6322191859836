import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, Input, message, PageHeader, Popover, Space, Table, Tag, Tooltip} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {GroupDto, UserApi, UserDto, UserRole} from '../../scaffold';
import {useBoolean, useInput} from 'react-hanger';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useParams} from 'react-router-dom';
import UserSaveForm from '../../components/User/SaveForm';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';
import {useMount} from 'react-use';
import ImportUserExcel from '../../components/User/ImportExcel';
import AntDTableUtils from '../../utils/AntDTableUtils';
import useExAntdTable from '../../utils/useExAntdTable';

export default function ManageUserPage() {
  const password = useInput();
  const updatePassword = useOpenApiFpRequest(UserApi, UserApi.prototype.userUpdatePasswordPost);
  const tabLayout = useTabLayout();
  const searchHook = useOpenApiFpRequest(UserApi, UserApi.prototype.userSearchGet);
  const params = useParams() as {role: UserRole} | undefined;
  const keyword = useInput('');
  const isTeacher = params?.role === UserRole.教师;
  const exAntDTable = useExAntdTable({
    request: params => {
      const tableParams = AntDTableUtils.makeParams(params);
      return searchHook.request({
        ...tableParams,
        keywords: keyword.value,
        userRole: isTeacher ? UserRole.教师 : UserRole.学生,
      });
    },
    deps: [isTeacher],
  });
  function refresh() {
    exAntDTable.refresh();
  }
  useMount(() => {
    tabLayout.setTitle(isTeacher ? '教师管理' : '学生管理');
  });

  const isDialogSave = useBoolean(false);
  const [selected, setSelected] = useState<UserDto>();

  return (
    <div>
      <PageHeader
        title={isTeacher ? '教师管理' : '学生管理'}
        style={{background: 'white'}}
        extra={
          <Space>
            {!isTeacher && <ImportUserExcel refresh={refresh} />}
            <Button
              type={'primary'}
              onClick={() => {
                isDialogSave.setTrue();
                setSelected(undefined);
              }}>
              添加 {isTeacher ? '教师' : '学生'} 账号
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <Space>
          <Input.Search {...keyword.eventBind} onSearch={refresh} placeholder={'输入关键词检索'} />
          <Button onClick={refresh} loading={searchHook.loading}>
            刷新
          </Button>
        </Space>
        <Table
          size={'small'}
          className={'m-t-10'}
          bordered
          {...exAntDTable.tableProps}
          pagination={exAntDTable.pagination}
          rowKey={'id'}
          loading={searchHook.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} />
          <Table.Column title={'姓名'} dataIndex={'name'} />
          <Table.Column title={isTeacher ? '教师工号' : '学号'} dataIndex={'username'} />
          {!isTeacher && <Table.Column<UserDto> title={'所在班级'} render={(_, item) => item.groupNames?.map(g => <Tag key={g}>{g}</Tag>)} />}
          <Table.Column<UserDto>
            title={'操作'}
            width={200}
            render={(_, item) => (
              <Space>
                <a
                  onClick={() => {
                    isDialogSave.setTrue();
                    setSelected(item);
                  }}>
                  编辑
                </a>
                {!isTeacher && <a onClick={() => tabLayout.goTo('/manage/exam/user/' + item.id)}>考试记录</a>}
                <Popover
                  title={'修改密码'}
                  content={
                    <Space>
                      <Input.Password {...password.eventBind} placeholder={'请输入新密码'} />
                      <Button
                        danger
                        loading={updatePassword.loading}
                        onClick={() => {
                          updatePassword
                            .request({
                              userUpdatePasswordParams: {
                                password: password.value,
                                id: item?.id || 0,
                              },
                            })
                            .then(() => message.success('修改成功'))
                            .catch(CommonApiErrorHandler);
                        }}>
                        确定修改
                      </Button>
                    </Space>
                  }
                  trigger={'click'}>
                  <a>修改密码</a>
                </Popover>
              </Space>
            )}
          />
        </Table>
      </div>
      <Drawer width={700} title={selected ? '编辑' : '新建'} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
        <UserSaveForm
          isTeacher={isTeacher}
          exist={selected}
          onSuccess={() => {
            refresh();
            isDialogSave.setFalse();
          }}
        />
      </Drawer>
    </div>
  );
}
