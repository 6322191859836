import MarkdownIt from 'markdown-it';
import mk from '@liradb2000/markdown-it-katex';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
const MarkdownParser: MarkdownIt = new MarkdownIt({
  linkify: true,
  typographer: true,
  highlight: function(str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return '<pre class="hljs"><code>' + hljs.highlight(lang, str, true).value + '</code></pre>';
      } catch (__) {}
    }

    return '<pre class="hljs"><code>' + MarkdownParser.utils.escapeHtml(str) + '</code></pre>';
  },
}).use(mk);

MarkdownParser.set({breaks: true});

export default MarkdownParser;
