import React from 'react';
import {useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {ExamApi, ExamSubmissionDto, QuestionType} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useMount} from 'react-use';
import {Button, Card, Checkbox, Divider, message, PageHeader, Space} from 'antd';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import QuestionSubmissionMarkDetail from '../../../components/Exam/QuestionSubmissionMarkDetail';
import {useBoolean} from 'react-hanger';

export default function ManageExamSubmissionMarkPage() {
  const params = useParams() as {id: string} | undefined;
  const pageId = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindSubmissionGet);
  const tabLayout = useTabLayout();
  const autoMarkState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examBatchAutoMarkSubmissionPost);
  const listState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSearchSubmissionGet);
  function refresh(id: number) {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle(`批改答卷: ${res?.student?.name} ${res?.student?.username}`);
      })
      .catch(CommonApiErrorHandler);
  }

  function refreshCurrent() {
    if (findState.data?.id) {
      refresh(findState.data.id);
    }
  }

  useMount(() => {
    refresh(pageId);
  });

  const notMarkOnly = useBoolean(false);

  function next() {
    listState.request({examId: findState.data?.examId || 0, pi: 1, ps: 9999}).then(res => {
      const list = res.list as ExamSubmissionDto[];
      const currentIndex = list.findIndex(i => i.id === findState.data?.id);
      let id = 0;
      for (let i = currentIndex + 1; i < list.length; i++) {
        if (!list[i].isMarked) {
          id = list[i].id as number;
          break;
        }
      }
      if (!id) {
        message.error('没有未批改的试卷了');
      } else {
        refresh(id);
      }
    });
  }

  return (
    <div>
      <PageHeader
        title={`批改答卷: ${findState.data?.student?.name} ${findState.data?.student?.username}`}
        style={{background: 'white'}}
        extra={
          <Space>
            <Checkbox checked={notMarkOnly.value} onChange={e => notMarkOnly.setValue(e.target.checked)}>
              只看未批改与编程题
            </Checkbox>
            <Button
              loading={autoMarkState.loading}
              onClick={() =>
                autoMarkState
                  .request({
                    examBatchAutoMarkSubmissionParams: {
                      ids: [findState.data?.id || 0],
                    },
                  })
                  .then(refreshCurrent)
                  .catch(CommonApiErrorHandler)
              }>
              机器批改
            </Button>
            <Button loading={listState.loading} onClick={next}>
              下一张未批改
            </Button>
          </Space>
        }
      />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          {findState.data?.submitQuestionDetails?.map((info, index) => {
            if (notMarkOnly.value && info.isMarked && info.question?.type !== QuestionType.编程题) return <div />;
            return (
              <div key={index}>
                {findState.data && <QuestionSubmissionMarkDetail refresh={refreshCurrent} info={info} submission={findState.data} index={index} />}
                <Divider />
              </div>
            );
          })}
        </Card>
      </div>
    </div>
  );
}
