import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Divider, Form, Input, message, PageHeader, Radio, Space} from 'antd';
import {ChoiceQuestionScoreType, PaperApi, PaperQuestionDetailGroupDto, PaperQuestionGroup, QuestionDto, UserRole} from '../../../scaffold';
import {useList, useMount} from 'react-use';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import {useParams} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import QuestionDisplay from '../../../components/Question/QuestionDisplay';

export default function ManagePaperPreviewPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperFindGet);
  const tabLayout = useTabLayout();
  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle('预览试卷:' + res.name);
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
  });

  const questionList = useMemo(() => {
    const qList: QuestionDto[] = [];
    findState.data?.questionDetailGroups?.forEach(g => {
      g.questions?.forEach(q => qList.push(q));
    });
    return qList;
  }, [findState.data]);

  return (
    <div>
      <PageHeader
        title={`预览试卷: ${findState.data?.name}`}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button onClick={() => tabLayout.goTo('/manage/paper/edit/' + findState.data?.id)}>编辑</Button>
            <Button loading={findState.loading} onClick={refresh}>
              刷新
            </Button>
          </Space>
        }
      />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          {questionList.map((question, qIndex) => (
            <div key={qIndex}>
              <QuestionDisplay question={question} />
              <Divider />
            </div>
          ))}
        </Card>
      </div>
    </div>
  );
}
