import * as React from 'react';
import {Button, message, Progress} from 'antd';
import {useState} from 'react';
import Flex from '../Shared/Flex';
import UploadButton from '../Shared/UploadButton';
import {useAxios} from '../../Http/AxiosProvider';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {DataPointApi} from '../../scaffold';
import CommonApiErrorHandler from '../../utils/HttpInstance';
function DataPointAdd(props: {questionId: number; onFinish: () => any}) {
  const [isDoingUpload, setIsDoingUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const axios = useAxios();
  const download = useOpenApiFpRequest(DataPointApi, DataPointApi.prototype.dataPointDownloadDataPointsGet);
  async function uploadFile(formData: FormData) {
    setIsDoingUpload(true);
    setProgress(0);
    formData.append('questionId', props.questionId.toFixed());
    try {
      await axios.instance.post('DataPoint/Add', formData, {
        timeout: 1000 * 60 * 60,
        onUploadProgress: e => setProgress(Number(((e.loaded / e.total) * 100).toFixed())),
      });
      setIsDoingUpload(false);
      props.onFinish();
    } catch (e) {
      message.error(e.message);
      setIsDoingUpload(false);
    }
  }
  return (
    <Flex style={{width: 150}} justify={'flex-end'}>
      <Button
        className={'m-r-10'}
        loading={download.loading}
        onClick={() => {
          download
            .request({questionId: props.questionId})
            .then(res => {
              window.open(res, '_blank');
            })
            .catch(CommonApiErrorHandler);
        }}>
        下载数据包
      </Button>
      {progress > 0 && progress !== 100 && isDoingUpload && <Progress percent={progress} size="small" />}
      {(!isDoingUpload || progress === 100) && (
        <UploadButton id={'upload-form'} label={'上传数据'} loading={isDoingUpload} multiple={true} upload={formData => uploadFile(formData)} />
      )}
    </Flex>
  );
}
export default DataPointAdd;
