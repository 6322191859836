import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, InputNumber, message, Radio, Select} from 'antd';
import {useNumber, useBoolean, useInput} from 'react-hanger';
import {ChoiceQuestionDto, EssayQuestionDto, ExamApi, ExamDto, FillQuestionDto, PaperApi, QuestionApi, UserApi, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useList} from 'react-use';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import Flex from '../Shared/Flex';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

export default function ExamSaveForm(props: {exist?: ExamDto; onSuccess: () => any}) {
  const name = useInput('');
  const description = useInput('');
  const isAllowSubmit = useBoolean(false);
  const isHide = useBoolean(false);
  const isShowAnswer = useBoolean(false);
  const isShowScore = useBoolean(false);
  const isAutoCorrect = useBoolean(false);
  const maxSubmitCount = useNumber(1);
  const limitMinute = useNumber(45);
  const [paperId, setPaperId] = useState<number>();
  useEffect(() => {
    if (props.exist) {
      name.setValue(props.exist.name || '');
      description.setValue(props.exist.description || '');
      isAllowSubmit.setValue(!!props.exist.isAllowSubmit);
      isHide.setValue(!!props.exist.isHide);
      isShowAnswer.setValue(!!props.exist.isShowAnswer);
      isShowScore.setValue(!!props.exist.isShowScore);
      isAutoCorrect.setValue(!!props.exist.isAutoCorrect);
      maxSubmitCount.setValue(props.exist.maxSubmitCount || 1);
      limitMinute.setValue(props.exist.limitMinute || 45);
      setPaperId(props.exist.paperId || undefined);
    } else {
      name.clear();
      description.clear();
      isAllowSubmit.setFalse();
      isHide.setFalse();
      isShowAnswer.setFalse();
      isAutoCorrect.setFalse();
      maxSubmitCount.setValue(1);
      setPaperId(undefined);
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSavePost);

  function save() {
    if (paperId) {
      saveState
        .request({
          saveExamParams: {
            id: props.exist?.id,
            name: name.value,
            description: description.value,
            isAllowSubmit: isAllowSubmit.value,
            isHide: isHide.value,
            isShowAnswer: isShowAnswer.value,
            isAutoCorrect: isAutoCorrect.value,
            isShowScore: isShowScore.value,
            limitMinute: limitMinute.value,
            maxSubmitCount: maxSubmitCount.value,
            paperId: paperId,
          },
        })
        .then(() => {
          message.success('保存成功');
          props.onSuccess();
        })
        .catch(CommonApiErrorHandler);
    }
  }
  const listPaper = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperListGet);
  useEffect(() => {
    listPaper.requestSync({});
  }, []);
  return (
    <Form layout={'vertical'}>
      <Form.Item label={'考试名称'} required>
        <Input {...name.eventBind} placeholder={'请输入考试名称'} />
      </Form.Item>
      <Form.Item label={'备注'}>
        <Input {...description.eventBind} placeholder={'请输入备注'} />
      </Form.Item>
      <Form.Item label={'是否允许答题'} required>
        <Radio.Group value={isAllowSubmit.value} onChange={e => isAllowSubmit.setValue(e.target.value)}>
          <Radio value={true}>允许学生答题</Radio>
          <Radio value={false}>学生无法答题</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'是否隐藏'} required>
        <Radio.Group value={isHide.value} onChange={e => isHide.setValue(e.target.value)}>
          <Radio value={true}>学生不可见此考试</Radio>
          <Radio value={false}>学生可见此考试</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'是否可查分数'} required>
        <Radio.Group value={isShowScore.value} onChange={e => isShowScore.setValue(e.target.value)}>
          <Radio value={true}>可查</Radio>
          <Radio value={false}>不可查</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'是否显示答案'} required>
        <Radio.Group value={isShowAnswer.value} onChange={e => isShowAnswer.setValue(e.target.value)}>
          <Radio value={true}>学生可见批改结果</Radio>
          <Radio value={false}>不可见批改结果</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'机器自动批改'} required>
        <Radio.Group value={isAutoCorrect.value} onChange={e => isAutoCorrect.setValue(e.target.value)}>
          <Radio value={true}>交卷后机器自动批改</Radio>
          <Radio value={false}>手动触发机器阅卷</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'最多参加次数'} required>
        <InputNumber value={maxSubmitCount.value} onChange={e => maxSubmitCount.setValue(e as number)} min={1} max={99} />
      </Form.Item>
      <Form.Item label={'时间限制(分钟)'} required>
        <InputNumber value={limitMinute.value} onChange={e => limitMinute.setValue(e as number)} min={1} max={6000} />
      </Form.Item>
      <Form.Item label={'使用试卷'} required>
        <Select value={paperId} onChange={e => setPaperId(e)} placeholder={'请选择一套试卷'}>
          {listPaper.data?.map(p => (
            <Select.Option key={p.id} value={p.id || 0}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
