import React from 'react';
import {useAxios} from '../../Http/AxiosProvider';
import {useBoolean} from 'react-use';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {Button, message, Modal, Space, Upload} from 'antd';
import {ExcelImportResponse, UserApi} from '../../scaffold';

const {confirm} = Modal;

export default function ImportUserExcel(props: {refresh: () => any}) {
  const axios = useAxios();
  const [isUploading, _isUploading] = useBoolean(false);
  const getStudentExcelTemplateState = useOpenApiFpRequest(UserApi, UserApi.prototype.userGetStudentExcelTemplateGet);

  function getExcelTemplate() {
    getStudentExcelTemplateState
      .request({})
      .then(res => {
        if (res.url) window.open(res.url, '_blank');
      })
      .catch(CommonApiErrorHandler);
  }

  function onSelectFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    _isUploading(true);
    axios.instance
      .post<ExcelImportResponse>('User/ImportStudent', formData, {
        timeout: 1000 * 60 * 60,
      })
      .then(res => {
        if (res.data.errorMessage) {
          confirm({
            title: res.data.errorMessage,
            content: res.data.errorExcelUrl && (
              <a href={res.data.errorExcelUrl} target={'_blank'}>
                下载错误标注 Excel
              </a>
            ),
          });
        } else {
          confirm({
            title: '导入完成',
            content: `新增 ${res.data.addCount} 条，跳过 ${res.data.passCount} 条`,
          });
        }
        _isUploading(false);
        props.refresh();
      })
      .catch(e => {
        _isUploading(false);
        message.error(e.message);
      });
    return false;
  }

  return (
    <Space>
      <a onClick={getExcelTemplate}>下载 Excel 模板</a>
      <Upload accept={'application/xlsx'} beforeUpload={onSelectFile} showUploadList={false}>
        <Button loading={isUploading}>从 Excel 导入</Button>
      </Upload>
    </Space>
  );
}
