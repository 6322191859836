import React, {useState} from 'react';
import {Alert, Button, message, Spin} from 'antd';
import Text from 'antd/lib/typography/Text';
import Flex from './Flex';
export interface IBatchProgressState<T> {
  progress: number;
  isDoing: boolean;
  errorMessage: string;
  start: () => any;
  init: () => any;
  rows: T[];
}
export function useBatchProgress<T>(props: {action: (file: T, index: number) => Promise<any>; rows: T[]}): IBatchProgressState<T> {
  const [progress, setProgress] = useState(-1);
  const [isDoing, setIsDoing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  function init() {
    setIsDoing(false);
    setErrorMessage('');
    setProgress(-1);
  }
  async function start() {
    const start = progress;
    setErrorMessage('');
    setIsDoing(true);
    setProgress(0);
    try {
      for (let i = start; i < props.rows.length; i++) {
        const file = props.rows[i];
        if (!file) continue;
        await props.action(file, i);
        setProgress(i + 1);
      }
      setIsDoing(false);
    } catch (e) {
      message.error(e.message);
      setErrorMessage(e.message);
      setIsDoing(false);
    }
  }
  return {
    progress,
    isDoing,
    errorMessage,
    start,
    rows: props.rows,
    init,
  };
}
export default function BatchActionProgress<T>(props: {
  state: IBatchProgressState<T>;
  onProgressText: string;
  successText: string;
  onSuccess: () => any;
  children?: any;
}) {
  const {progress, isDoing, errorMessage, rows, start} = props.state;
  return (
    <div>
      {progress === -1 && props.children}
      {progress >= 0 && (
        <div>
          <Flex align={'center'}>
            <Spin spinning={isDoing} className={'m-r-8'} />
            {progress < rows.length && (
              <Text type={'secondary'}>
                {props.onProgressText} ({progress}/{rows.length})
              </Text>
            )}
          </Flex>
          {errorMessage && (
            <div className={'m-t-12'}>
              <Alert message={errorMessage} showIcon type={'error'} />
              <div className={'massa-dialog-footer'}>
                <Button onClick={start} className={'m-r-4'}>
                  再次尝试
                </Button>
                <Button onClick={props.onSuccess}>取消</Button>
              </div>
            </div>
          )}
          {progress >= rows.length && (
            <div>
              <i className={'massa massa-check massa-dialog-check'} />
              <Text type={'secondary'}>{props.successText}</Text>
              <div className={'massa-dialog-footer'}>
                <Button type={'primary'} onClick={props.onSuccess}>
                  完成
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
