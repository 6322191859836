import React, {useEffect} from 'react';
import {Button, Form, Input, message} from 'antd';
import {useInput} from 'react-hanger';
import {UserApi, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';

export default function UserSaveForm(props: {exist?: UserDto; isTeacher: boolean; onSuccess: () => any}) {
  const name = useInput('');
  const username = useInput('');
  const password = useInput('');

  useEffect(() => {
    if (props.exist) {
      name.setValue(props.exist.name || '');
      username.setValue(props.exist.username || '');
    } else {
      name.clear();
      username.clear();
      password.clear();
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(UserApi, UserApi.prototype.userSavePost);

  function save() {
    saveState
      .request({
        userSaveParams: {
          id: props.exist?.id,
          name: name.value,
          username: username.value,
          password: password.value,
          isTeacher: props.isTeacher,
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'姓名'} required>
        <Input {...name.eventBind} placeholder={'请输入姓名'} />
      </Form.Item>
      <Form.Item label={props.isTeacher ? '教师工号' : '学号'} required>
        <Input {...username.eventBind} placeholder={'请输入号码，此号码也用于登录'} />
      </Form.Item>
      {!props.exist && (
        <Form.Item label={'密码'} required>
          <Input.Password {...password.eventBind} placeholder={'请输入密码'} />
        </Form.Item>
      )}
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
