import React, {useState} from 'react';
import {Document, Page} from 'react-pdf/dist/umd/entry.webpack';
import {Pagination} from 'antd';
import Flex from './Flex';

export default function PdfViewer(props: {url: string}) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(pdf: any) {
    console.log(pdf);
    setNumPages(pdf.numPages);
    setPageNumber(1);
  }
  return (
    <div>
      <div className={'b-1'} style={{userSelect: 'none'}}>
        <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <Flex justify={'center'} className={'m-t-10'}>
        <Pagination total={numPages} onChange={setPageNumber} pageSize={1} />
      </Flex>
    </div>
  );
}
