import {CombineService, PaginatedParams} from '@ahooksjs/use-request/lib/types';
import {SorterOrder} from '../scaffold';
import {Filter, Sorter} from '@ahooksjs/use-request/lib/antdTypes';

export default class AntDTableUtils {
  static makeParams(params: {current: number; pageSize: number; sorter?: Sorter; filters?: Filter}) {
    let sorterOrder: SorterOrder | undefined = undefined;
    let sorterKey: string | undefined = undefined;
    if (params.sorter) {
      sorterOrder = params.sorter.order === 'ascend' ? SorterOrder.Asc : SorterOrder.Desc;
      sorterKey = params.sorter.field;
    }
    return {
      pi: params.current,
      ps: params.pageSize,
      sorterKey,
      sorterOrder,
    };
  }
}
