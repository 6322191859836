import React from 'react';
import {ChoiceQuestionDto} from '../../scaffold';
import MarkdownRender from '../Shared/MarkdownRender';
import Flex from '../Shared/Flex';
import Text from 'antd/lib/typography/Text';
import {IQuestionDisplayProps} from './QuestionDisplay';

export default function ChoiceQuestionDisplay(props: IQuestionDisplayProps<ChoiceQuestionDto>) {
  const {question} = props;
  const labels = 'ABCDEFGHIJKLMN';
  return (
    <div>
      <MarkdownRender className={'smallMarkdown'} md={(question.isMultiple ? '**[多选]** ' : '**[单选]** ') + question.topic || ''} />
      {question.options?.map((opt, i) => (
        <Flex key={i} style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>
          <p style={{margin: '5px 0', width: 20, marginRight: 10, fontWeight: 'bold', fontSize: 16, lineHeight: '1.5em'}}>{labels[i]}.</p>
          <MarkdownRender className={'smallMarkdown'} md={opt} />
        </Flex>
      ))}
      {!props.hideRightAnswer && (
        <div className={'m-t-5'}>
          <Text strong>正确答案: {question.rightLabel}</Text>
        </div>
      )}
    </div>
  );
}
