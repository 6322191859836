import React from 'react';
import {Button, Input, Space} from 'antd';

export default function SearchAndRefresh(props: {value: string; onChange: (v: string) => any; refresh: () => any; loading: boolean}) {
  return (
    <Space>
      <Input.Search value={props.value} onChange={e => props.onChange(e.target.value)} placeholder={'输入关键词检索'} />
      <Button onClick={props.refresh} loading={props.loading}>
        刷新
      </Button>
    </Space>
  );
}
