import React, {useEffect, useMemo, useState} from 'react';
import {Button, Collapse, Drawer, Input, message, Modal, Popover, Skeleton, Space, Upload} from 'antd';
import {CodingQuestionDto, DataPointApi, ExcelImportResponse, QuestionType} from '../../scaffold';
import {useBoolean, useInput} from 'react-hanger';
import {useAxios} from '../../Http/AxiosProvider';
import DataPointAdd from './DataPointAdd';
import Flex from '../Shared/Flex';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import DataPointTaskManage, {IDataPointTask} from './TaskManage';

export default function ManageDataPointBtn(props: {refresh: () => any; question: CodingQuestionDto; children?: any}) {
  const isDialog = useBoolean(false);
  const [addTaskNameList, setAddTaskNameList] = useState<string[]>([]);
  const taskName = useInput('');
  const listState = useOpenApiFpRequest(DataPointApi, DataPointApi.prototype.dataPointListGet);
  function refresh() {
    listState.requestSync({questionId: props.question.id || 0});
  }
  useEffect(() => {
    if (isDialog.value) {
      refresh();
    }
  }, [props.question, isDialog.value]);

  const taskList = useMemo(() => {
    const dataPointList = listState.data || [];
    const taskList: IDataPointTask[] = [];
    dataPointList.forEach(dataPoint => {
      const taskIndex = taskList.findIndex(task => task.name === dataPoint.taskName);
      if (taskIndex >= 0) taskList[taskIndex].dataPointList.push(dataPoint);
      else taskList.push({name: dataPoint.taskName || '', dataPointList: [dataPoint]});
    });

    return taskList;
  }, [listState.data]);

  return (
    <React.Fragment>
      <a onClick={isDialog.setTrue}>{props.children || '数据管理'}</a>
      <Drawer width={880} title={'编辑数据'} visible={isDialog.value} onClose={isDialog.setFalse}>
        <Flex justify={'space-between'} align={'center'} className={'m-b-10'}>
          <Space>
            {/*<Button loading={downloadHook.loading} onClick={download}>*/}
            {/*  下载数据包*/}
            {/*</Button>*/}
            <Popover
              title={'添加新分组'}
              trigger={'click'}
              content={
                <Space>
                  <Input {...taskName.eventBind} placeholder={'输入新分组名称'} />
                  <Button
                    onClick={() => {
                      setAddTaskNameList([...addTaskNameList, taskName.value]);
                    }}>
                    确定
                  </Button>
                </Space>
              }>
              <Button>添加新分组</Button>
            </Popover>
          </Space>
          <DataPointAdd questionId={props.question.id || 0} onFinish={refresh} />
        </Flex>

        <Skeleton loading={listState.loading}>
          <Collapse accordion>
            {taskList.map(task => (
              <Collapse.Panel header={task.name} key={task.name}>
                <DataPointTaskManage
                  task={task}
                  question={props.question}
                  refresh={props.refresh}
                  taskNameList={[...taskList.map(i => i.name), ...addTaskNameList]}
                />
              </Collapse.Panel>
            ))}
            {addTaskNameList
              .filter(name => !taskList.find(i => i.name === name))
              .map(task => (
                <Collapse.Panel header={task} key={task} />
              ))}
          </Collapse>
        </Skeleton>
      </Drawer>
    </React.Fragment>
  );
}
