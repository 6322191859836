import React, {useContext, useEffect, useState} from 'react';
import {useList} from 'react-use';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {clearCache, dropByCacheKey, getCachingKeys} from 'react-router-cache-route';
export interface ITabLayoutContextTabItem {
  title: string;
  path: string;
}
export interface ITabLayoutContext {
  currentTabList: ITabLayoutContextTabItem[];
  goTo: (path: string, config?: {refresh?: boolean}) => void;
  clear: () => any;
  closeTab: (path: string) => any;
  setTitle: (title: string, path?: string) => any;
  titleOfPath: Record<string, string>;
}
const TabLayoutContext = React.createContext<ITabLayoutContext>({} as any);

export function useTabLayout() {
  return useContext(TabLayoutContext);
}

export interface ITabLayoutProviderProps {
  children: any;
  basePath: string;
}

export default function TabLayoutProvider(props: ITabLayoutProviderProps) {
  const [currentTabList, currentTabListHook] = useList<ITabLayoutContextTabItem>([]);
  const [titleOfPath, setTitleOfPath] = useState<Record<string, string>>({});
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // 先判断这个新路由是否是该 TabLayout 需要处理的路由
    if (!location.pathname.startsWith(props.basePath)) return;

    const existIndex = currentTabList.findIndex(t => t.path === location.pathname);
    // 推入新的标签
    if (existIndex < 0) {
      currentTabListHook.push({title: location.pathname, path: location.pathname});
    }
  }, [location.pathname]);

  function setTitle(title: string, path?: string) {
    setTitleOfPath({...titleOfPath, [path || location.pathname]: title});
  }

  function goTo(path: string, config?: {refresh?: boolean; title?: string}) {
    if (config && config.title) {
      setTitle(config.title, path);
    }
    if (config && config.refresh) {
      dropByCacheKey(path);
      setTimeout(() => history.push(path), 500);
    } else {
      history.push(path);
    }
  }

  function clear() {
    clearCache();
    currentTabListHook.clear();
  }

  function closeTab(path: string) {
    if (currentTabList.length >= 2) {
      const existIndex = currentTabList.findIndex(t => t.path === path);
      if (existIndex == 0) {
        console.warn('第一个标签不能关闭');
        return;
      }
      dropByCacheKey(path);
      const nextToGo = currentTabList[existIndex - 1];
      currentTabListHook.removeAt(existIndex);
      nextToGo && goTo(nextToGo.path);
    }
  }

  return <TabLayoutContext.Provider value={{currentTabList, goTo, clear, closeTab, setTitle, titleOfPath}}>{props.children}</TabLayoutContext.Provider>;
}
