import React from 'react';
import {useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {QuestionApi, SystemApi, UserRole} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useMount} from 'react-use';
import {Button, Card, Divider, Drawer, PageHeader, Space} from 'antd';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import CodingQuestionContentSection from '../../../components/Question/CodingQuestionContentSection';
import CodingQuestionAnswerSection from '../../../components/Question/CodingQuestionAnswerSection';
import CodingQuestionSaveForm from '../../../components/Question/CodingQuestionSaveForm';
import {useBoolean} from 'react-hanger';
import useAuth from '../../../utils/AuthContext';
import ManageDataPointBtn from '../../../components/Question/ManageDataPointBtn';
import MarkdownRender from '../../../components/Shared/MarkdownRender';

export default function AppSystemNoticeDetailPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(SystemApi, SystemApi.prototype.systemFindNoticeGet);
  const tabLayout = useTabLayout();
  const isDialogSave = useBoolean(false);
  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle(res.topic || '通知详情');
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
  });

  const auth = useAuth();

  return (
    <div>
      <PageHeader title={findState.data?.topic || '通知详情'} style={{background: 'white'}} />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          {findState.data && (
            <div>
              <MarkdownRender md={findState.data.content || ''} />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
