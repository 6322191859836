import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, InputNumber, message, Radio, Row} from 'antd';
import {useBoolean, useInput, useNumber, useArray} from 'react-hanger';
import {QuestionApi, UserApi, UserDto, CodingQuestionDto, CodingQuestionExample} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useList} from 'react-use';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import Flex from '../Shared/Flex';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import ExMdEditor from '../Shared/ExMdEditor';
import ExampleInput from './ExampleInput';

export default function CodingQuestionSaveForm(props: {exist?: CodingQuestionDto; onSuccess: () => any}) {
  const keyword = useInput('');
  const description = useInput('');
  const dataRange = useInput('');
  const inputFormat = useInput('');
  const outputFormat = useInput('');
  const inputFileName = useInput('');
  const outputFileName = useInput('');
  const [examples, examplesState] = useList<CodingQuestionExample>([]);
  const [limitTime, setLimitTime] = useState(2000);
  const [limitMemory, setLimitMemory] = useState(256);
  const topic = useInput('');
  useEffect(() => {
    keyword.setValue(props.exist?.keywords || '');
    topic.setValue(props.exist?.topic || '');
    description.setValue(props.exist?.description || '');
    dataRange.setValue(props.exist?.dataRange || '');
    inputFileName.setValue(props.exist?.inputFileName || '');
    outputFileName.setValue(props.exist?.outputFileName || '');
    inputFormat.setValue(props.exist?.inputFormat || '');
    outputFormat.setValue(props.exist?.outputFormat || '');
    examplesState.set(props.exist?.examples || [{input: '', output: '', note: ''}]);
    setLimitTime(props.exist?.limitTime || 2000);
    setLimitMemory(props.exist?.limitMemory || 256);
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSaveCodingQuestionPost);

  function save() {
    saveState
      .request({
        saveCodingQuestionParams: {
          id: props.exist?.id,
          keywords: keyword.value,
          topic: topic.value,
          description: description.value,
          dataRange: dataRange.value,
          inputFormat: inputFormat.value,
          outputFormat: outputFormat.value,
          inputFileName: inputFileName.value,
          outputFileName: outputFileName.value,
          examples: examples,
          limitMemory: limitMemory,
          limitTime: limitTime,
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'关键词（用于搜索）'}>
        <Input {...keyword.eventBind} placeholder={'请输入关键词，可以是知识点'} />
      </Form.Item>
      <Form.Item label={'题目标题'} required>
        <Input {...topic.eventBind} placeholder={'请输入题目标题'} />
      </Form.Item>
      <Form.Item label={'描述'} required>
        <LiveExMdEditor {...description.valueBind} style={{background: '#EEEFF3', padding: '5px 15px'}} placeholder={'请输入描述'} />
      </Form.Item>
      <Form.Item label={'输入格式'} required>
        <LiveExMdEditor {...inputFormat.valueBind} style={{background: '#EEEFF3', padding: '5px 15px'}} placeholder={'请输入输入格式'} />
      </Form.Item>
      <Form.Item label={'输出格式'} required>
        <LiveExMdEditor {...outputFormat.valueBind} style={{background: '#EEEFF3', padding: '5px 15px'}} placeholder={'请输入输出格式'} />
      </Form.Item>
      <Form.Item label={'数据范围'} required>
        <LiveExMdEditor {...dataRange.valueBind} style={{background: '#EEEFF3', padding: '5px 15px'}} placeholder={'请输入输出格式'} />
      </Form.Item>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label={'输入文件名'}>
            <Input {...inputFileName.eventBind} placeholder="如需按文件输入，请填写文件名" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={'输出文件名'}>
            <Input {...outputFileName.eventBind} placeholder="如需按文件输出，请填写文件名" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label={'内存限制(mb)'} required>
            <InputNumber style={{width: 100}} value={limitMemory} onChange={setLimitMemory as any} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={'时间限制(ms)'} required>
            <InputNumber style={{width: 100}} value={limitTime} onChange={setLimitTime as any} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={'数据样例'} required>
        <div className={'p-5 m-b-5'}>
          {examples.map((field, index) => (
            <Flex key={index} justify={'space-between'} align={'center'}>
              <ExampleInput value={field} onChange={v => examplesState.updateAt(index, v)} />
              <Button style={{flexShrink: 0}} type={'link'} danger disabled={index === 0} onClick={() => examplesState.removeAt(index)}>
                <DeleteOutlined />
                删除
              </Button>
            </Flex>
          ))}
          <Button onClick={() => examplesState.push({input: '', output: '', note: ''})} style={{marginTop: 5}}>
            <PlusOutlined /> 添加一个选项
          </Button>
        </div>
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
