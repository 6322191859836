import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {CodingQuestionDto, QuestionApi, QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest, QuestionDto, QuestionType, UserDto} from '../../../scaffold';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {useMount} from 'react-use';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {Button, Drawer, Input, PageHeader, Select, Space, Table, Tag} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import ChoiceQuestionSaveForm from '../../../components/Question/ChoiceQuestionSaveForm';
import FillQuestionSaveForm from '../../../components/Question/FillQuestionSaveForm';
import EssayQuestionSaveForm from '../../../components/Question/EssayQuestionSaveForm';
import OperateQuestionSaveForm from '../../../components/Question/OperateQuestionSaveForm';
import QuestionDisplay from '../../../components/Question/QuestionDisplay';
import CodingQuestionSaveForm from '../../../components/Question/CodingQuestionSaveForm';
import ManageDataPointBtn from '../../../components/Question/ManageDataPointBtn';
import useExAntdTable from '../../../utils/useExAntdTable';
import AntDTableUtils from '../../../utils/AntDTableUtils';
import Text from 'antd/lib/typography/Text';

export default function ManageQuestionPage() {
  const params = useParams() as {type: QuestionType} | undefined;
  const tabLayout = useTabLayout();
  const type = params?.type || QuestionType.选择题;
  const searchHook = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSearchQuestionGet);
  const searchCodingQuestionHook = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionAdvanceSearchCodingQuestionGet);
  const keyword = useInput('');
  const [hasDataPoints, setHasDataPoints] = useState<'不限' | '仅有数据' | '仅无数据'>('不限');
  const [hasCourses, setHasCourses] = useState<'不限' | '仅有课程' | '仅无课程'>('不限');
  const [hasPapers, setHasPapers] = useState<'不限' | '仅有试卷' | '仅无试卷'>('不限');

  const exAntDTable = useExAntdTable({
    request: params => {
      const tableParams = AntDTableUtils.makeParams(params);
      if (type === QuestionType.编程题) {
        let p: QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest = {...tableParams, keywords: keyword.value};
        if (hasDataPoints === '仅有数据') p = {...p, hasDataPoints: true};
        if (hasDataPoints === '仅无数据') p = {...p, hasDataPoints: false};
        if (hasCourses === '仅有课程') p = {...p, hasCourses: true};
        if (hasCourses === '仅无课程') p = {...p, hasCourses: false};
        if (hasPapers === '仅有试卷') p = {...p, hasPapers: true};
        if (hasPapers === '仅无试卷') p = {...p, hasPapers: false};
        return searchCodingQuestionHook.request(p);
      }
      return searchHook.request({
        ...tableParams,
        keywords: keyword.value,
        type,
      });
    },
    deps: [type, hasDataPoints, hasCourses, hasPapers],
  });
  function refresh() {
    exAntDTable.refresh();
  }

  useMount(() => {
    refresh();
    tabLayout.setTitle(QuestionType[type] + '管理');
  });
  const isDialogSave = useBoolean(false);
  const [selected, setSelected] = useState<QuestionDto>();
  return (
    <div>
      <PageHeader
        title={QuestionType[type] + '管理'}
        style={{background: 'white'}}
        extra={
          <Space>
            {type === QuestionType.编程题 && <Button onClick={() => tabLayout.goTo('/manage/importCodingQuestion')}>从致远导入编程题</Button>}
            <Button
              type={'primary'}
              onClick={() => {
                isDialogSave.setTrue();
                setSelected(undefined);
              }}>
              添加 {QuestionType[type]}
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <Space>
          <Input.Search {...keyword.eventBind} onSearch={refresh} placeholder={'输入关键词检索'} />
          {type === QuestionType.编程题 && (
            <React.Fragment>
              <Text>数据:</Text>
              <Select value={hasDataPoints} onSelect={setHasDataPoints} style={{width: 100}}>
                <Select.Option value={'不限'}>不限</Select.Option>
                <Select.Option value={'仅有数据'}>仅有数据</Select.Option>
                <Select.Option value={'仅无数据'}>仅无数据</Select.Option>
              </Select>
              <Text>课程:</Text>
              <Select value={hasCourses} onSelect={setHasCourses} style={{width: 100}}>
                <Select.Option value={'不限'}>不限</Select.Option>
                <Select.Option value={'仅有课程'}>仅有课程</Select.Option>
                <Select.Option value={'仅无课程'}>仅无课程</Select.Option>
              </Select>
              <Text>试卷:</Text>
              <Select value={hasPapers} onSelect={setHasPapers} style={{width: 100}}>
                <Select.Option value={'不限'}>不限</Select.Option>
                <Select.Option value={'仅有试卷'}>仅有试卷</Select.Option>
                <Select.Option value={'仅无试卷'}>仅无试卷</Select.Option>
              </Select>
            </React.Fragment>
          )}
          <Button onClick={refresh} loading={searchHook.loading}>
            刷新
          </Button>
        </Space>
        <Table
          size={'small'}
          className={'m-t-10'}
          bordered
          {...exAntDTable.tableProps}
          pagination={exAntDTable.pagination}
          rowKey={'id'}
          loading={searchHook.loading || searchCodingQuestionHook.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} width={100} />
          {type !== QuestionType.编程题 && <Table.Column<QuestionDto> title={'题干'} render={(_, item) => <QuestionDisplay question={item} key={item.id} />} />}
          {type === QuestionType.编程题 && <Table.Column<QuestionDto> title={'题目标题'} dataIndex={'topic'} />}
          {type === QuestionType.编程题 && (
            <Table.Column<CodingQuestionDto> title={'所在课程'} render={(_, item) => item.courseNames?.map(name => <Tag key={name}>{name}</Tag>)} />
          )}
          {type === QuestionType.编程题 && (
            <Table.Column<CodingQuestionDto> title={'所在试卷'} render={(_, item) => item.paperNames?.map(name => <Tag key={name}>{name}</Tag>)} />
          )}
          <Table.Column title={'关键词'} dataIndex={'keywords'} width={200} />
          {type === QuestionType.编程题 && (
            <Table.Column<CodingQuestionDto>
              title={'数据'}
              width={70}
              align={'center'}
              render={(_, item) => (item.versionMd5 ? <Tag color={'green'}>有数据</Tag> : <Tag color={'red'}>无数据</Tag>)}
            />
          )}
          <Table.Column<UserDto>
            title={'操作'}
            width={220}
            render={(_, item) => (
              <Space>
                {type === QuestionType.编程题 && <a onClick={() => tabLayout.goTo('/manage/codingQuestion/' + item.id)}>详情</a>}
                <a
                  onClick={() => {
                    isDialogSave.setTrue();
                    setSelected(item);
                  }}>
                  编辑
                </a>
                {type === QuestionType.编程题 && <ManageDataPointBtn refresh={refresh} question={item} key={item.id} />}
                {type === QuestionType.编程题 && <a onClick={() => tabLayout.goTo('/manage/question/' + item.id + '/codingSubmission')}>提交记录</a>}
              </Space>
            )}
          />
        </Table>
      </div>
      <Drawer width={700} title={(selected ? '编辑' : '添加') + QuestionType[type]} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
        {type === QuestionType.选择题 && (
          <ChoiceQuestionSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        )}
        {type === QuestionType.填空题 && (
          <FillQuestionSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        )}
        {type === QuestionType.论述题 && (
          <EssayQuestionSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        )}
        {type === QuestionType.操作题 && (
          <OperateQuestionSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        )}
        {type === QuestionType.编程题 && (
          <CodingQuestionSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        )}
      </Drawer>
    </div>
  );
}
