import React, {useState} from 'react';
import {Button, Drawer, PageHeader, Space, Table, Tag} from 'antd';
import ImportUserExcel from '../../../components/User/ImportExcel';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {CourseArticleApi, CourseArticleDto, PaperApi, PaperDto, QuestionDto, QuestionType, UserApi, UserDto, UserRole} from '../../../scaffold';
import {useHistory} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useBoolean, useInput} from 'react-hanger';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import {useMount} from 'react-use';
import useQuickRouter from '../../../utils/useQuickRouter';
import CourseArticleSaveForm from '../../../components/Course/ArticleSaveForm';

export default function ManageCourseArticleListPage() {
  const history = useHistory();
  const tabLayout = useTabLayout();
  const quickRouter = useQuickRouter();
  const listState = useOpenApiFpRequest(CourseArticleApi, CourseArticleApi.prototype.courseArticleSearchGet);

  const keyword = useInput('');
  const displayRows = listState.data?.list?.filter(i => PaperKeywordFilter(i, keyword.value));

  const isDialogSave = useBoolean(false);
  const [selected, setSelected] = useState<CourseArticleDto>();

  function refresh() {
    listState.requestSync({pi: 1, ps: 9999});
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader
        title={'教案列表'}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button
              type={'primary'}
              onClick={() => {
                isDialogSave.setTrue();
                setSelected(undefined);
              }}>
              添加教案
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
        <Table size={'small'} className={'m-t-10'} bordered dataSource={displayRows || []} rowKey={'id'} loading={listState.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} width={100} />
          <Table.Column title={'教案名称'} dataIndex={'topic'} />
          <Table.Column<CourseArticleDto>
            title={'操作'}
            width={330}
            render={(_, item) => (
              <Space>
                <a
                  onClick={() => {
                    isDialogSave.setTrue();
                    setSelected(item);
                  }}>
                  编辑
                </a>
              </Space>
            )}
          />
        </Table>
        <Drawer width={700} title={(selected ? '编辑' : '添加') + '教案'} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
          <CourseArticleSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        </Drawer>
      </div>
    </div>
  );
}
