import React, {useState} from 'react';
import {Button, Drawer, PageHeader, Space, Table, Tag} from 'antd';
import ImportUserExcel from '../../../components/User/ImportExcel';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {ExamApi, ExamDto, PaperApi, PaperDto, QuestionDto, QuestionType, UserApi, UserDto, UserRole} from '../../../scaffold';
import UserSaveForm from '../../../components/User/SaveForm';
import {useHistory} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useBoolean, useInput} from 'react-hanger';
import UserKeywordFilter from '../../../utils/keywordFiliter/User';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import {useMount} from 'react-use';
import ChoiceQuestionSaveForm from '../../../components/Question/ChoiceQuestionSaveForm';
import FillQuestionSaveForm from '../../../components/Question/FillQuestionSaveForm';
import EssayQuestionSaveForm from '../../../components/Question/EssayQuestionSaveForm';
import OperateQuestionSaveForm from '../../../components/Question/OperateQuestionSaveForm';
import ExamSaveForm from '../../../components/Exam/SaveForm';
import useQuickRouter from '../../../utils/useQuickRouter';
import ExamSetGroups from '../../../components/Exam/SetGroups';

export default function ManageExamListPage() {
  const history = useHistory();
  const tabLayout = useTabLayout();
  const quickRouter = useQuickRouter();
  const listState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSearchGet);

  const keyword = useInput('');
  const displayRows = listState.data?.list?.filter(i => PaperKeywordFilter(i, keyword.value));

  const isDialogSave = useBoolean(false);
  const isDialogGroup = useBoolean(false);
  const [selected, setSelected] = useState<ExamDto>();

  function refresh() {
    listState.requestSync({pi: 1, ps: 9999});
  }

  useMount(() => {
    refresh();
  });

  return (
    <div>
      <PageHeader
        title={'考试列表'}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button
              type={'primary'}
              onClick={() => {
                isDialogSave.setTrue();
                setSelected(undefined);
              }}>
              添加考试
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
        <Table size={'small'} className={'m-t-10'} bordered dataSource={displayRows || []} rowKey={'id'} loading={listState.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} width={100} />
          <Table.Column title={'考试名称'} dataIndex={'name'} />
          <Table.Column<ExamDto>
            title={'允许答题'}
            width={80}
            align={'center'}
            render={(_, item) => (item.isAllowSubmit ? <Tag color={'green'}>允许</Tag> : <Tag color={'red'}>不允许</Tag>)}
          />
          <Table.Column<ExamDto>
            width={80}
            align={'center'}
            title={'是否隐藏'}
            render={(_, item) => (item.isHide ? <Tag color={'red'}>隐藏</Tag> : <Tag color={'green'}>可见</Tag>)}
          />
          <Table.Column<ExamDto>
            width={80}
            align={'center'}
            title={'可查答案'}
            render={(_, item) => (item.isShowAnswer ? <Tag color={'green'}>可查</Tag> : <Tag color={'red'}>不可查</Tag>)}
          />
          <Table.Column<ExamDto>
            width={80}
            align={'center'}
            title={'可查成绩'}
            render={(_, item) => (item.isShowScore ? <Tag color={'green'}>可查</Tag> : <Tag color={'red'}>不可查</Tag>)}
          />
          <Table.Column<ExamDto>
            width={80}
            align={'center'}
            title={'机器阅卷'}
            render={(_, item) => (item.isAutoCorrect ? <Tag color={'green'}>自动</Tag> : <Tag color={'red'}>手动</Tag>)}
          />
          <Table.Column<ExamDto>
            title={'操作'}
            width={380}
            render={(_, item) => (
              <Space>
                <a
                  onClick={() => {
                    isDialogSave.setTrue();
                    setSelected(item);
                  }}>
                  编辑
                </a>
                <a
                  onClick={() => {
                    isDialogGroup.setTrue();
                    setSelected(item);
                  }}>
                  配置班级
                </a>
                <a onClick={() => quickRouter.paperPreview(item.paperId || 0)}>预览试卷</a>
                <a onClick={() => tabLayout.goTo('/manage/exam/submission/' + item.id)}>考试成绩</a>
                <a onClick={() => tabLayout.goTo('/manage/exam/analyse/' + item.id)}>考试分析</a>
                <a onClick={() => tabLayout.goTo('/manage/exam/analyseCodingQuestion/' + item.id)}>编程题排名</a>
              </Space>
            )}
          />
        </Table>
        <Drawer width={700} title={(selected ? '编辑' : '添加') + '试卷'} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
          <ExamSaveForm
            exist={selected}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        </Drawer>
        <Drawer width={700} title={'参与班级'} destroyOnClose visible={isDialogGroup.value} onClose={isDialogGroup.setFalse}>
          {selected && <ExamSetGroups exam={selected} />}
        </Drawer>
      </div>
    </div>
  );
}
