import React, {useMemo, useState} from 'react';
import {CodingSubmissionApi, CodingSubmissionApiCodingSubmissionSearchGetRequest, CodingSubmissionDto, CodingSubmissionStatus} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Drawer, message, Table} from 'antd';
import {TablePaginationConfig} from 'antd/lib/table';
import Text from 'antd/lib/typography/Text';
import SubmissionStatusCell from './SubmissionStatusCell';
import SubmissionDetail from './SubmissionDetail';

export default function CodingSubmissionTable(props: {
  query: {
    codingQuestionId?: number;
    submitterId?: number;
  };
  selectedList?: CodingSubmissionDto[];
  onChange?: (v: CodingSubmissionDto[]) => any;
}) {
  const {query} = props;
  const searchHook = useOpenApiFpRequest(CodingSubmissionApi, CodingSubmissionApi.prototype.codingSubmissionSearchGet);
  const antdTable = useAntdTable(
    params => {
      const tableParams = AntDTableUtils.makeParams(params);
      return searchHook.request({
        ...tableParams,
        ...props.query,
      });
    },
    {
      refreshDeps: [query.codingQuestionId, query.submitterId],
      defaultPageSize: 15,
      onError: e => message.error(e.message),
    },
  );
  const {tableProps} = antdTable;
  const pagination: TablePaginationConfig = {
    ...tableProps.pagination,
    size: 'default',
    defaultPageSize: 15,
    showTotal: total => `共 ${total} 条`,
    pageSizeOptions: ['15', '50', '200', '1000', '5000', '20000'],
  };
  const [submissionId, setSubmissionId] = useState<number>();

  return (
    <div>
      <Table<CodingSubmissionDto>
        {...tableProps}
        size={'small'}
        loading={searchHook.loading}
        className={'element-table'}
        rowKey={'id'}
        pagination={pagination}
        rowClassName={'hover'}
        onRow={record => {
          return {
            onClick() {
              setSubmissionId(record.id);
            },
          };
        }}
        rowSelection={{
          onChange: (_, rows) => props.onChange && props.onChange(rows),
          selectedRowKeys: props.selectedList?.map(i => i.id as number),
        }}>
        <Table.Column<CodingSubmissionDto> width={80} key="id" title="编号" dataIndex="id" />
        <Table.Column<CodingSubmissionDto> key="submitter" title="提交人" render={(_, item) => <Text strong>{item.submitter?.name}</Text>} />
        <Table.Column<CodingSubmissionDto> width={100} key="language" title="语言" dataIndex="language" />
        <Table.Column<CodingSubmissionDto>
          key="status"
          width={125}
          title="评测结果"
          render={(_, item) => <SubmissionStatusCell status={item.status as CodingSubmissionStatus} isOutDate={!!item.isOutDate} />}
        />
        <Table.Column<CodingSubmissionDto>
          key="score"
          title="得分"
          width={100}
          render={(_, item) => (
            <span>
              {item.scoreGet} / {item.totalScore}
            </span>
          )}
        />
        <Table.Column<CodingSubmissionDto> width={100} key="memoryUsage" title="内存占用" render={(_, item) => <span>{item.memoryUsage} Kb</span>} />
        <Table.Column<CodingSubmissionDto> width={100} key="timeUsage" title="时间占用" render={(_, item) => <span>{item.timeUsage} ms</span>} />
        <Table.Column<CodingSubmissionDto> width={200} key="createdTime" title="提交时间" dataIndex="createdTime" />
      </Table>
      <Drawer title={'提交记录:' + submissionId} footer={false} width={900} visible={!!submissionId} onClose={() => setSubmissionId(undefined)}>
        {submissionId && <SubmissionDetail submissionId={submissionId} />}
      </Drawer>
    </div>
  );
}
