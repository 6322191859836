import React, {useMemo, useState} from 'react';
import logo from './logo.svg';
import './styles/app.less';
import 'normalize.css/normalize.css';
import 'snack-helper/dist/snack-helper.css';
import AppPage from './pages/app';
import LoginPage from './pages/login/login';
import {AuthProvider, Alert, useAuthContext} from 'geestack-toolbox';
import {BrowserRouter, Switch, Route, Link, Router, HashRouter} from 'react-router-dom';
import CacheRoute, {CacheSwitch} from 'react-router-cache-route';
import TabLayoutProvider from './TabLayout/TabLayoutProvider';
import AdminPage from './pages/manage';
import AxiosProvider from './Http/AxiosProvider';
import {onRequestFulfilled, onResponseReject} from './utils/HttpInstance';
import {Config} from './config';
import {message} from 'antd';
import {ClusterProvider} from './utils/ClusterProvider';

const App: React.FunctionComponent<any> = () => {
  return (
    <ClusterProvider>
      <AuthProvider>
        <AxiosProvider
          onResponseReject={onResponseReject}
          onRequestFulfilled={onRequestFulfilled}
          axiosConfig={{
            timeout: 15 * 60 * 1000,
            baseURL: Config.basePath,
          }}
          defaultErrorHandle={error => message.error(error.message)}>
          <HashRouter>
            <Switch>
              <Route path={'/'} exact component={LoginPage} />
              <Route path={'/login'} component={LoginPage} />
              <Route path={'/app'} component={AppPage} />
              <Route path={'/manage'} component={AdminPage} />
            </Switch>
          </HashRouter>
        </AxiosProvider>
      </AuthProvider>
    </ClusterProvider>
  );
};

export default App;
