import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import useAuth from '../utils/AuthContext';
import {Config} from '../config';
import {useCluster} from '../utils/ClusterProvider';
export interface IAxiosContext {
  instance: AxiosInstance;
  defaultErrorHandle?: (error: Error) => any;
  axiosConfig: AxiosRequestConfig;
}
const AxiosContext = React.createContext<IAxiosContext>({} as any);

export interface IAxiosProviderProps {
  children?: any;
  onResponseReject?: (error: any) => Promise<any>;
  onRequestFulfilled?: (config: AxiosRequestConfig) => AxiosRequestConfig;
  axiosConfig: AxiosRequestConfig;
  defaultErrorHandle?: (error: Error) => any;
}

export function useAxios(): IAxiosContext {
  return useContext(AxiosContext);
}

export default function AxiosProvider(props: IAxiosProviderProps): any {
  const auth = useAuth();
  const {cluster} = useCluster();
  const instance = useMemo(() => {
    const res = axios.create(props.axiosConfig);
    res.interceptors.response.use(response => response, props.onResponseReject);
    res.interceptors.request.use(props.onRequestFulfilled);
    res.interceptors.request.use(config => {
      console.log(cluster?.name);
      try {
        config.headers = {...config.headers, 'Application-Version': Config.version};
        config.headers = {...config.headers, Cluster: cluster?.nameEn};
      } catch (e) {
        console.log(e);
      }
      if (auth.isLogin) {
        config.headers = {...config.headers, 'Application-Token': auth.token};
      }
      return config;
    });
    return res;
  }, [auth.token, cluster]);

  return (
    <AxiosContext.Provider value={{instance, defaultErrorHandle: props.defaultErrorHandle, axiosConfig: props.axiosConfig}}>
      {props.children}
    </AxiosContext.Provider>
  );
}
