import * as React from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {Radio, Select, Space} from 'antd';
import Flex from './Flex';
import 'codemirror/theme/dracula.css';
import 'codemirror/theme/neo.css';
import 'codemirror/theme/midnight.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/blackboard.css';
import 'codemirror/theme/tomorrow-night-bright.css';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/python/python.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/anyword-hint.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/comment-fold.js';

import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/display/placeholder.js';

import {useInput} from 'react-hanger';
import {useEffect, useState} from 'react';
import {CodingLanguage} from '../../scaffold';

export default function CodeInput(props: {
  code: string;
  language: CodingLanguage;
  onChange?: (value: {code: string; language: CodingLanguage}) => any;
  disabled?: boolean;
  hideLanguage?: boolean;
  errorInfo?: string;
}) {
  const theme = useInput('dracula');
  const [editor, setEditor] = useState<any>();

  useEffect(() => {
    if (editor && props.errorInfo) {
      const res = props.errorInfo.match(/code.cpp:(\d*):(\d*)/);
      if (res) {
        const startLine = parseInt(res[1]);
        const startCh = parseInt(res[2]);
        if (!isNaN(startLine) && !isNaN(startCh)) {
          editor.doc.markText({line: startLine - 1, ch: startCh - 1}, {line: startLine - 1}, {className: 'bg-line-red', clearOnEnter: true});
        }
      }
    }
  }, [editor, props.errorInfo]);

  function getMode() {
    if ([CodingLanguage.Cpp].includes(props.language)) return 'text/x-csrc';
    return 'text/x-python';
  }
  return (
    <div>
      {!props.hideLanguage && (
        <Flex align={'center'} justify={'space-between'} className={'m-b-10'}>
          <Space>
            <span className={'m-r-5'}>选择语言:</span>
            <Select
              style={{width: 150}}
              disabled={props.disabled}
              onChange={e => {
                if (props.onChange) props.onChange({code: props.code, language: e});
              }}
              defaultValue={props.language}>
              <Select.Option value={CodingLanguage.Cpp}>C++</Select.Option>
              <Select.Option value={CodingLanguage.Python3}>Python 3.6</Select.Option>
            </Select>
          </Space>
          <Space>
            <span className={'mr-md'}>编辑器主题:</span>
            <Select style={{width: 200}} disabled={props.disabled} {...theme.valueBind} defaultValue={props.language}>
              {['dracula', 'neo', 'midnight', 'eclipse', 'monokai', 'blackboard', 'tomorrow-night-bright'].map(th => (
                <Select.Option value={th} key={th}>
                  {th}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Flex>
      )}
      <CodeMirror
        value={props.code}
        editorDidMount={editor => {
          setEditor(editor);
        }}
        options={{
          mode: getMode(),
          theme: theme.value,
          lineNumbers: true,
          lineWrapping: true,
          foldGutter: true,
          gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-comment'],
          styleSelectedText: true,
          matchBrackets: true,
          highlightSelectionMatches: {showToken: /\w/, annotateScrollbar: true},
          readOnly: props.disabled === true,
          placeholder: '输入代码',
        }}
        onBeforeChange={(editor, data, value) => {
          if (props.onChange) {
            props.onChange({code: value, language: props.language});
          }
        }}
      />
    </div>
  );
}
