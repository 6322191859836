import React from 'react';
import {Button, Drawer, Input, PageHeader, Popover, Space, Table, Tag} from 'antd';
import ImportUserExcel from '../../../components/User/ImportExcel';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {PaperApi, PaperDto, UserApi, UserDto, UserRole} from '../../../scaffold';
import UserSaveForm from '../../../components/User/SaveForm';
import {useHistory} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useInput} from 'react-hanger';
import UserKeywordFilter from '../../../utils/keywordFiliter/User';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import {useMount} from 'react-use';
import useQuickRouter from '../../../utils/useQuickRouter';
import CommonApiErrorHandler from '../../../utils/HttpInstance';

export default function ManagePaperListPage() {
  const history = useHistory();
  const tabLayout = useTabLayout();
  const listState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperListGet);

  const keyword = useInput('');
  const displayRows = listState.data?.filter(i => PaperKeywordFilter(i, keyword.value));

  function refresh() {
    listState.requestSync({});
  }

  useMount(() => {
    refresh();
  });
  const quickRouter = useQuickRouter();
  const newName = useInput('');
  const copyState = useOpenApiFpRequest(PaperApi, PaperApi.prototype.paperCopyPost);

  return (
    <div>
      <PageHeader
        title={'试卷列表'}
        style={{background: 'white'}}
        extra={
          <Space>
            <Button
              type={'primary'}
              onClick={() => {
                tabLayout.goTo('/manage/paper/edit/', {refresh: true});
              }}>
              新建试卷
            </Button>
          </Space>
        }
      />
      <div className={'p-20'}>
        <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />
        <Table size={'small'} className={'m-t-10'} bordered dataSource={displayRows || []} rowKey={'id'} loading={listState.loading}>
          <Table.Column title={'编号'} dataIndex={'id'} width={100} />
          <Table.Column title={'试卷名称'} dataIndex={'name'} />
          <Table.Column<PaperDto>
            title={'操作'}
            width={200}
            render={(_, item) => (
              <Space>
                <a
                  onClick={() => {
                    tabLayout.goTo('/manage/paper/edit/' + item.id);
                  }}>
                  编辑
                </a>
                <Popover
                  title={'复制试卷'}
                  trigger={'click'}
                  content={
                    <Space>
                      输入新试卷名称:
                      <Input {...newName.eventBind} />
                      <Button
                        onClick={() =>
                          copyState
                            .request({copyPaperParams: {id: item.id || 0, name: newName.value}})
                            .then(refresh)
                            .catch(CommonApiErrorHandler)
                        }>
                        确认复制
                      </Button>
                    </Space>
                  }>
                  <a>复制试卷</a>
                </Popover>
                <a onClick={() => quickRouter.paperPreview(item.id || 0)}>预览试卷</a>
              </Space>
            )}
          />
        </Table>
      </div>
    </div>
  );
}
