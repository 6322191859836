import React, {useState} from 'react';
import MarkdownRender from './MarkdownRender';
import ExMdEditor from './ExMdEditor';
import {Modal} from 'antd';

export default function LiveExMdEditor(props: {value?: string; onChange?: (text: string) => any; placeholder?: string; style?: React.CSSProperties}) {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  return (
    <div>
      <div
        style={{cursor: 'pointer', ...props.style}}
        onClick={() => {
          setVisible(true);
          setText(props.value || '');
        }}>
        {props.value ? (
          <MarkdownRender className={'smallMarkdown'} md={props.value} />
        ) : (
          <MarkdownRender className={'smallMarkdown'} md={String(props.placeholder)} />
        )}
      </div>
      <Modal
        width={750}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          props.onChange && props.onChange(text);
        }}
        footer={<div />}
        closeIcon={false}>
        <div style={{width: 700}}>
          <ExMdEditor value={text} onChange={setText} />
        </div>
      </Modal>
    </div>
  );
}
