/* tslint:disable */
/* eslint-disable */
/**
 * Zoloft-BE
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddAspirinDataPointParams
 */
export interface AddAspirinDataPointParams {
    /**
     * 
     * @type {number}
     * @memberof AddAspirinDataPointParams
     */
    questionId: number;
    /**
     * 
     * @type {number}
     * @memberof AddAspirinDataPointParams
     */
    aspirinQuestionId: number;
    /**
     * 
     * @type {number}
     * @memberof AddAspirinDataPointParams
     */
    aspirinDataPointId: number;
    /**
     * 
     * @type {number}
     * @memberof AddAspirinDataPointParams
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof AddAspirinDataPointParams
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAspirinDataPointParams
     */
    taskName: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddAspirinDataPointParams
     */
    unionMode: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddAspirinDataPointParams
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddAspirinDataPointParams
     */
    size: number;
}
/**
 * 
 * @export
 * @interface AddLessonParams
 */
export interface AddLessonParams {
    /**
     * 
     * @type {string}
     * @memberof AddLessonParams
     */
    lessonName: string;
    /**
     * 
     * @type {number}
     * @memberof AddLessonParams
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface AdjustGroupStudentParams
 */
export interface AdjustGroupStudentParams {
    /**
     * 
     * @type {number}
     * @memberof AdjustGroupStudentParams
     */
    studentId: number;
    /**
     * 
     * @type {number}
     * @memberof AdjustGroupStudentParams
     */
    groupId: number;
}
/**
 * 
 * @export
 * @interface AuthorizeHeartbeatDto
 */
export interface AuthorizeHeartbeatDto {
    /**
     * 
     * @type {UserDto}
     * @memberof AuthorizeHeartbeatDto
     */
    user?: UserDto;
}
/**
 * 
 * @export
 * @interface AuthorizeLoginParams
 */
export interface AuthorizeLoginParams {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeLoginParams
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeLoginParams
     */
    password: string;
}
/**
 * 
 * @export
 * @interface BatchDeleteDataPointParams
 */
export interface BatchDeleteDataPointParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchDeleteDataPointParams
     */
    dataPointIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BatchDeleteDataPointParams
     */
    questionId: number;
}
/**
 * 
 * @export
 * @interface BatchUpdateDataPointParams
 */
export interface BatchUpdateDataPointParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchUpdateDataPointParams
     */
    dataPointIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BatchUpdateDataPointParams
     */
    score: number;
    /**
     * 
     * @type {boolean}
     * @memberof BatchUpdateDataPointParams
     */
    unionMode: boolean;
    /**
     * 
     * @type {number}
     * @memberof BatchUpdateDataPointParams
     */
    questionId: number;
}
/**
 * 
 * @export
 * @interface ChoiceQuestionDto
 */
export interface ChoiceQuestionDto {
    /**
     * 
     * @type {boolean}
     * @memberof ChoiceQuestionDto
     */
    isMultiple?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChoiceQuestionDto
     */
    options?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChoiceQuestionDto
     */
    rightLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChoiceQuestionDto
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChoiceQuestionDto
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof ChoiceQuestionDto
     */
    type?: QuestionType;
    /**
     * 
     * @type {number}
     * @memberof ChoiceQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChoiceQuestionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ChoiceQuestionScoreType {
    不给分 = '少选不给分',
    给一半分 = '少选给一半分',
    给一半分且向下取整 = '少选给一半分且向下取整'
}

/**
 * 
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    nameEn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Cluster
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    deletedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    restoredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Cluster
     */
    isDelete?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CodingLanguage {
    Python3 = 'Python3',
    Cpp = 'Cpp'
}

/**
 * 
 * @export
 * @interface CodingQuestionDto
 */
export interface CodingQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    versionMd5?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CodingQuestionDto
     */
    limitTime?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingQuestionDto
     */
    limitMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    dataRange?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    inputFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    outputFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    inputFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    outputFileName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodingQuestionDto
     */
    hasDataPoint?: boolean;
    /**
     * 
     * @type {Array<CodingQuestionExample>}
     * @memberof CodingQuestionDto
     */
    examples?: Array<CodingQuestionExample> | null;
    /**
     * 
     * @type {Array<DataPointDto>}
     * @memberof CodingQuestionDto
     */
    dataPoints?: Array<DataPointDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CodingQuestionDto
     */
    aspirinQuestionId?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CodingQuestionDto
     */
    courseNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CodingQuestionDto
     */
    paperNames?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof CodingQuestionDto
     */
    type?: QuestionType;
    /**
     * 
     * @type {number}
     * @memberof CodingQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface CodingQuestionDtoSearchResponseDto
 */
export interface CodingQuestionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<CodingQuestionDto>}
     * @memberof CodingQuestionDtoSearchResponseDto
     */
    list?: Array<CodingQuestionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CodingQuestionDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface CodingQuestionExample
 */
export interface CodingQuestionExample {
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionExample
     */
    input?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionExample
     */
    output?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingQuestionExample
     */
    note?: string | null;
}
/**
 * 
 * @export
 * @interface CodingSubmissionDataPointDto
 */
export interface CodingSubmissionDataPointDto {
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDataPointDto
     */
    limitTime?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDataPointDto
     */
    limitMemory?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDataPointDto
     */
    memoryUsage?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDataPointDto
     */
    timeUsage?: number;
    /**
     * 
     * @type {CodingSubmissionStatus}
     * @memberof CodingSubmissionDataPointDto
     */
    status?: CodingSubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDataPointDto
     */
    errorInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDataPointDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDataPointDto
     */
    inputFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDataPointDto
     */
    outputFileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDataPointDto
     */
    codingSubmissionId?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDataPointDto
     */
    dataPointId?: number;
}
/**
 * 
 * @export
 * @interface CodingSubmissionDto
 */
export interface CodingSubmissionDto {
    /**
     * 
     * @type {CodingSubmissionStatus}
     * @memberof CodingSubmissionDto
     */
    status?: CodingSubmissionStatus;
    /**
     * 
     * @type {CodingLanguage}
     * @memberof CodingSubmissionDto
     */
    language?: CodingLanguage;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    memoryUsage?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    timeUsage?: number;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDto
     */
    errorInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDto
     */
    versionMd5?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    limitTime?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    limitMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDto
     */
    inputFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDto
     */
    outputFileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    codingQuestionId?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    scoreGet?: number;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    totalScore?: number;
    /**
     * 
     * @type {CodingQuestionDto}
     * @memberof CodingSubmissionDto
     */
    codingQuestion?: CodingQuestionDto;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    submitterId?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof CodingSubmissionDto
     */
    submitter?: UserDto;
    /**
     * 
     * @type {Array<CodingSubmissionDataPointDto>}
     * @memberof CodingSubmissionDto
     */
    dataPoints?: Array<CodingSubmissionDataPointDto> | null;
    /**
     * 
     * @type {Array<SubmissionTaskWithScore>}
     * @memberof CodingSubmissionDto
     */
    tasks?: Array<SubmissionTaskWithScore> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodingSubmissionDto
     */
    isOutDate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface CodingSubmissionDtoSearchResponseDto
 */
export interface CodingSubmissionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<CodingSubmissionDto>}
     * @memberof CodingSubmissionDtoSearchResponseDto
     */
    list?: Array<CodingSubmissionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CodingSubmissionStatus {
    答案正确 = '答案正确',
    答案错误 = '答案错误',
    等待评测 = '等待评测',
    评测机故障 = '评测机故障',
    正在评测 = '正在评测',
    编译失败 = '编译失败',
    运行时错误 = '运行时错误',
    内存超出 = '内存超出',
    运行超时 = '运行超时',
    部分正确 = '部分正确',
    暂未公布 = '暂未公布'
}

/**
 * 
 * @export
 * @interface CopyPaperParams
 */
export interface CopyPaperParams {
    /**
     * 
     * @type {string}
     * @memberof CopyPaperParams
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CopyPaperParams
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CopySubmissionParams
 */
export interface CopySubmissionParams {
    /**
     * 
     * @type {string}
     * @memberof CopySubmissionParams
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CopySubmissionParams
     */
    clusterName: string;
    /**
     * 
     * @type {string}
     * @memberof CopySubmissionParams
     */
    createdTime: string;
    /**
     * 
     * @type {string}
     * @memberof CopySubmissionParams
     */
    code: string;
    /**
     * 
     * @type {CodingLanguage}
     * @memberof CopySubmissionParams
     */
    language: CodingLanguage;
    /**
     * 
     * @type {number}
     * @memberof CopySubmissionParams
     */
    codingQuestionId: number;
}
/**
 * 
 * @export
 * @interface CourseArticleDto
 */
export interface CourseArticleDto {
    /**
     * 
     * @type {string}
     * @memberof CourseArticleDto
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseArticleDto
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseArticleDto
     */
    pdfUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseArticleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseArticleDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface CourseArticleDtoSearchResponseDto
 */
export interface CourseArticleDtoSearchResponseDto {
    /**
     * 
     * @type {Array<CourseArticleDto>}
     * @memberof CourseArticleDtoSearchResponseDto
     */
    list?: Array<CourseArticleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseArticleDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface CourseDto
 */
export interface CourseDto {
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    isHide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    isPublic?: boolean;
    /**
     * 
     * @type {Array<GroupDto>}
     * @memberof CourseDto
     */
    groups?: Array<GroupDto> | null;
    /**
     * 
     * @type {Array<CourseLessonDto>}
     * @memberof CourseDto
     */
    lessons?: Array<CourseLessonDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    solvedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface CourseDtoSearchResponseDto
 */
export interface CourseDtoSearchResponseDto {
    /**
     * 
     * @type {Array<CourseDto>}
     * @memberof CourseDtoSearchResponseDto
     */
    list?: Array<CourseDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface CourseLessonDto
 */
export interface CourseLessonDto {
    /**
     * 
     * @type {Array<CourseLessonItemDto>}
     * @memberof CourseLessonDto
     */
    items?: Array<CourseLessonItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CourseLessonDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseLessonDto
     */
    sortIds?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseLessonDto
     */
    courseId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseLessonDto
     */
    solvedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseLessonDto
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseLessonDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseLessonDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface CourseLessonItemDto
 */
export interface CourseLessonItemDto {
    /**
     * 
     * @type {CourseLessonItemType}
     * @memberof CourseLessonItemDto
     */
    type?: CourseLessonItemType;
    /**
     * 
     * @type {CodingQuestionDto}
     * @memberof CourseLessonItemDto
     */
    codingQuestion?: CodingQuestionDto;
    /**
     * 
     * @type {CourseArticleDto}
     * @memberof CourseLessonItemDto
     */
    courseArticle?: CourseArticleDto;
    /**
     * 
     * @type {boolean}
     * @memberof CourseLessonItemDto
     */
    isSolved?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CourseLessonItemType {
    ExampleCodingQuestion = 'ExampleCodingQuestion',
    CodingQuestion = 'CodingQuestion',
    Article = 'Article'
}

/**
 * 
 * @export
 * @interface CourseSetGroupParams
 */
export interface CourseSetGroupParams {
    /**
     * 
     * @type {number}
     * @memberof CourseSetGroupParams
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseSetGroupParams
     */
    groupIds: Array<number>;
}
/**
 * 
 * @export
 * @interface DataPointDetailWithScore
 */
export interface DataPointDetailWithScore {
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    dataPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    limitTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    limitMemory?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    memoryUsage?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    timeUsage?: number;
    /**
     * 
     * @type {CodingSubmissionStatus}
     * @memberof DataPointDetailWithScore
     */
    status?: CodingSubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof DataPointDetailWithScore
     */
    errorInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataPointDetailWithScore
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataPointDetailWithScore
     */
    inputFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataPointDetailWithScore
     */
    outputFileName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DataPointDetailWithScore
     */
    unionMode?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointDetailWithScore
     */
    scoreGet?: number;
}
/**
 * 
 * @export
 * @interface DataPointDto
 */
export interface DataPointDto {
    /**
     * 
     * @type {number}
     * @memberof DataPointDto
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof DataPointDto
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataPointDto
     */
    taskName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DataPointDto
     */
    unionMode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataPointDto
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DataPointDto
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataPointDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface DeleteLessonParams
 */
export interface DeleteLessonParams {
    /**
     * 
     * @type {number}
     * @memberof DeleteLessonParams
     */
    lessonId: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteLessonParams
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface EssayQuestionDto
 */
export interface EssayQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof EssayQuestionDto
     */
    rightAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EssayQuestionDto
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EssayQuestionDto
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof EssayQuestionDto
     */
    type?: QuestionType;
    /**
     * 
     * @type {number}
     * @memberof EssayQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EssayQuestionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface Exam
 */
export interface Exam {
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isAllowSubmit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isHide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isShowAnswer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isShowScore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isAutoCorrect?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    maxSubmitCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    paperId?: number;
    /**
     * 
     * @type {Paper}
     * @memberof Exam
     */
    paper?: Paper;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    limitMinute?: number;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Exam
     */
    groups?: Array<Group> | null;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    clusterId?: number;
    /**
     * 
     * @type {Cluster}
     * @memberof Exam
     */
    cluster?: Cluster;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    deletedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    restoredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    isDelete?: boolean;
}
/**
 * 
 * @export
 * @interface ExamAnalyseCodingQuestionItemDto
 */
export interface ExamAnalyseCodingQuestionItemDto {
    /**
     * 
     * @type {ExamSubmissionDto}
     * @memberof ExamAnalyseCodingQuestionItemDto
     */
    examSubmission?: ExamSubmissionDto;
    /**
     * 
     * @type {number}
     * @memberof ExamAnalyseCodingQuestionItemDto
     */
    totalScoreGet?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamAnalyseCodingQuestionItemDto
     */
    totalSubmittedCount?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof ExamAnalyseCodingQuestionItemDto
     */
    user?: UserDto;
    /**
     * 
     * @type {Array<QuestionSolvedDetail>}
     * @memberof ExamAnalyseCodingQuestionItemDto
     */
    details?: Array<QuestionSolvedDetail> | null;
}
/**
 * 
 * @export
 * @interface ExamAnalyseQuestionItem
 */
export interface ExamAnalyseQuestionItem {
    /**
     * 
     * @type {number}
     * @memberof ExamAnalyseQuestionItem
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamAnalyseQuestionItem
     */
    userCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamAnalyseQuestionItem
     */
    rightCount?: number;
}
/**
 * 
 * @export
 * @interface ExamBatchAutoMarkSubmissionParams
 */
export interface ExamBatchAutoMarkSubmissionParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof ExamBatchAutoMarkSubmissionParams
     */
    ids: Array<number>;
}
/**
 * 
 * @export
 * @interface ExamDto
 */
export interface ExamDto {
    /**
     * 
     * @type {string}
     * @memberof ExamDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExamDto
     */
    isAllowSubmit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExamDto
     */
    isHide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExamDto
     */
    isShowAnswer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExamDto
     */
    isAutoCorrect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExamDto
     */
    isShowScore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExamDto
     */
    maxSubmitCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamDto
     */
    limitMinute?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamDto
     */
    paperId?: number;
    /**
     * 
     * @type {PaperDto}
     * @memberof ExamDto
     */
    paper?: PaperDto;
    /**
     * 
     * @type {Array<GroupDto>}
     * @memberof ExamDto
     */
    groups?: Array<GroupDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExamDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExamDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface ExamDtoSearchResponseDto
 */
export interface ExamDtoSearchResponseDto {
    /**
     * 
     * @type {Array<ExamDto>}
     * @memberof ExamDtoSearchResponseDto
     */
    list?: Array<ExamDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExamDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ExamMarkSubmissionParams
 */
export interface ExamMarkSubmissionParams {
    /**
     * 
     * @type {number}
     * @memberof ExamMarkSubmissionParams
     */
    submissionId: number;
    /**
     * 
     * @type {number}
     * @memberof ExamMarkSubmissionParams
     */
    questionId: number;
    /**
     * 
     * @type {number}
     * @memberof ExamMarkSubmissionParams
     */
    scoreGet: number;
}
/**
 * 
 * @export
 * @interface ExamSetGroupParams
 */
export interface ExamSetGroupParams {
    /**
     * 
     * @type {number}
     * @memberof ExamSetGroupParams
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExamSetGroupParams
     */
    groupIds: Array<number>;
}
/**
 * 
 * @export
 * @interface ExamSubmissionCreateParams
 */
export interface ExamSubmissionCreateParams {
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionCreateParams
     */
    examId: number;
}
/**
 * 
 * @export
 * @interface ExamSubmissionDto
 */
export interface ExamSubmissionDto {
    /**
     * 
     * @type {string}
     * @memberof ExamSubmissionDto
     */
    submittedTime?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionDto
     */
    studentId?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof ExamSubmissionDto
     */
    student?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionDto
     */
    examId?: number;
    /**
     * 
     * @type {ExamDto}
     * @memberof ExamSubmissionDto
     */
    exam?: ExamDto;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionDto
     */
    paperId?: number;
    /**
     * 
     * @type {PaperDto}
     * @memberof ExamSubmissionDto
     */
    paper?: PaperDto;
    /**
     * 
     * @type {Array<SubmitQuestionDetailDto>}
     * @memberof ExamSubmissionDto
     */
    submitQuestionDetails?: Array<SubmitQuestionDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionDto
     */
    scoreGet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExamSubmissionDto
     */
    isMarked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExamSubmissionDto
     */
    expiredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExamSubmissionDto
     */
    isSubmitted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExamSubmissionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface ExamSubmissionDtoSearchResponseDto
 */
export interface ExamSubmissionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<ExamSubmissionDto>}
     * @memberof ExamSubmissionDtoSearchResponseDto
     */
    list?: Array<ExamSubmissionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ExamSubmissionSaveParams
 */
export interface ExamSubmissionSaveParams {
    /**
     * 
     * @type {Array<ExamSubmissionSaveParamsQuestionItem>}
     * @memberof ExamSubmissionSaveParams
     */
    questionItems: Array<ExamSubmissionSaveParamsQuestionItem>;
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionSaveParams
     */
    submissionId: number;
}
/**
 * 
 * @export
 * @interface ExamSubmissionSaveParamsQuestionItem
 */
export interface ExamSubmissionSaveParamsQuestionItem {
    /**
     * 
     * @type {number}
     * @memberof ExamSubmissionSaveParamsQuestionItem
     */
    questionId: number;
    /**
     * 
     * @type {string}
     * @memberof ExamSubmissionSaveParamsQuestionItem
     */
    userInput: string;
}
/**
 * 
 * @export
 * @interface ExcelImportResponse
 */
export interface ExcelImportResponse {
    /**
     * 
     * @type {string}
     * @memberof ExcelImportResponse
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExcelImportResponse
     */
    errorExcelUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExcelImportResponse
     */
    addCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExcelImportResponse
     */
    passCount?: number;
}
/**
 * 
 * @export
 * @interface FillQuestionDto
 */
export interface FillQuestionDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FillQuestionDto
     */
    rightAnswers?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FillQuestionDto
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FillQuestionDto
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof FillQuestionDto
     */
    type?: QuestionType;
    /**
     * 
     * @type {number}
     * @memberof FillQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FillQuestionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface FindParams
 */
export interface FindParams {
    /**
     * 
     * @type {number}
     * @memberof FindParams
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface GetExcelTemplateResponse
 */
export interface GetExcelTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof GetExcelTemplateResponse
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name?: string | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof Group
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    studentCount?: number;
    /**
     * 
     * @type {Array<Exam>}
     * @memberof Group
     */
    exams?: Array<Exam> | null;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    clusterId?: number;
    /**
     * 
     * @type {Cluster}
     * @memberof Group
     */
    cluster?: Cluster;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    deletedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    restoredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    isDelete?: boolean;
}
/**
 * 
 * @export
 * @interface GroupDto
 */
export interface GroupDto {
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupDto
     */
    studentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface NoticeDto
 */
export interface NoticeDto {
    /**
     * 
     * @type {string}
     * @memberof NoticeDto
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoticeDto
     */
    content?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeDto
     */
    isHide?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoticeDto
     */
    publishedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof NoticeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NoticeDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface OperateQuestionDto
 */
export interface OperateQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof OperateQuestionDto
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperateQuestionDto
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof OperateQuestionDto
     */
    type?: QuestionType;
    /**
     * 
     * @type {number}
     * @memberof OperateQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OperateQuestionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface Paper
 */
export interface Paper {
    /**
     * 
     * @type {string}
     * @memberof Paper
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Paper
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Paper
     */
    isRandomOption?: boolean;
    /**
     * 
     * @type {ChoiceQuestionScoreType}
     * @memberof Paper
     */
    choiceQuestionScoreType?: ChoiceQuestionScoreType;
    /**
     * 
     * @type {Array<PaperQuestionGroup>}
     * @memberof Paper
     */
    questionGroups?: Array<PaperQuestionGroup> | null;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Paper
     */
    questions?: Array<Question> | null;
    /**
     * 
     * @type {Array<PaperQuestionDetailGroup>}
     * @memberof Paper
     */
    questionDetailGroups?: Array<PaperQuestionDetailGroup> | null;
    /**
     * 
     * @type {number}
     * @memberof Paper
     */
    clusterId?: number;
    /**
     * 
     * @type {Cluster}
     * @memberof Paper
     */
    cluster?: Cluster;
    /**
     * 
     * @type {number}
     * @memberof Paper
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Paper
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Paper
     */
    deletedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Paper
     */
    restoredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Paper
     */
    isDelete?: boolean;
}
/**
 * 
 * @export
 * @interface PaperDto
 */
export interface PaperDto {
    /**
     * 
     * @type {string}
     * @memberof PaperDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaperDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaperDto
     */
    isRandomOption?: boolean;
    /**
     * 
     * @type {ChoiceQuestionScoreType}
     * @memberof PaperDto
     */
    choiceQuestionScoreType?: ChoiceQuestionScoreType;
    /**
     * 
     * @type {Array<PaperQuestionDetailGroupDto>}
     * @memberof PaperDto
     */
    questionDetailGroups?: Array<PaperQuestionDetailGroupDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PaperDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PaperDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface PaperQuestionDetailGroup
 */
export interface PaperQuestionDetailGroup {
    /**
     * 
     * @type {Array<Question>}
     * @memberof PaperQuestionDetailGroup
     */
    questions?: Array<Question> | null;
    /**
     * 
     * @type {number}
     * @memberof PaperQuestionDetailGroup
     */
    pickCount: number;
    /**
     * 
     * @type {number}
     * @memberof PaperQuestionDetailGroup
     */
    eachScore: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaperQuestionDetailGroup
     */
    isRandomQuestion: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PaperQuestionDetailGroup
     */
    questionIds: Array<number>;
}
/**
 * 
 * @export
 * @interface PaperQuestionDetailGroupDto
 */
export interface PaperQuestionDetailGroupDto {
    /**
     * 
     * @type {Array<QuestionDto>}
     * @memberof PaperQuestionDetailGroupDto
     */
    questions?: Array<QuestionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PaperQuestionDetailGroupDto
     */
    pickCount: number;
    /**
     * 
     * @type {number}
     * @memberof PaperQuestionDetailGroupDto
     */
    eachScore: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaperQuestionDetailGroupDto
     */
    isRandomQuestion: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PaperQuestionDetailGroupDto
     */
    questionIds: Array<number>;
}
/**
 * 
 * @export
 * @interface PaperQuestionGroup
 */
export interface PaperQuestionGroup {
    /**
     * 
     * @type {number}
     * @memberof PaperQuestionGroup
     */
    pickCount: number;
    /**
     * 
     * @type {number}
     * @memberof PaperQuestionGroup
     */
    eachScore: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaperQuestionGroup
     */
    isRandomQuestion: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PaperQuestionGroup
     */
    questionIds: Array<number>;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof Question
     */
    type?: QuestionType;
    /**
     * 
     * @type {Array<Paper>}
     * @memberof Question
     */
    papers?: Array<Paper> | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    clusterId?: number;
    /**
     * 
     * @type {Cluster}
     * @memberof Question
     */
    cluster?: Cluster;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    deletedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    restoredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    isDelete?: boolean;
}
/**
 * 
 * @export
 * @interface QuestionDto
 */
export interface QuestionDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    topic?: string | null;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionDto
     */
    type?: QuestionType;
    /**
     * 
     * @type {number}
     * @memberof QuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface QuestionDtoSearchResponseDto
 */
export interface QuestionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<QuestionDto>}
     * @memberof QuestionDtoSearchResponseDto
     */
    list?: Array<QuestionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface QuestionSolvedDetail
 */
export interface QuestionSolvedDetail {
    /**
     * 
     * @type {number}
     * @memberof QuestionSolvedDetail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionSolvedDetail
     */
    scoreGet?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionSolvedDetail
     */
    scoreTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionSolvedDetail
     */
    submittedCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionSolvedDetail
     */
    isSolved?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum QuestionType {
    选择题 = '选择题',
    填空题 = '填空题',
    论述题 = '论述题',
    操作题 = '操作题',
    编程题 = '编程题'
}

/**
 * 
 * @export
 * @interface SaveChoiceQuestionParams
 */
export interface SaveChoiceQuestionParams {
    /**
     * 
     * @type {boolean}
     * @memberof SaveChoiceQuestionParams
     */
    isMultiple: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveChoiceQuestionParams
     */
    options: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SaveChoiceQuestionParams
     */
    rightLabel: string;
    /**
     * 
     * @type {number}
     * @memberof SaveChoiceQuestionParams
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveChoiceQuestionParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveChoiceQuestionParams
     */
    keywords?: string | null;
}
/**
 * 
 * @export
 * @interface SaveCodingQuestionParams
 */
export interface SaveCodingQuestionParams {
    /**
     * 
     * @type {number}
     * @memberof SaveCodingQuestionParams
     */
    limitTime: number;
    /**
     * 
     * @type {number}
     * @memberof SaveCodingQuestionParams
     */
    limitMemory: number;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    dataRange?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    inputFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    outputFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    inputFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    outputFileName?: string | null;
    /**
     * 
     * @type {Array<CodingQuestionExample>}
     * @memberof SaveCodingQuestionParams
     */
    examples: Array<CodingQuestionExample>;
    /**
     * 
     * @type {number}
     * @memberof SaveCodingQuestionParams
     */
    aspirinQuestionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaveCodingQuestionParams
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCodingQuestionParams
     */
    keywords?: string | null;
}
/**
 * 
 * @export
 * @interface SaveCourseArticleParams
 */
export interface SaveCourseArticleParams {
    /**
     * 
     * @type {string}
     * @memberof SaveCourseArticleParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCourseArticleParams
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCourseArticleParams
     */
    pdfUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaveCourseArticleParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCourseArticleParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCourseArticleParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @interface SaveCourseParams
 */
export interface SaveCourseParams {
    /**
     * 
     * @type {string}
     * @memberof SaveCourseParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SaveCourseParams
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCourseParams
     */
    isHide: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCourseParams
     */
    isPublic: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof SaveCourseParams
     */
    lessonSortIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof SaveCourseParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCourseParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveCourseParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @interface SaveEssayQuestionParams
 */
export interface SaveEssayQuestionParams {
    /**
     * 
     * @type {string}
     * @memberof SaveEssayQuestionParams
     */
    rightAnswer: string;
    /**
     * 
     * @type {number}
     * @memberof SaveEssayQuestionParams
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveEssayQuestionParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveEssayQuestionParams
     */
    keywords?: string | null;
}
/**
 * 
 * @export
 * @interface SaveExamParams
 */
export interface SaveExamParams {
    /**
     * 
     * @type {string}
     * @memberof SaveExamParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SaveExamParams
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isAllowSubmit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isHide: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isShowAnswer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isAutoCorrect: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveExamParams
     */
    maxSubmitCount: number;
    /**
     * 
     * @type {number}
     * @memberof SaveExamParams
     */
    paperId: number;
    /**
     * 
     * @type {number}
     * @memberof SaveExamParams
     */
    limitMinute: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isShowScore: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveExamParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveExamParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @interface SaveFillQuestionParams
 */
export interface SaveFillQuestionParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveFillQuestionParams
     */
    rightAnswers: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SaveFillQuestionParams
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveFillQuestionParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFillQuestionParams
     */
    keywords?: string | null;
}
/**
 * 
 * @export
 * @interface SaveGroupParams
 */
export interface SaveGroupParams {
    /**
     * 
     * @type {string}
     * @memberof SaveGroupParams
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SaveGroupParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveGroupParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveGroupParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @interface SaveNoticeParams
 */
export interface SaveNoticeParams {
    /**
     * 
     * @type {string}
     * @memberof SaveNoticeParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveNoticeParams
     */
    content: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveNoticeParams
     */
    isHide: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveNoticeParams
     */
    publishedDate: string;
    /**
     * 
     * @type {number}
     * @memberof SaveNoticeParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveNoticeParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveNoticeParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @interface SaveOperateQuestionParams
 */
export interface SaveOperateQuestionParams {
    /**
     * 
     * @type {number}
     * @memberof SaveOperateQuestionParams
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveOperateQuestionParams
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOperateQuestionParams
     */
    keywords?: string | null;
}
/**
 * 
 * @export
 * @interface SavePaperParams
 */
export interface SavePaperParams {
    /**
     * 
     * @type {string}
     * @memberof SavePaperParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SavePaperParams
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SavePaperParams
     */
    isRandomOption: boolean;
    /**
     * 
     * @type {ChoiceQuestionScoreType}
     * @memberof SavePaperParams
     */
    choiceQuestionScoreType: ChoiceQuestionScoreType;
    /**
     * 
     * @type {Array<PaperQuestionGroup>}
     * @memberof SavePaperParams
     */
    questionGroups: Array<PaperQuestionGroup>;
    /**
     * 
     * @type {number}
     * @memberof SavePaperParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SavePaperParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavePaperParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SorterOrder {
    Desc = 'Desc',
    Asc = 'Asc'
}

/**
 * 
 * @export
 * @interface SubmissionTaskWithScore
 */
export interface SubmissionTaskWithScore {
    /**
     * 
     * @type {Array<DataPointDetailWithScore>}
     * @memberof SubmissionTaskWithScore
     */
    dataPoints?: Array<DataPointDetailWithScore> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubmissionTaskWithScore
     */
    unionMode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubmissionTaskWithScore
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubmissionTaskWithScore
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionTaskWithScore
     */
    scoreGet?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionTaskWithScore
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface SubmitCodingParams
 */
export interface SubmitCodingParams {
    /**
     * 
     * @type {string}
     * @memberof SubmitCodingParams
     */
    code: string;
    /**
     * 
     * @type {CodingLanguage}
     * @memberof SubmitCodingParams
     */
    language: CodingLanguage;
    /**
     * 
     * @type {number}
     * @memberof SubmitCodingParams
     */
    codingQuestionId: number;
}
/**
 * 
 * @export
 * @interface SubmitQuestionDetailDto
 */
export interface SubmitQuestionDetailDto {
    /**
     * 
     * @type {number}
     * @memberof SubmitQuestionDetailDto
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitQuestionDetailDto
     */
    scoreGet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitQuestionDetailDto
     */
    isMarked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubmitQuestionDetailDto
     */
    userInput?: string | null;
    /**
     * 
     * @type {QuestionDto}
     * @memberof SubmitQuestionDetailDto
     */
    question?: QuestionDto;
}
/**
 * 
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    body?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    expiredTime?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TokenDto
     */
    user?: UserDto;
}
/**
 * 
 * @export
 * @interface UpdateDataPointTaskNameParams
 */
export interface UpdateDataPointTaskNameParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDataPointTaskNameParams
     */
    dataPointIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataPointTaskNameParams
     */
    taskName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDataPointTaskNameParams
     */
    questionId: number;
}
/**
 * 
 * @export
 * @interface UpdateLessonArticle
 */
export interface UpdateLessonArticle {
    /**
     * 
     * @type {number}
     * @memberof UpdateLessonArticle
     */
    articleId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLessonArticle
     */
    lessonId: number;
}
/**
 * 
 * @export
 * @interface UpdateLessonCodingQuestion
 */
export interface UpdateLessonCodingQuestion {
    /**
     * 
     * @type {number}
     * @memberof UpdateLessonCodingQuestion
     */
    codingQuestionId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLessonCodingQuestion
     */
    lessonId: number;
}
/**
 * 
 * @export
 * @interface UpdateLessonParams
 */
export interface UpdateLessonParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateLessonParams
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLessonParams
     */
    sortIds?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLessonParams
     */
    lessonId: number;
}
/**
 * 
 * @export
 * @interface UpdateTaskParams
 */
export interface UpdateTaskParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskParams
     */
    taskName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskParams
     */
    unionMode: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskParams
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskParams
     */
    questionId: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    role?: UserRole;
    /**
     * 
     * @type {Array<Group>}
     * @memberof User
     */
    groups?: Array<Group> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    groupNames?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    clusterId?: number;
    /**
     * 
     * @type {Cluster}
     * @memberof User
     */
    cluster?: Cluster;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    deletedTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    restoredTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isDelete?: boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    username?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof UserDto
     */
    role?: UserRole;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    groupNames?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    createdTime?: string;
}
/**
 * 
 * @export
 * @interface UserDtoSearchResponseDto
 */
export interface UserDtoSearchResponseDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UserDtoSearchResponseDto
     */
    list?: Array<UserDto> | null;
    /**
     * 
     * @type {number}
     * @memberof UserDtoSearchResponseDto
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface UserQuestionSolvedDetail
 */
export interface UserQuestionSolvedDetail {
    /**
     * 
     * @type {number}
     * @memberof UserQuestionSolvedDetail
     */
    totalScoreGet?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuestionSolvedDetail
     */
    totalSubmittedCount?: number;
    /**
     * 
     * @type {User}
     * @memberof UserQuestionSolvedDetail
     */
    user?: User;
    /**
     * 
     * @type {Array<QuestionSolvedDetail>}
     * @memberof UserQuestionSolvedDetail
     */
    details?: Array<QuestionSolvedDetail> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserRole {
    超级管理员 = '超级管理员',
    管理员 = '管理员',
    教师 = '教师',
    学生 = '学生'
}

/**
 * 
 * @export
 * @interface UserSaveParams
 */
export interface UserSaveParams {
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaveParams
     */
    isTeacher: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserSaveParams
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaveParams
     */
    isUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaveParams
     */
    isAdd?: boolean;
}
/**
 * 
 * @export
 * @interface UserUpdatePasswordParams
 */
export interface UserUpdatePasswordParams {
    /**
     * 
     * @type {number}
     * @memberof UserUpdatePasswordParams
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatePasswordParams
     */
    password: string;
}

/**
 * AuthorizeApi - axios parameter creator
 * @export
 */
export const AuthorizeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeHeartbeatGet: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authorize/Heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorizeLoginParams} [authorizeLoginParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeLoginPost: async (authorizeLoginParams?: AuthorizeLoginParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authorize/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizeLoginParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizeApi - functional programming interface
 * @export
 */
export const AuthorizeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeHeartbeatGet(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeHeartbeatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeHeartbeatGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorizeLoginParams} [authorizeLoginParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeLoginPost(authorizeLoginParams?: AuthorizeLoginParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeLoginPost(authorizeLoginParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizeApi - factory interface
 * @export
 */
export const AuthorizeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeHeartbeatGet(body?: string, options?: any): AxiosPromise<AuthorizeHeartbeatDto> {
            return localVarFp.authorizeHeartbeatGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorizeLoginParams} [authorizeLoginParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeLoginPost(authorizeLoginParams?: AuthorizeLoginParams, options?: any): AxiosPromise<TokenDto> {
            return localVarFp.authorizeLoginPost(authorizeLoginParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authorizeHeartbeatGet operation in AuthorizeApi.
 * @export
 * @interface AuthorizeApiAuthorizeHeartbeatGetRequest
 */
export interface AuthorizeApiAuthorizeHeartbeatGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeApiAuthorizeHeartbeatGet
     */
    readonly body?: string
}

/**
 * Request parameters for authorizeLoginPost operation in AuthorizeApi.
 * @export
 * @interface AuthorizeApiAuthorizeLoginPostRequest
 */
export interface AuthorizeApiAuthorizeLoginPostRequest {
    /**
     * 
     * @type {AuthorizeLoginParams}
     * @memberof AuthorizeApiAuthorizeLoginPost
     */
    readonly authorizeLoginParams?: AuthorizeLoginParams
}

/**
 * AuthorizeApi - object-oriented interface
 * @export
 * @class AuthorizeApi
 * @extends {BaseAPI}
 */
export class AuthorizeApi extends BaseAPI {
    /**
     * 
     * @param {AuthorizeApiAuthorizeHeartbeatGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizeApi
     */
    public authorizeHeartbeatGet(requestParameters: AuthorizeApiAuthorizeHeartbeatGetRequest = {}, options?: any) {
        return AuthorizeApiFp(this.configuration).authorizeHeartbeatGet(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorizeApiAuthorizeLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizeApi
     */
    public authorizeLoginPost(requestParameters: AuthorizeApiAuthorizeLoginPostRequest = {}, options?: any) {
        return AuthorizeApiFp(this.configuration).authorizeLoginPost(requestParameters.authorizeLoginParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CodingSubmissionApi - axios parameter creator
 * @export
 */
export const CodingSubmissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} codingQuestionId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionAnalyseUserSolvedGet: async (codingQuestionId: number, groupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'codingQuestionId' is not null or undefined
            assertParamExists('codingSubmissionAnalyseUserSolvedGet', 'codingQuestionId', codingQuestionId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('codingSubmissionAnalyseUserSolvedGet', 'groupId', groupId)
            const localVarPath = `/CodingSubmission/AnalyseUserSolved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codingQuestionId !== undefined) {
                localVarQueryParameter['CodingQuestionId'] = codingQuestionId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CopySubmissionParams} [copySubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionCopyPost: async (copySubmissionParams?: CopySubmissionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CodingSubmission/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copySubmissionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionFindByIdGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CodingSubmission/FindById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionResubmitPost: async (findParams?: FindParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CodingSubmission/Resubmit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [codingQuestionId] 
         * @param {number} [submitterId] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionSearchGet: async (pi: number, ps: number, codingQuestionId?: number, submitterId?: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('codingSubmissionSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('codingSubmissionSearchGet', 'ps', ps)
            const localVarPath = `/CodingSubmission/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codingQuestionId !== undefined) {
                localVarQueryParameter['CodingQuestionId'] = codingQuestionId;
            }

            if (submitterId !== undefined) {
                localVarQueryParameter['SubmitterId'] = submitterId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitCodingParams} [submitCodingParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionSubmitPost: async (submitCodingParams?: SubmitCodingParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CodingSubmission/Submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitCodingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodingSubmissionApi - functional programming interface
 * @export
 */
export const CodingSubmissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodingSubmissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} codingQuestionId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codingSubmissionAnalyseUserSolvedGet(codingQuestionId: number, groupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserQuestionSolvedDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codingSubmissionAnalyseUserSolvedGet(codingQuestionId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CopySubmissionParams} [copySubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codingSubmissionCopyPost(copySubmissionParams?: CopySubmissionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codingSubmissionCopyPost(copySubmissionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codingSubmissionFindByIdGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codingSubmissionFindByIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codingSubmissionResubmitPost(findParams?: FindParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codingSubmissionResubmitPost(findParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [codingQuestionId] 
         * @param {number} [submitterId] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codingSubmissionSearchGet(pi: number, ps: number, codingQuestionId?: number, submitterId?: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingSubmissionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codingSubmissionSearchGet(pi, ps, codingQuestionId, submitterId, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubmitCodingParams} [submitCodingParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codingSubmissionSubmitPost(submitCodingParams?: SubmitCodingParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codingSubmissionSubmitPost(submitCodingParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodingSubmissionApi - factory interface
 * @export
 */
export const CodingSubmissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodingSubmissionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} codingQuestionId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionAnalyseUserSolvedGet(codingQuestionId: number, groupId: number, options?: any): AxiosPromise<Array<UserQuestionSolvedDetail>> {
            return localVarFp.codingSubmissionAnalyseUserSolvedGet(codingQuestionId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CopySubmissionParams} [copySubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionCopyPost(copySubmissionParams?: CopySubmissionParams, options?: any): AxiosPromise<CodingSubmissionDto> {
            return localVarFp.codingSubmissionCopyPost(copySubmissionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionFindByIdGet(id?: number, options?: any): AxiosPromise<CodingSubmissionDto> {
            return localVarFp.codingSubmissionFindByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionResubmitPost(findParams?: FindParams, options?: any): AxiosPromise<CodingSubmissionDto> {
            return localVarFp.codingSubmissionResubmitPost(findParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [codingQuestionId] 
         * @param {number} [submitterId] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionSearchGet(pi: number, ps: number, codingQuestionId?: number, submitterId?: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<CodingSubmissionDtoSearchResponseDto> {
            return localVarFp.codingSubmissionSearchGet(pi, ps, codingQuestionId, submitterId, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitCodingParams} [submitCodingParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codingSubmissionSubmitPost(submitCodingParams?: SubmitCodingParams, options?: any): AxiosPromise<CodingSubmissionDto> {
            return localVarFp.codingSubmissionSubmitPost(submitCodingParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for codingSubmissionAnalyseUserSolvedGet operation in CodingSubmissionApi.
 * @export
 * @interface CodingSubmissionApiCodingSubmissionAnalyseUserSolvedGetRequest
 */
export interface CodingSubmissionApiCodingSubmissionAnalyseUserSolvedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionAnalyseUserSolvedGet
     */
    readonly codingQuestionId: number

    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionAnalyseUserSolvedGet
     */
    readonly groupId: number
}

/**
 * Request parameters for codingSubmissionCopyPost operation in CodingSubmissionApi.
 * @export
 * @interface CodingSubmissionApiCodingSubmissionCopyPostRequest
 */
export interface CodingSubmissionApiCodingSubmissionCopyPostRequest {
    /**
     * 
     * @type {CopySubmissionParams}
     * @memberof CodingSubmissionApiCodingSubmissionCopyPost
     */
    readonly copySubmissionParams?: CopySubmissionParams
}

/**
 * Request parameters for codingSubmissionFindByIdGet operation in CodingSubmissionApi.
 * @export
 * @interface CodingSubmissionApiCodingSubmissionFindByIdGetRequest
 */
export interface CodingSubmissionApiCodingSubmissionFindByIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionFindByIdGet
     */
    readonly id?: number
}

/**
 * Request parameters for codingSubmissionResubmitPost operation in CodingSubmissionApi.
 * @export
 * @interface CodingSubmissionApiCodingSubmissionResubmitPostRequest
 */
export interface CodingSubmissionApiCodingSubmissionResubmitPostRequest {
    /**
     * 
     * @type {FindParams}
     * @memberof CodingSubmissionApiCodingSubmissionResubmitPost
     */
    readonly findParams?: FindParams
}

/**
 * Request parameters for codingSubmissionSearchGet operation in CodingSubmissionApi.
 * @export
 * @interface CodingSubmissionApiCodingSubmissionSearchGetRequest
 */
export interface CodingSubmissionApiCodingSubmissionSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly codingQuestionId?: number

    /**
     * 
     * @type {number}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly submitterId?: number

    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof CodingSubmissionApiCodingSubmissionSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for codingSubmissionSubmitPost operation in CodingSubmissionApi.
 * @export
 * @interface CodingSubmissionApiCodingSubmissionSubmitPostRequest
 */
export interface CodingSubmissionApiCodingSubmissionSubmitPostRequest {
    /**
     * 
     * @type {SubmitCodingParams}
     * @memberof CodingSubmissionApiCodingSubmissionSubmitPost
     */
    readonly submitCodingParams?: SubmitCodingParams
}

/**
 * CodingSubmissionApi - object-oriented interface
 * @export
 * @class CodingSubmissionApi
 * @extends {BaseAPI}
 */
export class CodingSubmissionApi extends BaseAPI {
    /**
     * 
     * @param {CodingSubmissionApiCodingSubmissionAnalyseUserSolvedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodingSubmissionApi
     */
    public codingSubmissionAnalyseUserSolvedGet(requestParameters: CodingSubmissionApiCodingSubmissionAnalyseUserSolvedGetRequest, options?: any) {
        return CodingSubmissionApiFp(this.configuration).codingSubmissionAnalyseUserSolvedGet(requestParameters.codingQuestionId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodingSubmissionApiCodingSubmissionCopyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodingSubmissionApi
     */
    public codingSubmissionCopyPost(requestParameters: CodingSubmissionApiCodingSubmissionCopyPostRequest = {}, options?: any) {
        return CodingSubmissionApiFp(this.configuration).codingSubmissionCopyPost(requestParameters.copySubmissionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodingSubmissionApiCodingSubmissionFindByIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodingSubmissionApi
     */
    public codingSubmissionFindByIdGet(requestParameters: CodingSubmissionApiCodingSubmissionFindByIdGetRequest = {}, options?: any) {
        return CodingSubmissionApiFp(this.configuration).codingSubmissionFindByIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodingSubmissionApiCodingSubmissionResubmitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodingSubmissionApi
     */
    public codingSubmissionResubmitPost(requestParameters: CodingSubmissionApiCodingSubmissionResubmitPostRequest = {}, options?: any) {
        return CodingSubmissionApiFp(this.configuration).codingSubmissionResubmitPost(requestParameters.findParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodingSubmissionApiCodingSubmissionSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodingSubmissionApi
     */
    public codingSubmissionSearchGet(requestParameters: CodingSubmissionApiCodingSubmissionSearchGetRequest, options?: any) {
        return CodingSubmissionApiFp(this.configuration).codingSubmissionSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.codingQuestionId, requestParameters.submitterId, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodingSubmissionApiCodingSubmissionSubmitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodingSubmissionApi
     */
    public codingSubmissionSubmitPost(requestParameters: CodingSubmissionApiCodingSubmissionSubmitPostRequest = {}, options?: any) {
        return CodingSubmissionApiFp(this.configuration).codingSubmissionSubmitPost(requestParameters.submitCodingParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseApi - axios parameter creator
 * @export
 */
export const CourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateLessonArticle} [updateLessonArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseAddArticlePost: async (updateLessonArticle?: UpdateLessonArticle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/AddArticle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLessonCodingQuestion} [updateLessonCodingQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseAddCodingQuestionPost: async (updateLessonCodingQuestion?: UpdateLessonCodingQuestion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/AddCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonCodingQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddLessonParams} [addLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseAddLessonPost: async (addLessonParams?: AddLessonParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/AddLesson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLessonParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteLessonParams} [deleteLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteLessonPost: async (deleteLessonParams?: DeleteLessonParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/DeleteLesson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteLessonParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseFindGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLessonArticle} [updateLessonArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseRemoveArticlePost: async (updateLessonArticle?: UpdateLessonArticle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/RemoveArticle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLessonCodingQuestion} [updateLessonCodingQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseRemoveCodingQuestionPost: async (updateLessonCodingQuestion?: UpdateLessonCodingQuestion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/RemoveCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonCodingQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveCourseParams} [saveCourseParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSavePost: async (saveCourseParams?: SaveCourseParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCourseParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSearchGet: async (pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('courseSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('courseSearchGet', 'ps', ps)
            const localVarPath = `/Course/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CourseSetGroupParams} [courseSetGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSetGroupsPost: async (courseSetGroupParams?: CourseSetGroupParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/SetGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseSetGroupParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLessonParams} [updateLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdateLessonPost: async (updateLessonParams?: UpdateLessonParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Course/UpdateLesson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateLessonArticle} [updateLessonArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseAddArticlePost(updateLessonArticle?: UpdateLessonArticle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseLessonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseAddArticlePost(updateLessonArticle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLessonCodingQuestion} [updateLessonCodingQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseAddCodingQuestionPost(updateLessonCodingQuestion?: UpdateLessonCodingQuestion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseLessonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseAddCodingQuestionPost(updateLessonCodingQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddLessonParams} [addLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseAddLessonPost(addLessonParams?: AddLessonParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseAddLessonPost(addLessonParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteLessonParams} [deleteLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDeleteLessonPost(deleteLessonParams?: DeleteLessonParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseDeleteLessonPost(deleteLessonParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseFindGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLessonArticle} [updateLessonArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseRemoveArticlePost(updateLessonArticle?: UpdateLessonArticle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseLessonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseRemoveArticlePost(updateLessonArticle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLessonCodingQuestion} [updateLessonCodingQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseRemoveCodingQuestionPost(updateLessonCodingQuestion?: UpdateLessonCodingQuestion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseLessonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseRemoveCodingQuestionPost(updateLessonCodingQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveCourseParams} [saveCourseParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseSavePost(saveCourseParams?: SaveCourseParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseSavePost(saveCourseParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseSearchGet(pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseSearchGet(pi, ps, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CourseSetGroupParams} [courseSetGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseSetGroupsPost(courseSetGroupParams?: CourseSetGroupParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseSetGroupsPost(courseSetGroupParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLessonParams} [updateLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseUpdateLessonPost(updateLessonParams?: UpdateLessonParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseLessonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseUpdateLessonPost(updateLessonParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateLessonArticle} [updateLessonArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseAddArticlePost(updateLessonArticle?: UpdateLessonArticle, options?: any): AxiosPromise<CourseLessonDto> {
            return localVarFp.courseAddArticlePost(updateLessonArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLessonCodingQuestion} [updateLessonCodingQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseAddCodingQuestionPost(updateLessonCodingQuestion?: UpdateLessonCodingQuestion, options?: any): AxiosPromise<CourseLessonDto> {
            return localVarFp.courseAddCodingQuestionPost(updateLessonCodingQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddLessonParams} [addLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseAddLessonPost(addLessonParams?: AddLessonParams, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.courseAddLessonPost(addLessonParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteLessonParams} [deleteLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDeleteLessonPost(deleteLessonParams?: DeleteLessonParams, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.courseDeleteLessonPost(deleteLessonParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseFindGet(id?: number, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.courseFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLessonArticle} [updateLessonArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseRemoveArticlePost(updateLessonArticle?: UpdateLessonArticle, options?: any): AxiosPromise<CourseLessonDto> {
            return localVarFp.courseRemoveArticlePost(updateLessonArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLessonCodingQuestion} [updateLessonCodingQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseRemoveCodingQuestionPost(updateLessonCodingQuestion?: UpdateLessonCodingQuestion, options?: any): AxiosPromise<CourseLessonDto> {
            return localVarFp.courseRemoveCodingQuestionPost(updateLessonCodingQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveCourseParams} [saveCourseParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSavePost(saveCourseParams?: SaveCourseParams, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.courseSavePost(saveCourseParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSearchGet(pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<CourseDtoSearchResponseDto> {
            return localVarFp.courseSearchGet(pi, ps, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CourseSetGroupParams} [courseSetGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseSetGroupsPost(courseSetGroupParams?: CourseSetGroupParams, options?: any): AxiosPromise<CourseDto> {
            return localVarFp.courseSetGroupsPost(courseSetGroupParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLessonParams} [updateLessonParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdateLessonPost(updateLessonParams?: UpdateLessonParams, options?: any): AxiosPromise<CourseLessonDto> {
            return localVarFp.courseUpdateLessonPost(updateLessonParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for courseAddArticlePost operation in CourseApi.
 * @export
 * @interface CourseApiCourseAddArticlePostRequest
 */
export interface CourseApiCourseAddArticlePostRequest {
    /**
     * 
     * @type {UpdateLessonArticle}
     * @memberof CourseApiCourseAddArticlePost
     */
    readonly updateLessonArticle?: UpdateLessonArticle
}

/**
 * Request parameters for courseAddCodingQuestionPost operation in CourseApi.
 * @export
 * @interface CourseApiCourseAddCodingQuestionPostRequest
 */
export interface CourseApiCourseAddCodingQuestionPostRequest {
    /**
     * 
     * @type {UpdateLessonCodingQuestion}
     * @memberof CourseApiCourseAddCodingQuestionPost
     */
    readonly updateLessonCodingQuestion?: UpdateLessonCodingQuestion
}

/**
 * Request parameters for courseAddLessonPost operation in CourseApi.
 * @export
 * @interface CourseApiCourseAddLessonPostRequest
 */
export interface CourseApiCourseAddLessonPostRequest {
    /**
     * 
     * @type {AddLessonParams}
     * @memberof CourseApiCourseAddLessonPost
     */
    readonly addLessonParams?: AddLessonParams
}

/**
 * Request parameters for courseDeleteLessonPost operation in CourseApi.
 * @export
 * @interface CourseApiCourseDeleteLessonPostRequest
 */
export interface CourseApiCourseDeleteLessonPostRequest {
    /**
     * 
     * @type {DeleteLessonParams}
     * @memberof CourseApiCourseDeleteLessonPost
     */
    readonly deleteLessonParams?: DeleteLessonParams
}

/**
 * Request parameters for courseFindGet operation in CourseApi.
 * @export
 * @interface CourseApiCourseFindGetRequest
 */
export interface CourseApiCourseFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CourseApiCourseFindGet
     */
    readonly id?: number
}

/**
 * Request parameters for courseRemoveArticlePost operation in CourseApi.
 * @export
 * @interface CourseApiCourseRemoveArticlePostRequest
 */
export interface CourseApiCourseRemoveArticlePostRequest {
    /**
     * 
     * @type {UpdateLessonArticle}
     * @memberof CourseApiCourseRemoveArticlePost
     */
    readonly updateLessonArticle?: UpdateLessonArticle
}

/**
 * Request parameters for courseRemoveCodingQuestionPost operation in CourseApi.
 * @export
 * @interface CourseApiCourseRemoveCodingQuestionPostRequest
 */
export interface CourseApiCourseRemoveCodingQuestionPostRequest {
    /**
     * 
     * @type {UpdateLessonCodingQuestion}
     * @memberof CourseApiCourseRemoveCodingQuestionPost
     */
    readonly updateLessonCodingQuestion?: UpdateLessonCodingQuestion
}

/**
 * Request parameters for courseSavePost operation in CourseApi.
 * @export
 * @interface CourseApiCourseSavePostRequest
 */
export interface CourseApiCourseSavePostRequest {
    /**
     * 
     * @type {SaveCourseParams}
     * @memberof CourseApiCourseSavePost
     */
    readonly saveCourseParams?: SaveCourseParams
}

/**
 * Request parameters for courseSearchGet operation in CourseApi.
 * @export
 * @interface CourseApiCourseSearchGetRequest
 */
export interface CourseApiCourseSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CourseApiCourseSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof CourseApiCourseSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof CourseApiCourseSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof CourseApiCourseSearchGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof CourseApiCourseSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof CourseApiCourseSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for courseSetGroupsPost operation in CourseApi.
 * @export
 * @interface CourseApiCourseSetGroupsPostRequest
 */
export interface CourseApiCourseSetGroupsPostRequest {
    /**
     * 
     * @type {CourseSetGroupParams}
     * @memberof CourseApiCourseSetGroupsPost
     */
    readonly courseSetGroupParams?: CourseSetGroupParams
}

/**
 * Request parameters for courseUpdateLessonPost operation in CourseApi.
 * @export
 * @interface CourseApiCourseUpdateLessonPostRequest
 */
export interface CourseApiCourseUpdateLessonPostRequest {
    /**
     * 
     * @type {UpdateLessonParams}
     * @memberof CourseApiCourseUpdateLessonPost
     */
    readonly updateLessonParams?: UpdateLessonParams
}

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @param {CourseApiCourseAddArticlePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseAddArticlePost(requestParameters: CourseApiCourseAddArticlePostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseAddArticlePost(requestParameters.updateLessonArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseAddCodingQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseAddCodingQuestionPost(requestParameters: CourseApiCourseAddCodingQuestionPostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseAddCodingQuestionPost(requestParameters.updateLessonCodingQuestion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseAddLessonPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseAddLessonPost(requestParameters: CourseApiCourseAddLessonPostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseAddLessonPost(requestParameters.addLessonParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseDeleteLessonPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDeleteLessonPost(requestParameters: CourseApiCourseDeleteLessonPostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseDeleteLessonPost(requestParameters.deleteLessonParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseFindGet(requestParameters: CourseApiCourseFindGetRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseRemoveArticlePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseRemoveArticlePost(requestParameters: CourseApiCourseRemoveArticlePostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseRemoveArticlePost(requestParameters.updateLessonArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseRemoveCodingQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseRemoveCodingQuestionPost(requestParameters: CourseApiCourseRemoveCodingQuestionPostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseRemoveCodingQuestionPost(requestParameters.updateLessonCodingQuestion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseSavePost(requestParameters: CourseApiCourseSavePostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseSavePost(requestParameters.saveCourseParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseSearchGet(requestParameters: CourseApiCourseSearchGetRequest, options?: any) {
        return CourseApiFp(this.configuration).courseSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseSetGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseSetGroupsPost(requestParameters: CourseApiCourseSetGroupsPostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseSetGroupsPost(requestParameters.courseSetGroupParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseApiCourseUpdateLessonPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseUpdateLessonPost(requestParameters: CourseApiCourseUpdateLessonPostRequest = {}, options?: any) {
        return CourseApiFp(this.configuration).courseUpdateLessonPost(requestParameters.updateLessonParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseArticleApi - axios parameter creator
 * @export
 */
export const CourseArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseArticleFindGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CourseArticle/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveCourseArticleParams} [saveCourseArticleParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseArticleSavePost: async (saveCourseArticleParams?: SaveCourseArticleParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CourseArticle/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCourseArticleParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseArticleSearchGet: async (pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('courseArticleSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('courseArticleSearchGet', 'ps', ps)
            const localVarPath = `/CourseArticle/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseArticleApi - functional programming interface
 * @export
 */
export const CourseArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseArticleFindGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseArticleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseArticleFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveCourseArticleParams} [saveCourseArticleParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseArticleSavePost(saveCourseArticleParams?: SaveCourseArticleParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseArticleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseArticleSavePost(saveCourseArticleParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseArticleSearchGet(pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseArticleDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseArticleSearchGet(pi, ps, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseArticleApi - factory interface
 * @export
 */
export const CourseArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseArticleFindGet(id?: number, options?: any): AxiosPromise<CourseArticleDto> {
            return localVarFp.courseArticleFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveCourseArticleParams} [saveCourseArticleParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseArticleSavePost(saveCourseArticleParams?: SaveCourseArticleParams, options?: any): AxiosPromise<CourseArticleDto> {
            return localVarFp.courseArticleSavePost(saveCourseArticleParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseArticleSearchGet(pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<CourseArticleDtoSearchResponseDto> {
            return localVarFp.courseArticleSearchGet(pi, ps, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for courseArticleFindGet operation in CourseArticleApi.
 * @export
 * @interface CourseArticleApiCourseArticleFindGetRequest
 */
export interface CourseArticleApiCourseArticleFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CourseArticleApiCourseArticleFindGet
     */
    readonly id?: number
}

/**
 * Request parameters for courseArticleSavePost operation in CourseArticleApi.
 * @export
 * @interface CourseArticleApiCourseArticleSavePostRequest
 */
export interface CourseArticleApiCourseArticleSavePostRequest {
    /**
     * 
     * @type {SaveCourseArticleParams}
     * @memberof CourseArticleApiCourseArticleSavePost
     */
    readonly saveCourseArticleParams?: SaveCourseArticleParams
}

/**
 * Request parameters for courseArticleSearchGet operation in CourseArticleApi.
 * @export
 * @interface CourseArticleApiCourseArticleSearchGetRequest
 */
export interface CourseArticleApiCourseArticleSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CourseArticleApiCourseArticleSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof CourseArticleApiCourseArticleSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof CourseArticleApiCourseArticleSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof CourseArticleApiCourseArticleSearchGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof CourseArticleApiCourseArticleSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof CourseArticleApiCourseArticleSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * CourseArticleApi - object-oriented interface
 * @export
 * @class CourseArticleApi
 * @extends {BaseAPI}
 */
export class CourseArticleApi extends BaseAPI {
    /**
     * 
     * @param {CourseArticleApiCourseArticleFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseArticleApi
     */
    public courseArticleFindGet(requestParameters: CourseArticleApiCourseArticleFindGetRequest = {}, options?: any) {
        return CourseArticleApiFp(this.configuration).courseArticleFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseArticleApiCourseArticleSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseArticleApi
     */
    public courseArticleSavePost(requestParameters: CourseArticleApiCourseArticleSavePostRequest = {}, options?: any) {
        return CourseArticleApiFp(this.configuration).courseArticleSavePost(requestParameters.saveCourseArticleParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseArticleApiCourseArticleSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseArticleApi
     */
    public courseArticleSearchGet(requestParameters: CourseArticleApiCourseArticleSearchGetRequest, options?: any) {
        return CourseArticleApiFp(this.configuration).courseArticleSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataPointApi - axios parameter creator
 * @export
 */
export const DataPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddAspirinDataPointParams} [addAspirinDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointAddAspirinDataPointPost: async (addAspirinDataPointParams?: AddAspirinDataPointParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataPoint/AddAspirinDataPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAspirinDataPointParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [file] 
         * @param {number} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointAddPost: async (file?: Array<any>, questionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataPoint/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (questionId !== undefined) { 
                localVarFormParams.append('questionId', questionId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchDeleteDataPointParams} [batchDeleteDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointBatchDeletePost: async (batchDeleteDataPointParams?: BatchDeleteDataPointParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataPoint/BatchDelete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchDeleteDataPointParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchUpdateDataPointParams} [batchUpdateDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointBatchUpdatePost: async (batchUpdateDataPointParams?: BatchUpdateDataPointParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataPoint/BatchUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchUpdateDataPointParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointDownloadDataPointsGet: async (questionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('dataPointDownloadDataPointsGet', 'questionId', questionId)
            const localVarPath = `/DataPoint/DownloadDataPoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['QuestionId'] = questionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointListGet: async (questionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('dataPointListGet', 'questionId', questionId)
            const localVarPath = `/DataPoint/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['QuestionId'] = questionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDataPointTaskNameParams} [updateDataPointTaskNameParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointUpdateTaskNamePost: async (updateDataPointTaskNameParams?: UpdateDataPointTaskNameParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataPoint/UpdateTaskName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataPointTaskNameParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTaskParams} [updateTaskParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointUpdateTaskPost: async (updateTaskParams?: UpdateTaskParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataPoint/UpdateTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataPointApi - functional programming interface
 * @export
 */
export const DataPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataPointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddAspirinDataPointParams} [addAspirinDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointAddAspirinDataPointPost(addAspirinDataPointParams?: AddAspirinDataPointParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointAddAspirinDataPointPost(addAspirinDataPointParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} [file] 
         * @param {number} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointAddPost(file?: Array<any>, questionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointAddPost(file, questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BatchDeleteDataPointParams} [batchDeleteDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointBatchDeletePost(batchDeleteDataPointParams?: BatchDeleteDataPointParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointBatchDeletePost(batchDeleteDataPointParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BatchUpdateDataPointParams} [batchUpdateDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointBatchUpdatePost(batchUpdateDataPointParams?: BatchUpdateDataPointParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataPointDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointBatchUpdatePost(batchUpdateDataPointParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointDownloadDataPointsGet(questionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointDownloadDataPointsGet(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointListGet(questionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataPointDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointListGet(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDataPointTaskNameParams} [updateDataPointTaskNameParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointUpdateTaskNamePost(updateDataPointTaskNameParams?: UpdateDataPointTaskNameParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataPointDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointUpdateTaskNamePost(updateDataPointTaskNameParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTaskParams} [updateTaskParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPointUpdateTaskPost(updateTaskParams?: UpdateTaskParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataPointDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPointUpdateTaskPost(updateTaskParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataPointApi - factory interface
 * @export
 */
export const DataPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataPointApiFp(configuration)
    return {
        /**
         * 
         * @param {AddAspirinDataPointParams} [addAspirinDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointAddAspirinDataPointPost(addAspirinDataPointParams?: AddAspirinDataPointParams, options?: any): AxiosPromise<void> {
            return localVarFp.dataPointAddAspirinDataPointPost(addAspirinDataPointParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} [file] 
         * @param {number} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointAddPost(file?: Array<any>, questionId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.dataPointAddPost(file, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchDeleteDataPointParams} [batchDeleteDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointBatchDeletePost(batchDeleteDataPointParams?: BatchDeleteDataPointParams, options?: any): AxiosPromise<void> {
            return localVarFp.dataPointBatchDeletePost(batchDeleteDataPointParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchUpdateDataPointParams} [batchUpdateDataPointParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointBatchUpdatePost(batchUpdateDataPointParams?: BatchUpdateDataPointParams, options?: any): AxiosPromise<Array<DataPointDto>> {
            return localVarFp.dataPointBatchUpdatePost(batchUpdateDataPointParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointDownloadDataPointsGet(questionId: number, options?: any): AxiosPromise<string> {
            return localVarFp.dataPointDownloadDataPointsGet(questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointListGet(questionId: number, options?: any): AxiosPromise<Array<DataPointDto>> {
            return localVarFp.dataPointListGet(questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDataPointTaskNameParams} [updateDataPointTaskNameParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointUpdateTaskNamePost(updateDataPointTaskNameParams?: UpdateDataPointTaskNameParams, options?: any): AxiosPromise<Array<DataPointDto>> {
            return localVarFp.dataPointUpdateTaskNamePost(updateDataPointTaskNameParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTaskParams} [updateTaskParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPointUpdateTaskPost(updateTaskParams?: UpdateTaskParams, options?: any): AxiosPromise<Array<DataPointDto>> {
            return localVarFp.dataPointUpdateTaskPost(updateTaskParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dataPointAddAspirinDataPointPost operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointAddAspirinDataPointPostRequest
 */
export interface DataPointApiDataPointAddAspirinDataPointPostRequest {
    /**
     * 
     * @type {AddAspirinDataPointParams}
     * @memberof DataPointApiDataPointAddAspirinDataPointPost
     */
    readonly addAspirinDataPointParams?: AddAspirinDataPointParams
}

/**
 * Request parameters for dataPointAddPost operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointAddPostRequest
 */
export interface DataPointApiDataPointAddPostRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof DataPointApiDataPointAddPost
     */
    readonly file?: Array<any>

    /**
     * 
     * @type {number}
     * @memberof DataPointApiDataPointAddPost
     */
    readonly questionId?: number
}

/**
 * Request parameters for dataPointBatchDeletePost operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointBatchDeletePostRequest
 */
export interface DataPointApiDataPointBatchDeletePostRequest {
    /**
     * 
     * @type {BatchDeleteDataPointParams}
     * @memberof DataPointApiDataPointBatchDeletePost
     */
    readonly batchDeleteDataPointParams?: BatchDeleteDataPointParams
}

/**
 * Request parameters for dataPointBatchUpdatePost operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointBatchUpdatePostRequest
 */
export interface DataPointApiDataPointBatchUpdatePostRequest {
    /**
     * 
     * @type {BatchUpdateDataPointParams}
     * @memberof DataPointApiDataPointBatchUpdatePost
     */
    readonly batchUpdateDataPointParams?: BatchUpdateDataPointParams
}

/**
 * Request parameters for dataPointDownloadDataPointsGet operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointDownloadDataPointsGetRequest
 */
export interface DataPointApiDataPointDownloadDataPointsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DataPointApiDataPointDownloadDataPointsGet
     */
    readonly questionId: number
}

/**
 * Request parameters for dataPointListGet operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointListGetRequest
 */
export interface DataPointApiDataPointListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DataPointApiDataPointListGet
     */
    readonly questionId: number
}

/**
 * Request parameters for dataPointUpdateTaskNamePost operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointUpdateTaskNamePostRequest
 */
export interface DataPointApiDataPointUpdateTaskNamePostRequest {
    /**
     * 
     * @type {UpdateDataPointTaskNameParams}
     * @memberof DataPointApiDataPointUpdateTaskNamePost
     */
    readonly updateDataPointTaskNameParams?: UpdateDataPointTaskNameParams
}

/**
 * Request parameters for dataPointUpdateTaskPost operation in DataPointApi.
 * @export
 * @interface DataPointApiDataPointUpdateTaskPostRequest
 */
export interface DataPointApiDataPointUpdateTaskPostRequest {
    /**
     * 
     * @type {UpdateTaskParams}
     * @memberof DataPointApiDataPointUpdateTaskPost
     */
    readonly updateTaskParams?: UpdateTaskParams
}

/**
 * DataPointApi - object-oriented interface
 * @export
 * @class DataPointApi
 * @extends {BaseAPI}
 */
export class DataPointApi extends BaseAPI {
    /**
     * 
     * @param {DataPointApiDataPointAddAspirinDataPointPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointAddAspirinDataPointPost(requestParameters: DataPointApiDataPointAddAspirinDataPointPostRequest = {}, options?: any) {
        return DataPointApiFp(this.configuration).dataPointAddAspirinDataPointPost(requestParameters.addAspirinDataPointParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointAddPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointAddPost(requestParameters: DataPointApiDataPointAddPostRequest = {}, options?: any) {
        return DataPointApiFp(this.configuration).dataPointAddPost(requestParameters.file, requestParameters.questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointBatchDeletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointBatchDeletePost(requestParameters: DataPointApiDataPointBatchDeletePostRequest = {}, options?: any) {
        return DataPointApiFp(this.configuration).dataPointBatchDeletePost(requestParameters.batchDeleteDataPointParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointBatchUpdatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointBatchUpdatePost(requestParameters: DataPointApiDataPointBatchUpdatePostRequest = {}, options?: any) {
        return DataPointApiFp(this.configuration).dataPointBatchUpdatePost(requestParameters.batchUpdateDataPointParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointDownloadDataPointsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointDownloadDataPointsGet(requestParameters: DataPointApiDataPointDownloadDataPointsGetRequest, options?: any) {
        return DataPointApiFp(this.configuration).dataPointDownloadDataPointsGet(requestParameters.questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointListGet(requestParameters: DataPointApiDataPointListGetRequest, options?: any) {
        return DataPointApiFp(this.configuration).dataPointListGet(requestParameters.questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointUpdateTaskNamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointUpdateTaskNamePost(requestParameters: DataPointApiDataPointUpdateTaskNamePostRequest = {}, options?: any) {
        return DataPointApiFp(this.configuration).dataPointUpdateTaskNamePost(requestParameters.updateDataPointTaskNameParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataPointApiDataPointUpdateTaskPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataPointApi
     */
    public dataPointUpdateTaskPost(requestParameters: DataPointApiDataPointUpdateTaskPostRequest = {}, options?: any) {
        return DataPointApiFp(this.configuration).dataPointUpdateTaskPost(requestParameters.updateTaskParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExamApi - axios parameter creator
 * @export
 */
export const ExamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} examId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examAnalyseCodingQuestionGet: async (examId: number, groupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('examAnalyseCodingQuestionGet', 'examId', examId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('examAnalyseCodingQuestionGet', 'groupId', groupId)
            const localVarPath = `/Exam/AnalyseCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} examId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examAnalyseGet: async (examId: number, groupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'examId' is not null or undefined
            assertParamExists('examAnalyseGet', 'examId', examId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('examAnalyseGet', 'groupId', groupId)
            const localVarPath = `/Exam/Analyse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExamBatchAutoMarkSubmissionParams} [examBatchAutoMarkSubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examBatchAutoMarkSubmissionPost: async (examBatchAutoMarkSubmissionParams?: ExamBatchAutoMarkSubmissionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/BatchAutoMarkSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examBatchAutoMarkSubmissionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExamSubmissionCreateParams} [examSubmissionCreateParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examCreateSubmissionPost: async (examSubmissionCreateParams?: ExamSubmissionCreateParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/CreateSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examSubmissionCreateParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examDeleteSubmissionPost: async (findParams?: FindParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/DeleteSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examFindGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examFindSubmissionGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/FindSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExamMarkSubmissionParams} [examMarkSubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examMarkSubmissionPost: async (examMarkSubmissionParams?: ExamMarkSubmissionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/MarkSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examMarkSubmissionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveExamParams} [saveExamParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSavePost: async (saveExamParams?: SaveExamParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveExamParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExamSubmissionSaveParams} [examSubmissionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSaveSubmissionPost: async (examSubmissionSaveParams?: ExamSubmissionSaveParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/SaveSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examSubmissionSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSearchGet: async (pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('examSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('examSearchGet', 'ps', ps)
            const localVarPath = `/Exam/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [groupId] 
         * @param {number} [userId] 
         * @param {number} [examId] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSearchSubmissionGet: async (pi: number, ps: number, groupId?: number, userId?: number, examId?: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('examSearchSubmissionGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('examSearchSubmissionGet', 'ps', ps)
            const localVarPath = `/Exam/SearchSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (examId !== undefined) {
                localVarQueryParameter['ExamId'] = examId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExamSetGroupParams} [examSetGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSetGroupsPost: async (examSetGroupParams?: ExamSetGroupParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/SetGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examSetGroupParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSubmitSubmissionPost: async (findParams?: FindParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Exam/SubmitSubmission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamApi - functional programming interface
 * @export
 */
export const ExamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} examId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examAnalyseCodingQuestionGet(examId: number, groupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamAnalyseCodingQuestionItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examAnalyseCodingQuestionGet(examId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} examId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examAnalyseGet(examId: number, groupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExamAnalyseQuestionItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examAnalyseGet(examId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExamBatchAutoMarkSubmissionParams} [examBatchAutoMarkSubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examBatchAutoMarkSubmissionPost(examBatchAutoMarkSubmissionParams?: ExamBatchAutoMarkSubmissionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examBatchAutoMarkSubmissionPost(examBatchAutoMarkSubmissionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExamSubmissionCreateParams} [examSubmissionCreateParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examCreateSubmissionPost(examSubmissionCreateParams?: ExamSubmissionCreateParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examCreateSubmissionPost(examSubmissionCreateParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examDeleteSubmissionPost(findParams?: FindParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examDeleteSubmissionPost(findParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examFindGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examFindSubmissionGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examFindSubmissionGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExamMarkSubmissionParams} [examMarkSubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examMarkSubmissionPost(examMarkSubmissionParams?: ExamMarkSubmissionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examMarkSubmissionPost(examMarkSubmissionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveExamParams} [saveExamParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSavePost(saveExamParams?: SaveExamParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSavePost(saveExamParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExamSubmissionSaveParams} [examSubmissionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSaveSubmissionPost(examSubmissionSaveParams?: ExamSubmissionSaveParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSaveSubmissionPost(examSubmissionSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSearchGet(pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSearchGet(pi, ps, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [groupId] 
         * @param {number} [userId] 
         * @param {number} [examId] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSearchSubmissionGet(pi: number, ps: number, groupId?: number, userId?: number, examId?: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamSubmissionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSearchSubmissionGet(pi, ps, groupId, userId, examId, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExamSetGroupParams} [examSetGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSetGroupsPost(examSetGroupParams?: ExamSetGroupParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSetGroupsPost(examSetGroupParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSubmitSubmissionPost(findParams?: FindParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamSubmissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSubmitSubmissionPost(findParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExamApi - factory interface
 * @export
 */
export const ExamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamApiFp(configuration)
    return {
        /**
         * 
         * @param {number} examId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examAnalyseCodingQuestionGet(examId: number, groupId: number, options?: any): AxiosPromise<Array<ExamAnalyseCodingQuestionItemDto>> {
            return localVarFp.examAnalyseCodingQuestionGet(examId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} examId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examAnalyseGet(examId: number, groupId: number, options?: any): AxiosPromise<Array<ExamAnalyseQuestionItem>> {
            return localVarFp.examAnalyseGet(examId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExamBatchAutoMarkSubmissionParams} [examBatchAutoMarkSubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examBatchAutoMarkSubmissionPost(examBatchAutoMarkSubmissionParams?: ExamBatchAutoMarkSubmissionParams, options?: any): AxiosPromise<void> {
            return localVarFp.examBatchAutoMarkSubmissionPost(examBatchAutoMarkSubmissionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExamSubmissionCreateParams} [examSubmissionCreateParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examCreateSubmissionPost(examSubmissionCreateParams?: ExamSubmissionCreateParams, options?: any): AxiosPromise<ExamSubmissionDto> {
            return localVarFp.examCreateSubmissionPost(examSubmissionCreateParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examDeleteSubmissionPost(findParams?: FindParams, options?: any): AxiosPromise<void> {
            return localVarFp.examDeleteSubmissionPost(findParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examFindGet(id?: number, options?: any): AxiosPromise<ExamDto> {
            return localVarFp.examFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examFindSubmissionGet(id?: number, options?: any): AxiosPromise<ExamSubmissionDto> {
            return localVarFp.examFindSubmissionGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExamMarkSubmissionParams} [examMarkSubmissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examMarkSubmissionPost(examMarkSubmissionParams?: ExamMarkSubmissionParams, options?: any): AxiosPromise<ExamSubmissionDto> {
            return localVarFp.examMarkSubmissionPost(examMarkSubmissionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveExamParams} [saveExamParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSavePost(saveExamParams?: SaveExamParams, options?: any): AxiosPromise<ExamDto> {
            return localVarFp.examSavePost(saveExamParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExamSubmissionSaveParams} [examSubmissionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSaveSubmissionPost(examSubmissionSaveParams?: ExamSubmissionSaveParams, options?: any): AxiosPromise<ExamSubmissionDto> {
            return localVarFp.examSaveSubmissionPost(examSubmissionSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSearchGet(pi: number, ps: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<ExamDtoSearchResponseDto> {
            return localVarFp.examSearchGet(pi, ps, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [groupId] 
         * @param {number} [userId] 
         * @param {number} [examId] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSearchSubmissionGet(pi: number, ps: number, groupId?: number, userId?: number, examId?: number, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<ExamSubmissionDtoSearchResponseDto> {
            return localVarFp.examSearchSubmissionGet(pi, ps, groupId, userId, examId, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExamSetGroupParams} [examSetGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSetGroupsPost(examSetGroupParams?: ExamSetGroupParams, options?: any): AxiosPromise<ExamDto> {
            return localVarFp.examSetGroupsPost(examSetGroupParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSubmitSubmissionPost(findParams?: FindParams, options?: any): AxiosPromise<ExamSubmissionDto> {
            return localVarFp.examSubmitSubmissionPost(findParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for examAnalyseCodingQuestionGet operation in ExamApi.
 * @export
 * @interface ExamApiExamAnalyseCodingQuestionGetRequest
 */
export interface ExamApiExamAnalyseCodingQuestionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamAnalyseCodingQuestionGet
     */
    readonly examId: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamAnalyseCodingQuestionGet
     */
    readonly groupId: number
}

/**
 * Request parameters for examAnalyseGet operation in ExamApi.
 * @export
 * @interface ExamApiExamAnalyseGetRequest
 */
export interface ExamApiExamAnalyseGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamAnalyseGet
     */
    readonly examId: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamAnalyseGet
     */
    readonly groupId: number
}

/**
 * Request parameters for examBatchAutoMarkSubmissionPost operation in ExamApi.
 * @export
 * @interface ExamApiExamBatchAutoMarkSubmissionPostRequest
 */
export interface ExamApiExamBatchAutoMarkSubmissionPostRequest {
    /**
     * 
     * @type {ExamBatchAutoMarkSubmissionParams}
     * @memberof ExamApiExamBatchAutoMarkSubmissionPost
     */
    readonly examBatchAutoMarkSubmissionParams?: ExamBatchAutoMarkSubmissionParams
}

/**
 * Request parameters for examCreateSubmissionPost operation in ExamApi.
 * @export
 * @interface ExamApiExamCreateSubmissionPostRequest
 */
export interface ExamApiExamCreateSubmissionPostRequest {
    /**
     * 
     * @type {ExamSubmissionCreateParams}
     * @memberof ExamApiExamCreateSubmissionPost
     */
    readonly examSubmissionCreateParams?: ExamSubmissionCreateParams
}

/**
 * Request parameters for examDeleteSubmissionPost operation in ExamApi.
 * @export
 * @interface ExamApiExamDeleteSubmissionPostRequest
 */
export interface ExamApiExamDeleteSubmissionPostRequest {
    /**
     * 
     * @type {FindParams}
     * @memberof ExamApiExamDeleteSubmissionPost
     */
    readonly findParams?: FindParams
}

/**
 * Request parameters for examFindGet operation in ExamApi.
 * @export
 * @interface ExamApiExamFindGetRequest
 */
export interface ExamApiExamFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamFindGet
     */
    readonly id?: number
}

/**
 * Request parameters for examFindSubmissionGet operation in ExamApi.
 * @export
 * @interface ExamApiExamFindSubmissionGetRequest
 */
export interface ExamApiExamFindSubmissionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamFindSubmissionGet
     */
    readonly id?: number
}

/**
 * Request parameters for examMarkSubmissionPost operation in ExamApi.
 * @export
 * @interface ExamApiExamMarkSubmissionPostRequest
 */
export interface ExamApiExamMarkSubmissionPostRequest {
    /**
     * 
     * @type {ExamMarkSubmissionParams}
     * @memberof ExamApiExamMarkSubmissionPost
     */
    readonly examMarkSubmissionParams?: ExamMarkSubmissionParams
}

/**
 * Request parameters for examSavePost operation in ExamApi.
 * @export
 * @interface ExamApiExamSavePostRequest
 */
export interface ExamApiExamSavePostRequest {
    /**
     * 
     * @type {SaveExamParams}
     * @memberof ExamApiExamSavePost
     */
    readonly saveExamParams?: SaveExamParams
}

/**
 * Request parameters for examSaveSubmissionPost operation in ExamApi.
 * @export
 * @interface ExamApiExamSaveSubmissionPostRequest
 */
export interface ExamApiExamSaveSubmissionPostRequest {
    /**
     * 
     * @type {ExamSubmissionSaveParams}
     * @memberof ExamApiExamSaveSubmissionPost
     */
    readonly examSubmissionSaveParams?: ExamSubmissionSaveParams
}

/**
 * Request parameters for examSearchGet operation in ExamApi.
 * @export
 * @interface ExamApiExamSearchGetRequest
 */
export interface ExamApiExamSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamSearchGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof ExamApiExamSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiExamSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for examSearchSubmissionGet operation in ExamApi.
 * @export
 * @interface ExamApiExamSearchSubmissionGetRequest
 */
export interface ExamApiExamSearchSubmissionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly groupId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly userId?: number

    /**
     * 
     * @type {number}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly examId?: number

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof ExamApiExamSearchSubmissionGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for examSetGroupsPost operation in ExamApi.
 * @export
 * @interface ExamApiExamSetGroupsPostRequest
 */
export interface ExamApiExamSetGroupsPostRequest {
    /**
     * 
     * @type {ExamSetGroupParams}
     * @memberof ExamApiExamSetGroupsPost
     */
    readonly examSetGroupParams?: ExamSetGroupParams
}

/**
 * Request parameters for examSubmitSubmissionPost operation in ExamApi.
 * @export
 * @interface ExamApiExamSubmitSubmissionPostRequest
 */
export interface ExamApiExamSubmitSubmissionPostRequest {
    /**
     * 
     * @type {FindParams}
     * @memberof ExamApiExamSubmitSubmissionPost
     */
    readonly findParams?: FindParams
}

/**
 * ExamApi - object-oriented interface
 * @export
 * @class ExamApi
 * @extends {BaseAPI}
 */
export class ExamApi extends BaseAPI {
    /**
     * 
     * @param {ExamApiExamAnalyseCodingQuestionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examAnalyseCodingQuestionGet(requestParameters: ExamApiExamAnalyseCodingQuestionGetRequest, options?: any) {
        return ExamApiFp(this.configuration).examAnalyseCodingQuestionGet(requestParameters.examId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamAnalyseGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examAnalyseGet(requestParameters: ExamApiExamAnalyseGetRequest, options?: any) {
        return ExamApiFp(this.configuration).examAnalyseGet(requestParameters.examId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamBatchAutoMarkSubmissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examBatchAutoMarkSubmissionPost(requestParameters: ExamApiExamBatchAutoMarkSubmissionPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examBatchAutoMarkSubmissionPost(requestParameters.examBatchAutoMarkSubmissionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamCreateSubmissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examCreateSubmissionPost(requestParameters: ExamApiExamCreateSubmissionPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examCreateSubmissionPost(requestParameters.examSubmissionCreateParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamDeleteSubmissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examDeleteSubmissionPost(requestParameters: ExamApiExamDeleteSubmissionPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examDeleteSubmissionPost(requestParameters.findParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examFindGet(requestParameters: ExamApiExamFindGetRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamFindSubmissionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examFindSubmissionGet(requestParameters: ExamApiExamFindSubmissionGetRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examFindSubmissionGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamMarkSubmissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examMarkSubmissionPost(requestParameters: ExamApiExamMarkSubmissionPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examMarkSubmissionPost(requestParameters.examMarkSubmissionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSavePost(requestParameters: ExamApiExamSavePostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examSavePost(requestParameters.saveExamParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSaveSubmissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSaveSubmissionPost(requestParameters: ExamApiExamSaveSubmissionPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examSaveSubmissionPost(requestParameters.examSubmissionSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSearchGet(requestParameters: ExamApiExamSearchGetRequest, options?: any) {
        return ExamApiFp(this.configuration).examSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSearchSubmissionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSearchSubmissionGet(requestParameters: ExamApiExamSearchSubmissionGetRequest, options?: any) {
        return ExamApiFp(this.configuration).examSearchSubmissionGet(requestParameters.pi, requestParameters.ps, requestParameters.groupId, requestParameters.userId, requestParameters.examId, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSetGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSetGroupsPost(requestParameters: ExamApiExamSetGroupsPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examSetGroupsPost(requestParameters.examSetGroupParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamApiExamSubmitSubmissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public examSubmitSubmissionPost(requestParameters: ExamApiExamSubmitSubmissionPostRequest = {}, options?: any) {
        return ExamApiFp(this.configuration).examSubmitSubmissionPost(requestParameters.findParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaperApi - axios parameter creator
 * @export
 */
export const PaperApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CopyPaperParams} [copyPaperParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperCopyPost: async (copyPaperParams?: CopyPaperParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Paper/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyPaperParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperFindGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Paper/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Paper/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SavePaperParams} [savePaperParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperSavePost: async (savePaperParams?: SavePaperParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Paper/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePaperParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaperApi - functional programming interface
 * @export
 */
export const PaperApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaperApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CopyPaperParams} [copyPaperParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paperCopyPost(copyPaperParams?: CopyPaperParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paperCopyPost(copyPaperParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paperFindGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paperFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paperListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaperDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paperListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SavePaperParams} [savePaperParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paperSavePost(savePaperParams?: SavePaperParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paperSavePost(savePaperParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaperApi - factory interface
 * @export
 */
export const PaperApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaperApiFp(configuration)
    return {
        /**
         * 
         * @param {CopyPaperParams} [copyPaperParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperCopyPost(copyPaperParams?: CopyPaperParams, options?: any): AxiosPromise<PaperDto> {
            return localVarFp.paperCopyPost(copyPaperParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperFindGet(id?: number, options?: any): AxiosPromise<PaperDto> {
            return localVarFp.paperFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperListGet(options?: any): AxiosPromise<Array<PaperDto>> {
            return localVarFp.paperListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SavePaperParams} [savePaperParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paperSavePost(savePaperParams?: SavePaperParams, options?: any): AxiosPromise<PaperDto> {
            return localVarFp.paperSavePost(savePaperParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for paperCopyPost operation in PaperApi.
 * @export
 * @interface PaperApiPaperCopyPostRequest
 */
export interface PaperApiPaperCopyPostRequest {
    /**
     * 
     * @type {CopyPaperParams}
     * @memberof PaperApiPaperCopyPost
     */
    readonly copyPaperParams?: CopyPaperParams
}

/**
 * Request parameters for paperFindGet operation in PaperApi.
 * @export
 * @interface PaperApiPaperFindGetRequest
 */
export interface PaperApiPaperFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PaperApiPaperFindGet
     */
    readonly id?: number
}

/**
 * Request parameters for paperSavePost operation in PaperApi.
 * @export
 * @interface PaperApiPaperSavePostRequest
 */
export interface PaperApiPaperSavePostRequest {
    /**
     * 
     * @type {SavePaperParams}
     * @memberof PaperApiPaperSavePost
     */
    readonly savePaperParams?: SavePaperParams
}

/**
 * PaperApi - object-oriented interface
 * @export
 * @class PaperApi
 * @extends {BaseAPI}
 */
export class PaperApi extends BaseAPI {
    /**
     * 
     * @param {PaperApiPaperCopyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaperApi
     */
    public paperCopyPost(requestParameters: PaperApiPaperCopyPostRequest = {}, options?: any) {
        return PaperApiFp(this.configuration).paperCopyPost(requestParameters.copyPaperParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaperApiPaperFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaperApi
     */
    public paperFindGet(requestParameters: PaperApiPaperFindGetRequest = {}, options?: any) {
        return PaperApiFp(this.configuration).paperFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaperApi
     */
    public paperListGet(options?: any) {
        return PaperApiFp(this.configuration).paperListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaperApiPaperSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaperApi
     */
    public paperSavePost(requestParameters: PaperApiPaperSavePostRequest = {}, options?: any) {
        return PaperApiFp(this.configuration).paperSavePost(requestParameters.savePaperParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [clusterId] 
         * @param {boolean} [hasDataPoints] 
         * @param {boolean} [hasCourses] 
         * @param {boolean} [hasPapers] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAdvanceSearchCodingQuestionGet: async (pi: number, ps: number, clusterId?: number, hasDataPoints?: boolean, hasCourses?: boolean, hasPapers?: boolean, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('questionAdvanceSearchCodingQuestionGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('questionAdvanceSearchCodingQuestionGet', 'ps', ps)
            const localVarPath = `/Question/AdvanceSearchCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clusterId !== undefined) {
                localVarQueryParameter['ClusterId'] = clusterId;
            }

            if (hasDataPoints !== undefined) {
                localVarQueryParameter['HasDataPoints'] = hasDataPoints;
            }

            if (hasCourses !== undefined) {
                localVarQueryParameter['HasCourses'] = hasCourses;
            }

            if (hasPapers !== undefined) {
                localVarQueryParameter['HasPapers'] = hasPapers;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCopyCodingQuestionPost: async (findParams?: FindParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/CopyCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionFindCodingQuestionByAspirinIdGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/FindCodingQuestionByAspirinId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionFindCodingQuestionGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/FindCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveChoiceQuestionParams} [saveChoiceQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveChoiceQuestionPost: async (saveChoiceQuestionParams?: SaveChoiceQuestionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/SaveChoiceQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveChoiceQuestionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveCodingQuestionParams} [saveCodingQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveCodingQuestionPost: async (saveCodingQuestionParams?: SaveCodingQuestionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/SaveCodingQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCodingQuestionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveEssayQuestionParams} [saveEssayQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveEssayQuestionPost: async (saveEssayQuestionParams?: SaveEssayQuestionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/SaveEssayQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveEssayQuestionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveFillQuestionParams} [saveFillQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveFillQuestionPost: async (saveFillQuestionParams?: SaveFillQuestionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/SaveFillQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveFillQuestionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveOperateQuestionParams} [saveOperateQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveOperateQuestionPost: async (saveOperateQuestionParams?: SaveOperateQuestionParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Question/SaveOperateQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOperateQuestionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {QuestionType} [type] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSearchQuestionGet: async (pi: number, ps: number, type?: QuestionType, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('questionSearchQuestionGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('questionSearchQuestionGet', 'ps', ps)
            const localVarPath = `/Question/SearchQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [clusterId] 
         * @param {boolean} [hasDataPoints] 
         * @param {boolean} [hasCourses] 
         * @param {boolean} [hasPapers] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAdvanceSearchCodingQuestionGet(pi: number, ps: number, clusterId?: number, hasDataPoints?: boolean, hasCourses?: boolean, hasPapers?: boolean, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingQuestionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAdvanceSearchCodingQuestionGet(pi, ps, clusterId, hasDataPoints, hasCourses, hasPapers, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionCopyCodingQuestionPost(findParams?: FindParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionCopyCodingQuestionPost(findParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionFindCodingQuestionByAspirinIdGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionFindCodingQuestionByAspirinIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionFindCodingQuestionGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionFindCodingQuestionGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveChoiceQuestionParams} [saveChoiceQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionSaveChoiceQuestionPost(saveChoiceQuestionParams?: SaveChoiceQuestionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChoiceQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionSaveChoiceQuestionPost(saveChoiceQuestionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveCodingQuestionParams} [saveCodingQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionSaveCodingQuestionPost(saveCodingQuestionParams?: SaveCodingQuestionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodingQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionSaveCodingQuestionPost(saveCodingQuestionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveEssayQuestionParams} [saveEssayQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionSaveEssayQuestionPost(saveEssayQuestionParams?: SaveEssayQuestionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssayQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionSaveEssayQuestionPost(saveEssayQuestionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveFillQuestionParams} [saveFillQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionSaveFillQuestionPost(saveFillQuestionParams?: SaveFillQuestionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FillQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionSaveFillQuestionPost(saveFillQuestionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveOperateQuestionParams} [saveOperateQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionSaveOperateQuestionPost(saveOperateQuestionParams?: SaveOperateQuestionParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperateQuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionSaveOperateQuestionPost(saveOperateQuestionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {QuestionType} [type] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionSearchQuestionGet(pi: number, ps: number, type?: QuestionType, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionSearchQuestionGet(pi, ps, type, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [clusterId] 
         * @param {boolean} [hasDataPoints] 
         * @param {boolean} [hasCourses] 
         * @param {boolean} [hasPapers] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAdvanceSearchCodingQuestionGet(pi: number, ps: number, clusterId?: number, hasDataPoints?: boolean, hasCourses?: boolean, hasPapers?: boolean, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<CodingQuestionDtoSearchResponseDto> {
            return localVarFp.questionAdvanceSearchCodingQuestionGet(pi, ps, clusterId, hasDataPoints, hasCourses, hasPapers, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCopyCodingQuestionPost(findParams?: FindParams, options?: any): AxiosPromise<CodingQuestionDto> {
            return localVarFp.questionCopyCodingQuestionPost(findParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionFindCodingQuestionByAspirinIdGet(id?: number, options?: any): AxiosPromise<CodingQuestionDto> {
            return localVarFp.questionFindCodingQuestionByAspirinIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionFindCodingQuestionGet(id?: number, options?: any): AxiosPromise<CodingQuestionDto> {
            return localVarFp.questionFindCodingQuestionGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveChoiceQuestionParams} [saveChoiceQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveChoiceQuestionPost(saveChoiceQuestionParams?: SaveChoiceQuestionParams, options?: any): AxiosPromise<ChoiceQuestionDto> {
            return localVarFp.questionSaveChoiceQuestionPost(saveChoiceQuestionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveCodingQuestionParams} [saveCodingQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveCodingQuestionPost(saveCodingQuestionParams?: SaveCodingQuestionParams, options?: any): AxiosPromise<CodingQuestionDto> {
            return localVarFp.questionSaveCodingQuestionPost(saveCodingQuestionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveEssayQuestionParams} [saveEssayQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveEssayQuestionPost(saveEssayQuestionParams?: SaveEssayQuestionParams, options?: any): AxiosPromise<EssayQuestionDto> {
            return localVarFp.questionSaveEssayQuestionPost(saveEssayQuestionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveFillQuestionParams} [saveFillQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveFillQuestionPost(saveFillQuestionParams?: SaveFillQuestionParams, options?: any): AxiosPromise<FillQuestionDto> {
            return localVarFp.questionSaveFillQuestionPost(saveFillQuestionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveOperateQuestionParams} [saveOperateQuestionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSaveOperateQuestionPost(saveOperateQuestionParams?: SaveOperateQuestionParams, options?: any): AxiosPromise<OperateQuestionDto> {
            return localVarFp.questionSaveOperateQuestionPost(saveOperateQuestionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {QuestionType} [type] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionSearchQuestionGet(pi: number, ps: number, type?: QuestionType, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<QuestionDtoSearchResponseDto> {
            return localVarFp.questionSearchQuestionGet(pi, ps, type, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for questionAdvanceSearchCodingQuestionGet operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest
 */
export interface QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly clusterId?: number

    /**
     * 
     * @type {boolean}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly hasDataPoints?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly hasCourses?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly hasPapers?: boolean

    /**
     * 
     * @type {string}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof QuestionApiQuestionAdvanceSearchCodingQuestionGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for questionCopyCodingQuestionPost operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionCopyCodingQuestionPostRequest
 */
export interface QuestionApiQuestionCopyCodingQuestionPostRequest {
    /**
     * 
     * @type {FindParams}
     * @memberof QuestionApiQuestionCopyCodingQuestionPost
     */
    readonly findParams?: FindParams
}

/**
 * Request parameters for questionFindCodingQuestionByAspirinIdGet operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionFindCodingQuestionByAspirinIdGetRequest
 */
export interface QuestionApiQuestionFindCodingQuestionByAspirinIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionFindCodingQuestionByAspirinIdGet
     */
    readonly id?: number
}

/**
 * Request parameters for questionFindCodingQuestionGet operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionFindCodingQuestionGetRequest
 */
export interface QuestionApiQuestionFindCodingQuestionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionFindCodingQuestionGet
     */
    readonly id?: number
}

/**
 * Request parameters for questionSaveChoiceQuestionPost operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionSaveChoiceQuestionPostRequest
 */
export interface QuestionApiQuestionSaveChoiceQuestionPostRequest {
    /**
     * 
     * @type {SaveChoiceQuestionParams}
     * @memberof QuestionApiQuestionSaveChoiceQuestionPost
     */
    readonly saveChoiceQuestionParams?: SaveChoiceQuestionParams
}

/**
 * Request parameters for questionSaveCodingQuestionPost operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionSaveCodingQuestionPostRequest
 */
export interface QuestionApiQuestionSaveCodingQuestionPostRequest {
    /**
     * 
     * @type {SaveCodingQuestionParams}
     * @memberof QuestionApiQuestionSaveCodingQuestionPost
     */
    readonly saveCodingQuestionParams?: SaveCodingQuestionParams
}

/**
 * Request parameters for questionSaveEssayQuestionPost operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionSaveEssayQuestionPostRequest
 */
export interface QuestionApiQuestionSaveEssayQuestionPostRequest {
    /**
     * 
     * @type {SaveEssayQuestionParams}
     * @memberof QuestionApiQuestionSaveEssayQuestionPost
     */
    readonly saveEssayQuestionParams?: SaveEssayQuestionParams
}

/**
 * Request parameters for questionSaveFillQuestionPost operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionSaveFillQuestionPostRequest
 */
export interface QuestionApiQuestionSaveFillQuestionPostRequest {
    /**
     * 
     * @type {SaveFillQuestionParams}
     * @memberof QuestionApiQuestionSaveFillQuestionPost
     */
    readonly saveFillQuestionParams?: SaveFillQuestionParams
}

/**
 * Request parameters for questionSaveOperateQuestionPost operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionSaveOperateQuestionPostRequest
 */
export interface QuestionApiQuestionSaveOperateQuestionPostRequest {
    /**
     * 
     * @type {SaveOperateQuestionParams}
     * @memberof QuestionApiQuestionSaveOperateQuestionPost
     */
    readonly saveOperateQuestionParams?: SaveOperateQuestionParams
}

/**
 * Request parameters for questionSearchQuestionGet operation in QuestionApi.
 * @export
 * @interface QuestionApiQuestionSearchQuestionGetRequest
 */
export interface QuestionApiQuestionSearchQuestionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly ps: number

    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly type?: QuestionType

    /**
     * 
     * @type {string}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof QuestionApiQuestionSearchQuestionGet
     */
    readonly isDelete?: boolean
}

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * 
     * @param {QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionAdvanceSearchCodingQuestionGet(requestParameters: QuestionApiQuestionAdvanceSearchCodingQuestionGetRequest, options?: any) {
        return QuestionApiFp(this.configuration).questionAdvanceSearchCodingQuestionGet(requestParameters.pi, requestParameters.ps, requestParameters.clusterId, requestParameters.hasDataPoints, requestParameters.hasCourses, requestParameters.hasPapers, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionCopyCodingQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionCopyCodingQuestionPost(requestParameters: QuestionApiQuestionCopyCodingQuestionPostRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionCopyCodingQuestionPost(requestParameters.findParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionFindCodingQuestionByAspirinIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionFindCodingQuestionByAspirinIdGet(requestParameters: QuestionApiQuestionFindCodingQuestionByAspirinIdGetRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionFindCodingQuestionByAspirinIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionFindCodingQuestionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionFindCodingQuestionGet(requestParameters: QuestionApiQuestionFindCodingQuestionGetRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionFindCodingQuestionGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionSaveChoiceQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionSaveChoiceQuestionPost(requestParameters: QuestionApiQuestionSaveChoiceQuestionPostRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionSaveChoiceQuestionPost(requestParameters.saveChoiceQuestionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionSaveCodingQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionSaveCodingQuestionPost(requestParameters: QuestionApiQuestionSaveCodingQuestionPostRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionSaveCodingQuestionPost(requestParameters.saveCodingQuestionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionSaveEssayQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionSaveEssayQuestionPost(requestParameters: QuestionApiQuestionSaveEssayQuestionPostRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionSaveEssayQuestionPost(requestParameters.saveEssayQuestionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionSaveFillQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionSaveFillQuestionPost(requestParameters: QuestionApiQuestionSaveFillQuestionPostRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionSaveFillQuestionPost(requestParameters.saveFillQuestionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionSaveOperateQuestionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionSaveOperateQuestionPost(requestParameters: QuestionApiQuestionSaveOperateQuestionPostRequest = {}, options?: any) {
        return QuestionApiFp(this.configuration).questionSaveOperateQuestionPost(requestParameters.saveOperateQuestionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QuestionApiQuestionSearchQuestionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionSearchQuestionGet(requestParameters: QuestionApiQuestionSearchQuestionGetRequest, options?: any) {
        return QuestionApiFp(this.configuration).questionSearchQuestionGet(requestParameters.pi, requestParameters.ps, requestParameters.type, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemDeleteNoticePost: async (findParams?: FindParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/DeleteNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemFindNoticeGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/FindNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemListAllNoticeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/ListAllNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemListNoticeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/ListNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveNoticeParams} [saveNoticeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemSaveNoticePost: async (saveNoticeParams?: SaveNoticeParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/SaveNotice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveNoticeParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemDeleteNoticePost(findParams?: FindParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemDeleteNoticePost(findParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemFindNoticeGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemFindNoticeGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemListAllNoticeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemListAllNoticeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemListNoticeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemListNoticeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveNoticeParams} [saveNoticeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemSaveNoticePost(saveNoticeParams?: SaveNoticeParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemSaveNoticePost(saveNoticeParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @param {FindParams} [findParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemDeleteNoticePost(findParams?: FindParams, options?: any): AxiosPromise<void> {
            return localVarFp.systemDeleteNoticePost(findParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemFindNoticeGet(id?: number, options?: any): AxiosPromise<NoticeDto> {
            return localVarFp.systemFindNoticeGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemListAllNoticeGet(options?: any): AxiosPromise<Array<NoticeDto>> {
            return localVarFp.systemListAllNoticeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemListNoticeGet(options?: any): AxiosPromise<Array<NoticeDto>> {
            return localVarFp.systemListNoticeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveNoticeParams} [saveNoticeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemSaveNoticePost(saveNoticeParams?: SaveNoticeParams, options?: any): AxiosPromise<NoticeDto> {
            return localVarFp.systemSaveNoticePost(saveNoticeParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for systemDeleteNoticePost operation in SystemApi.
 * @export
 * @interface SystemApiSystemDeleteNoticePostRequest
 */
export interface SystemApiSystemDeleteNoticePostRequest {
    /**
     * 
     * @type {FindParams}
     * @memberof SystemApiSystemDeleteNoticePost
     */
    readonly findParams?: FindParams
}

/**
 * Request parameters for systemFindNoticeGet operation in SystemApi.
 * @export
 * @interface SystemApiSystemFindNoticeGetRequest
 */
export interface SystemApiSystemFindNoticeGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SystemApiSystemFindNoticeGet
     */
    readonly id?: number
}

/**
 * Request parameters for systemSaveNoticePost operation in SystemApi.
 * @export
 * @interface SystemApiSystemSaveNoticePostRequest
 */
export interface SystemApiSystemSaveNoticePostRequest {
    /**
     * 
     * @type {SaveNoticeParams}
     * @memberof SystemApiSystemSaveNoticePost
     */
    readonly saveNoticeParams?: SaveNoticeParams
}

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {SystemApiSystemDeleteNoticePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemDeleteNoticePost(requestParameters: SystemApiSystemDeleteNoticePostRequest = {}, options?: any) {
        return SystemApiFp(this.configuration).systemDeleteNoticePost(requestParameters.findParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SystemApiSystemFindNoticeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemFindNoticeGet(requestParameters: SystemApiSystemFindNoticeGetRequest = {}, options?: any) {
        return SystemApiFp(this.configuration).systemFindNoticeGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemListAllNoticeGet(options?: any) {
        return SystemApiFp(this.configuration).systemListAllNoticeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemListNoticeGet(options?: any) {
        return SystemApiFp(this.configuration).systemListNoticeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SystemApiSystemSaveNoticePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemSaveNoticePost(requestParameters: SystemApiSystemSaveNoticePostRequest = {}, options?: any) {
        return SystemApiFp(this.configuration).systemSaveNoticePost(requestParameters.saveNoticeParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdjustGroupStudentParams} [adjustGroupStudentParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddGroupStudentPost: async (adjustGroupStudentParams?: AdjustGroupStudentParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/AddGroupStudent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adjustGroupStudentParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindGroupGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/FindGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetStudentExcelTemplateGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/GetStudentExcelTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userImportStudentPost: async (contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/ImportStudent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListGroupGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/ListGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListGroupStudentGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/ListGroupStudent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdjustGroupStudentParams} [adjustGroupStudentParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRemoveGroupStudentPost: async (adjustGroupStudentParams?: AdjustGroupStudentParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/RemoveGroupStudent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adjustGroupStudentParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveGroupParams} [saveGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSaveGroupPost: async (saveGroupParams?: SaveGroupParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/SaveGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveGroupParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSaveParams} [userSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavePost: async (userSaveParams?: UserSaveParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {UserRole} [userRole] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchGet: async (pi: number, ps: number, userRole?: UserRole, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('userSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('userSearchGet', 'ps', ps)
            const localVarPath = `/User/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userRole !== undefined) {
                localVarQueryParameter['UserRole'] = userRole;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (keywords !== undefined) {
                localVarQueryParameter['Keywords'] = keywords;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdatePasswordParams} [userUpdatePasswordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePasswordPost: async (userUpdatePasswordParams?: UserUpdatePasswordParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatePasswordParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadFilePost: async (contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/UploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadImagePost: async (contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/UploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdjustGroupStudentParams} [adjustGroupStudentParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddGroupStudentPost(adjustGroupStudentParams?: AdjustGroupStudentParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddGroupStudentPost(adjustGroupStudentParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFindGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFindGroupGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFindGroupGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetStudentExcelTemplateGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExcelTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetStudentExcelTemplateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userImportStudentPost(contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userImportStudentPost(contentType, contentDisposition, length, name, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userListGroupGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userListGroupGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userListGroupStudentGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userListGroupStudentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdjustGroupStudentParams} [adjustGroupStudentParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRemoveGroupStudentPost(adjustGroupStudentParams?: AdjustGroupStudentParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRemoveGroupStudentPost(adjustGroupStudentParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveGroupParams} [saveGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSaveGroupPost(saveGroupParams?: SaveGroupParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSaveGroupPost(saveGroupParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSaveParams} [userSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSavePost(userSaveParams?: UserSaveParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSavePost(userSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {UserRole} [userRole] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchGet(pi: number, ps: number, userRole?: UserRole, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchGet(pi, ps, userRole, sorterKey, keywords, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdatePasswordParams} [userUpdatePasswordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePasswordPost(userUpdatePasswordParams?: UserUpdatePasswordParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdatePasswordPost(userUpdatePasswordParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUploadFilePost(contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUploadFilePost(contentType, contentDisposition, length, name, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUploadImagePost(contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUploadImagePost(contentType, contentDisposition, length, name, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {AdjustGroupStudentParams} [adjustGroupStudentParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddGroupStudentPost(adjustGroupStudentParams?: AdjustGroupStudentParams, options?: any): AxiosPromise<void> {
            return localVarFp.userAddGroupStudentPost(adjustGroupStudentParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindGet(id?: number, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindGroupGet(id?: number, options?: any): AxiosPromise<GroupDto> {
            return localVarFp.userFindGroupGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetStudentExcelTemplateGet(options?: any): AxiosPromise<GetExcelTemplateResponse> {
            return localVarFp.userGetStudentExcelTemplateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userImportStudentPost(contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<ExcelImportResponse> {
            return localVarFp.userImportStudentPost(contentType, contentDisposition, length, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListGroupGet(options?: any): AxiosPromise<Array<GroupDto>> {
            return localVarFp.userListGroupGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userListGroupStudentGet(id?: number, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.userListGroupStudentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdjustGroupStudentParams} [adjustGroupStudentParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRemoveGroupStudentPost(adjustGroupStudentParams?: AdjustGroupStudentParams, options?: any): AxiosPromise<void> {
            return localVarFp.userRemoveGroupStudentPost(adjustGroupStudentParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveGroupParams} [saveGroupParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSaveGroupPost(saveGroupParams?: SaveGroupParams, options?: any): AxiosPromise<GroupDto> {
            return localVarFp.userSaveGroupPost(saveGroupParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSaveParams} [userSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavePost(userSaveParams?: UserSaveParams, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userSavePost(userSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {UserRole} [userRole] 
         * @param {string} [sorterKey] 
         * @param {string} [keywords] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchGet(pi: number, ps: number, userRole?: UserRole, sorterKey?: string, keywords?: string, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<UserDtoSearchResponseDto> {
            return localVarFp.userSearchGet(pi, ps, userRole, sorterKey, keywords, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserUpdatePasswordParams} [userUpdatePasswordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePasswordPost(userUpdatePasswordParams?: UserUpdatePasswordParams, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userUpdatePasswordPost(userUpdatePasswordParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadFilePost(contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.userUploadFilePost(contentType, contentDisposition, length, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUploadImagePost(contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.userUploadImagePost(contentType, contentDisposition, length, name, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userAddGroupStudentPost operation in UserApi.
 * @export
 * @interface UserApiUserAddGroupStudentPostRequest
 */
export interface UserApiUserAddGroupStudentPostRequest {
    /**
     * 
     * @type {AdjustGroupStudentParams}
     * @memberof UserApiUserAddGroupStudentPost
     */
    readonly adjustGroupStudentParams?: AdjustGroupStudentParams
}

/**
 * Request parameters for userFindGet operation in UserApi.
 * @export
 * @interface UserApiUserFindGetRequest
 */
export interface UserApiUserFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserFindGet
     */
    readonly id?: number
}

/**
 * Request parameters for userFindGroupGet operation in UserApi.
 * @export
 * @interface UserApiUserFindGroupGetRequest
 */
export interface UserApiUserFindGroupGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserFindGroupGet
     */
    readonly id?: number
}

/**
 * Request parameters for userImportStudentPost operation in UserApi.
 * @export
 * @interface UserApiUserImportStudentPostRequest
 */
export interface UserApiUserImportStudentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserImportStudentPost
     */
    readonly contentType?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserImportStudentPost
     */
    readonly contentDisposition?: string

    /**
     * 
     * @type {number}
     * @memberof UserApiUserImportStudentPost
     */
    readonly length?: number

    /**
     * 
     * @type {string}
     * @memberof UserApiUserImportStudentPost
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserImportStudentPost
     */
    readonly fileName?: string
}

/**
 * Request parameters for userListGroupStudentGet operation in UserApi.
 * @export
 * @interface UserApiUserListGroupStudentGetRequest
 */
export interface UserApiUserListGroupStudentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserListGroupStudentGet
     */
    readonly id?: number
}

/**
 * Request parameters for userRemoveGroupStudentPost operation in UserApi.
 * @export
 * @interface UserApiUserRemoveGroupStudentPostRequest
 */
export interface UserApiUserRemoveGroupStudentPostRequest {
    /**
     * 
     * @type {AdjustGroupStudentParams}
     * @memberof UserApiUserRemoveGroupStudentPost
     */
    readonly adjustGroupStudentParams?: AdjustGroupStudentParams
}

/**
 * Request parameters for userSaveGroupPost operation in UserApi.
 * @export
 * @interface UserApiUserSaveGroupPostRequest
 */
export interface UserApiUserSaveGroupPostRequest {
    /**
     * 
     * @type {SaveGroupParams}
     * @memberof UserApiUserSaveGroupPost
     */
    readonly saveGroupParams?: SaveGroupParams
}

/**
 * Request parameters for userSavePost operation in UserApi.
 * @export
 * @interface UserApiUserSavePostRequest
 */
export interface UserApiUserSavePostRequest {
    /**
     * 
     * @type {UserSaveParams}
     * @memberof UserApiUserSavePost
     */
    readonly userSaveParams?: UserSaveParams
}

/**
 * Request parameters for userSearchGet operation in UserApi.
 * @export
 * @interface UserApiUserSearchGetRequest
 */
export interface UserApiUserSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof UserApiUserSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {UserRole}
     * @memberof UserApiUserSearchGet
     */
    readonly userRole?: UserRole

    /**
     * 
     * @type {string}
     * @memberof UserApiUserSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserSearchGet
     */
    readonly keywords?: string

    /**
     * 
     * @type {SorterOrder}
     * @memberof UserApiUserSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof UserApiUserSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for userUpdatePasswordPost operation in UserApi.
 * @export
 * @interface UserApiUserUpdatePasswordPostRequest
 */
export interface UserApiUserUpdatePasswordPostRequest {
    /**
     * 
     * @type {UserUpdatePasswordParams}
     * @memberof UserApiUserUpdatePasswordPost
     */
    readonly userUpdatePasswordParams?: UserUpdatePasswordParams
}

/**
 * Request parameters for userUploadFilePost operation in UserApi.
 * @export
 * @interface UserApiUserUploadFilePostRequest
 */
export interface UserApiUserUploadFilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadFilePost
     */
    readonly contentType?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadFilePost
     */
    readonly contentDisposition?: string

    /**
     * 
     * @type {number}
     * @memberof UserApiUserUploadFilePost
     */
    readonly length?: number

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadFilePost
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadFilePost
     */
    readonly fileName?: string
}

/**
 * Request parameters for userUploadImagePost operation in UserApi.
 * @export
 * @interface UserApiUserUploadImagePostRequest
 */
export interface UserApiUserUploadImagePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadImagePost
     */
    readonly contentType?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadImagePost
     */
    readonly contentDisposition?: string

    /**
     * 
     * @type {number}
     * @memberof UserApiUserUploadImagePost
     */
    readonly length?: number

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadImagePost
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserUploadImagePost
     */
    readonly fileName?: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiUserAddGroupStudentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAddGroupStudentPost(requestParameters: UserApiUserAddGroupStudentPostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userAddGroupStudentPost(requestParameters.adjustGroupStudentParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userFindGet(requestParameters: UserApiUserFindGetRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserFindGroupGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userFindGroupGet(requestParameters: UserApiUserFindGroupGetRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userFindGroupGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetStudentExcelTemplateGet(options?: any) {
        return UserApiFp(this.configuration).userGetStudentExcelTemplateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserImportStudentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userImportStudentPost(requestParameters: UserApiUserImportStudentPostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userImportStudentPost(requestParameters.contentType, requestParameters.contentDisposition, requestParameters.length, requestParameters.name, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userListGroupGet(options?: any) {
        return UserApiFp(this.configuration).userListGroupGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserListGroupStudentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userListGroupStudentGet(requestParameters: UserApiUserListGroupStudentGetRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userListGroupStudentGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserRemoveGroupStudentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRemoveGroupStudentPost(requestParameters: UserApiUserRemoveGroupStudentPostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userRemoveGroupStudentPost(requestParameters.adjustGroupStudentParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserSaveGroupPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSaveGroupPost(requestParameters: UserApiUserSaveGroupPostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userSaveGroupPost(requestParameters.saveGroupParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSavePost(requestParameters: UserApiUserSavePostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userSavePost(requestParameters.userSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSearchGet(requestParameters: UserApiUserSearchGetRequest, options?: any) {
        return UserApiFp(this.configuration).userSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.userRole, requestParameters.sorterKey, requestParameters.keywords, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUpdatePasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePasswordPost(requestParameters: UserApiUserUpdatePasswordPostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userUpdatePasswordPost(requestParameters.userUpdatePasswordParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUploadFilePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUploadFilePost(requestParameters: UserApiUserUploadFilePostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userUploadFilePost(requestParameters.contentType, requestParameters.contentDisposition, requestParameters.length, requestParameters.name, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUploadImagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUploadImagePost(requestParameters: UserApiUserUploadImagePostRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userUploadImagePost(requestParameters.contentType, requestParameters.contentDisposition, requestParameters.length, requestParameters.name, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }
}


