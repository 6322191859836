import React, {useEffect} from 'react';
import {Button, Checkbox, Form, Input, message, Radio} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import {ChoiceQuestionDto, QuestionApi, UserApi, UserDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useList} from 'react-use';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import Flex from '../Shared/Flex';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

interface QuestionOption {
  content: string;
  isRight: boolean;
}
export default function ChoiceQuestionSaveForm(props: {exist?: ChoiceQuestionDto; onSuccess: () => any}) {
  const defaultOptions = [
    {content: '', isRight: true},
    {content: '', isRight: false},
    {content: '', isRight: false},
  ];
  const keyword = useInput('');
  const topic = useInput('');
  const isMultiple = useBoolean(false);
  const [options, optionsState] = useList<QuestionOption>(defaultOptions);
  const labels = 'ABCDEFGHIJKLMNOPQRST';
  useEffect(() => {
    if (props.exist) {
      keyword.setValue(props.exist.keywords || '');
      topic.setValue(props.exist.topic || '');
      isMultiple.setValue(!!props.exist.isMultiple);
      optionsState.set(props.exist.options?.map((opt, index) => ({content: opt, isRight: !!props.exist?.rightLabel?.includes(labels[index])})) || []);
    } else {
      keyword.clear();
      topic.clear();
      isMultiple.setFalse();
      optionsState.set(defaultOptions);
    }
  }, [props.exist]);

  const saveState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionSaveChoiceQuestionPost);

  function save() {
    saveState
      .request({
        saveChoiceQuestionParams: {
          id: props.exist?.id,
          keywords: keyword.value,
          topic: topic.value,
          isMultiple: isMultiple.value,
          options: options.map(i => i.content),
          rightLabel: options.map((opt, index) => (opt.isRight ? labels[index] : '')).join(''),
        },
      })
      .then(() => {
        message.success('保存成功');
        props.onSuccess();
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <Form layout={'vertical'}>
      <Form.Item label={'关键词（用于搜索）'}>
        <Input {...keyword.eventBind} placeholder={'请输入关键词，可以是知识点'} />
      </Form.Item>
      <Form.Item label={'题干'} required>
        <LiveExMdEditor {...topic.valueBind} placeholder={`请输入题干`} style={{background: '#EEEFF3', padding: '5px 15px'}} />
      </Form.Item>
      <Form.Item label={'题型'} required>
        <Radio.Group value={isMultiple.value} onChange={e => isMultiple.setValue(e.target.value)}>
          <Radio value={false}>单选</Radio>
          <Radio value={true}>多选</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={'选项'} required>
        <div className={'p-5 m-b-5'} style={{backgroundColor: 'rgba(122,232,125,0.2)'}}>
          {options.map((field, index) => (
            <Flex key={index} justify={'space-between'} align={'center'}>
              <Checkbox className={'m-r-5'} checked={field.isRight} onChange={e => optionsState.updateAt(index, {...field, isRight: e.target.checked})} />
              <strong className={'m-r-5'}>{labels[index]}.</strong>
              <Form.Item style={{marginBottom: 0, flex: 1}}>
                <LiveExMdEditor
                  value={field.content}
                  onChange={text => optionsState.updateAt(index, {...field, content: text})}
                  placeholder={`请输入选项`}
                  style={{background: 'rgba(255,255,255,0.6)', padding: '0 5px'}}
                />
              </Form.Item>
              <Button style={{flexShrink: 0}} type={'link'} danger disabled={index === 0} onClick={() => optionsState.removeAt(index)}>
                <DeleteOutlined />
                删除
              </Button>
            </Flex>
          ))}
          <Button onClick={() => optionsState.push({isRight: false, content: ''})} style={{marginTop: 5}}>
            <PlusOutlined /> 添加一个选项
          </Button>
        </div>
      </Form.Item>
      <Button type={'primary'} onClick={save} loading={saveState.loading}>
        保存
      </Button>
    </Form>
  );
}
