import React from 'react';
import {useParams} from 'react-router-dom';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import {QuestionApi, UserRole} from '../../../scaffold';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import {useMount} from 'react-use';
import {Button, Card, Divider, Drawer, PageHeader, Space} from 'antd';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import CodingQuestionContentSection from '../../../components/Question/CodingQuestionContentSection';
import CodingQuestionAnswerSection from '../../../components/Question/CodingQuestionAnswerSection';
import CodingQuestionSaveForm from '../../../components/Question/CodingQuestionSaveForm';
import {useBoolean} from 'react-hanger';
import useAuth from '../../../utils/AuthContext';
import ManageDataPointBtn from '../../../components/Question/ManageDataPointBtn';

export default function AppCodingQuestionDetailPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(QuestionApi, QuestionApi.prototype.questionFindCodingQuestionGet);
  const tabLayout = useTabLayout();
  const isDialogSave = useBoolean(false);
  function refresh() {
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle(res.topic || '题目详情');
      })
      .catch(CommonApiErrorHandler);
  }

  useMount(() => {
    refresh();
  });

  const auth = useAuth();

  return (
    <div>
      <PageHeader
        title={findState.data?.topic || '题目详情'}
        style={{background: 'white'}}
        extra={
          <Space>
            {auth.currentUser?.role && findState.data && [UserRole.管理员, UserRole.教师].includes(auth.currentUser.role) && (
              <React.Fragment>
                <Button onClick={isDialogSave.setTrue}>编辑</Button>
                <ManageDataPointBtn refresh={refresh} question={findState.data}>
                  <Button>数据管理</Button>
                </ManageDataPointBtn>
                <Button onClick={() => tabLayout.goTo('/manage/question/' + findState.data?.id + '/codingSubmission')}>提交记录</Button>
              </React.Fragment>
            )}
          </Space>
        }
      />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          {findState.data && (
            <div>
              <CodingQuestionContentSection question={findState.data} />
              <Divider />
              <CodingQuestionAnswerSection question={findState.data} />
            </div>
          )}
        </Card>
        <Drawer width={700} title={'编辑'} visible={isDialogSave.value} onClose={isDialogSave.setFalse}>
          <CodingQuestionSaveForm
            exist={findState.data}
            onSuccess={() => {
              refresh();
              isDialogSave.setFalse();
            }}
          />
        </Drawer>
      </div>
    </div>
  );
}
