import React from 'react';
import Text from 'antd/lib/typography/Text';
import {Button, Input} from 'antd';
import Search from 'antd/lib/input/Search';
import {useInput} from 'react-hanger';
import {CourseDto} from '../../scaffold';
import courseLock from '../../assets/course-lock.png';
export default function CourseLock(props: {isActive: boolean; course: CourseDto; refresh: () => any}) {
  const {isActive} = props;
  // const activateHook = CourseHook.useActivate();
  const code = useInput('');
  return (
    <React.Fragment>
      <div className={'LockTop ' + (isActive ? 'LockTopLeave' : '')}>
        <div className="text-center">
          <h1>{props.course.name}</h1>
          <Text type={'secondary'} style={{fontSize: 18}}>
            {props.course.description}
          </Text>
        </div>
        <div className="LockTopBar" />
        <img src={courseLock} alt="lock" className={'LockImg ' + (isActive ? 'LockImgOpened' : '')} />
      </div>
      <div className={`LockBottom ${isActive ? 'LockBottomLeave' : ''}`}>
        <div className="LockBottomBar" />
        <div className="LockBottomActiveArea">
          <Text>该课程尚未激活，无法查看</Text>
          {/*<Search*/}
          {/*  placeholder="请输入激活码或口令"*/}
          {/*  enterButton="激活"*/}
          {/*  size="large"*/}
          {/*  {...code.eventBind}*/}
          {/*  // onSearch={value =>*/}
          {/*  //   activateHook*/}
          {/*  //     .request({*/}
          {/*  //       courseId: props.course.id,*/}
          {/*  //       code: value,*/}
          {/*  //     })*/}
          {/*  //     .then(props.refresh)*/}
          {/*  //     .catch(CommonConsole)*/}
          {/*  // }*/}
          {/*  // loading={activateHook.loading}*/}
          {/*/>*/}
        </div>
      </div>
    </React.Fragment>
  );
}
