import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Checkbox, Divider, Form, Input, message, PageHeader, Popconfirm, Radio, Select, Space, Table, Tag, Tooltip} from 'antd';
import {ExamAnalyseCodingQuestionItemDto, ExamApi, ExamDto, ExamSubmissionDto} from '../../../scaffold';
import {useList, useMount} from 'react-use';
import {useOpenApiFpRequest} from '../../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../../utils/HttpInstance';
import {useParams} from 'react-router-dom';
import {useTabLayout} from '../../../TabLayout/TabLayoutProvider';
import QuestionDisplay from '../../../components/Question/QuestionDisplay';
import SearchAndRefresh from '../../../components/Shared/SearchAndRefresh';
import {useBoolean, useInput} from 'react-hanger';
import PaperKeywordFilter from '../../../utils/keywordFiliter/Paper';
import ExamSubmissionKeywordFilter from '../../../utils/keywordFiliter/ExamSubmission';
import ExamGroupSubmission from '../../../components/Exam/GroupSubmission';
import {CSVLink} from 'react-csv';

export default function ManageExamSubmissionPage() {
  const params = useParams() as {id: string} | undefined;
  const id = params?.id ? parseInt(params.id) : 0;
  const findState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examFindGet);
  const listState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examSearchSubmissionGet);
  const deleteState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examDeleteSubmissionPost);
  const tabLayout = useTabLayout();
  const [groupId, setGroupId] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<ExamSubmissionDto[]>([]);

  function refresh() {
    setSelectedRow([]);
    findState
      .request({id})
      .then(res => {
        tabLayout.setTitle('考试成绩:' + res.name);
        listState.requestSync({examId: id, groupId, pi: 1, ps: 9999});
      })
      .catch(CommonApiErrorHandler);
  }

  useEffect(() => {
    refresh();
  }, [groupId]);

  const keyword = useInput('');
  const displayRows = listState.data?.list?.filter(i => ExamSubmissionKeywordFilter(i, keyword.value));
  const autoMarkState = useOpenApiFpRequest(ExamApi, ExamApi.prototype.examBatchAutoMarkSubmissionPost);
  const groupDetail = useBoolean(false);

  function autoMark() {
    autoMarkState
      .request({examBatchAutoMarkSubmissionParams: {ids: selectedRow.map(i => i.id as number)}})
      .then(() => refresh())
      .catch(CommonApiErrorHandler);
  }

  const csvData = displayRows?.map(i => ({
    学号: i.student?.username,
    姓名: i.student?.name,
    成绩: i.scoreGet,
    是否批改: i.isMarked ? '已批改' : '待批改',
    进入考试时间: i.createdTime,
    交卷时间: i.submittedTime,
  }));

  return (
    <div>
      <PageHeader title={`考试成绩: ${findState.data?.name}`} style={{background: 'white'}} />
      <div className={'p-20'} style={{overflowY: 'auto'}}>
        <Card className={'m-b-15'} loading={findState.loading}>
          <Space>
            <Select value={groupId} onChange={setGroupId} placeholder={'筛选班级'} style={{width: 200}} allowClear>
              {findState.data?.groups?.map(g => (
                <Select.Option value={g.id as number} key={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
            <SearchAndRefresh {...keyword.valueBind} refresh={refresh} loading={listState.loading} />

            {selectedRow.length > 0 && (
              <Space>
                <Button onClick={autoMark} loading={autoMarkState.loading}>
                  机器批改
                </Button>
              </Space>
            )}
            <Checkbox checked={groupDetail.value} onChange={e => groupDetail.setValue(e.target.checked)}>
              班级面板
            </Checkbox>
            <CSVLink filename={'导出成绩'} data={csvData ?? []} headers={['学号', '姓名', '成绩', '是否批改', '进入考试时间', '交卷时间']}>
              <Button>导出 CSV</Button>
            </CSVLink>
          </Space>
          {!groupDetail.value && (
            <Table
              size={'small'}
              className={'m-t-10'}
              bordered
              dataSource={displayRows || []}
              rowKey={'id'}
              loading={listState.loading}
              pagination={{showSizeChanger: true}}
              rowSelection={{
                selectedRowKeys: selectedRow.map(i => i.id as number),
                onChange(_, rows) {
                  setSelectedRow(rows);
                },
              }}>
              <Table.Column title={'编号'} dataIndex={'id'} width={100} />
              <Table.Column title={'学生姓名'} dataIndex={['student', 'name']} />
              <Table.Column title={'学生学号'} dataIndex={['student', 'username']} />
              <Table.Column title={'成绩'} dataIndex={['scoreGet']} />
              <Table.Column<ExamSubmissionDto>
                title={'是否批改'}
                width={100}
                render={(_, item) => <Tag color={item.isMarked ? 'green' : 'default'}>{item.isMarked ? '已批改' : '待批改'}</Tag>}
              />
              <Table.Column<ExamSubmissionDto> title={'进入考试时间'} width={200} render={(_, item) => item?.createdTime} />
              <Table.Column<ExamSubmissionDto> title={'交卷时间'} width={200} render={(_, item) => item?.submittedTime} />
              <Table.Column<ExamSubmissionDto>
                title={'操作'}
                width={300}
                render={(_, item) => (
                  <Space>
                    <a onClick={() => tabLayout.goTo('/manage/exam/submission/result/' + item.id)}>查看答卷</a>
                    <a onClick={() => tabLayout.goTo('/manage/exam/submission/mark/' + item.id)}>批改答卷</a>
                    <Popconfirm
                      title={'确定要删除答卷吗？'}
                      onConfirm={() => {
                        deleteState
                          .request({findParams: {id: item.id || 0}})
                          .then(refresh)
                          .catch(CommonApiErrorHandler);
                      }}>
                      <a>删除答卷</a>
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
          )}
          {groupDetail.value && groupId && <ExamGroupSubmission groupId={groupId} submissionList={displayRows || []} />}
        </Card>
      </div>
    </div>
  );
}
