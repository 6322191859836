import React from 'react';
import {ChoiceQuestionDto, OperateQuestionDto, FillQuestionDto, QuestionDto} from '../../scaffold';
import MarkdownRender from '../Shared/MarkdownRender';
import Flex from '../Shared/Flex';
import Text from 'antd/lib/typography/Text';
import {IQuestionDisplayProps} from './QuestionDisplay';

export default function OperateQuestionDisplay(props: IQuestionDisplayProps<OperateQuestionDto>) {
  const {question} = props;
  return (
    <div>
      <MarkdownRender className={'smallMarkdown'} md={'**[操作题]** ' + question.topic || ''} />
    </div>
  );
}
