import React, {useState} from 'react';
import {Collapse} from 'react-collapse';
import {Progress, Space} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import {CourseLessonDto, CourseLessonItemDto, CourseLessonItemType} from '../../scaffold';
import Flex from '../Shared/Flex';
import CourseLessonDetailIcon from './DetailIcon';
import pass from '../../assets/pass.png';
import unpass from '../../assets/unpass.png';
export default function LessonItem(props: {
  lesson: CourseLessonDto;
  index: number;
  isBlur: boolean;
  goToDetail: (type: CourseLessonItemType, id: number) => any;
}) {
  const solvedPercent = props.lesson.totalCount ? ((props.lesson.solvedCount || 0) / props.lesson.totalCount) * 100 : 100;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`LessonListItem ${isOpen ? 'LessonListItemOpen' : ''}`}>
      <div className={'LessonListItemHead'} onClick={() => setIsOpen(!isOpen)}>
        <div className={'LessonListItemHeadTitle'}>
          <strong style={{marginRight: 35}}>第 {props.index} 章</strong>
          <span>{props.lesson.name}</span>
        </div>
        <Space size={40} align={'center'}>
          <Flex className={'LessonListItemPercent'} direction={'column'} align={'flex-end'}>
            <div style={{marginBottom: 5}}>
              <strong>{solvedPercent.toFixed()} %</strong>
            </div>
            <Progress percent={solvedPercent} showInfo={false} style={{width: 200}} width={200} strokeWidth={9} />
          </Flex>
          <div className={'LessonListItemArrow'}>
            <DownOutlined />
          </div>
        </Space>
      </div>
      <Collapse isOpened={isOpen}>
        <div className={`LessonListItemContent`}>
          {props.lesson.items?.map((item, i) => (
            <div
              className={'DetailItem'}
              key={i}
              onClick={() => props.goToDetail(item.type as CourseLessonItemType, item.codingQuestion?.id || item.courseArticle?.id || 0)}>
              <CourseLessonDetailIcon detail={item} />
              <Text ellipsis>{item.codingQuestion?.topic || item.courseArticle?.topic}</Text>
              {item.codingQuestion && (
                <React.Fragment>
                  {item.isSolved ? <img className={'DetailSolveIcon'} src={pass} /> : <img className={'DetailSolveIcon'} src={unpass} />}
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
}
