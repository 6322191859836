import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {ITabLayoutRouteConfig, TabLayout} from '../TabLayout/TabLayout';
import {CarryOutTwoTone, CarTwoTone} from '@ant-design/icons';
import LayoutHeader from '../components/layouts/LayoutHeader';
import TabLayoutProvider from '../TabLayout/TabLayoutProvider';
import ManageGroupPage from './manage/group';
import useAuthCheck from '../utils/useAuthCheck';
import {useMount} from 'react-use';
import {QuestionType, UserRole} from '../scaffold';
import useAuth from '../utils/AuthContext';
import ManageUserPage from './manage/user';
import ManageQuestionPage from './manage/question/manage';
import PaperEditPage from './manage/paper/edit';
import ManagePaperListPage from './manage/paper/list';
import ManagePaperPreviewPage from './manage/paper/preview';
import ManageExamListPage from './manage/exam/list';
import ManageExamSubmissionPage from './manage/exam/submission';
import AppExamSubmissionResultPage from './app/exam/result';
import ManageExamSubmissionMarkPage from './manage/exam/mark';
import AppIndexPage from './app';
import WorkingIndexPage from './app/working';
import ManageExamAnalysePage from './manage/exam/analyse';
import ManageExamUserPage from './manage/exam/user';
import AppCodingQuestionDetailPage from './app/codingQuestion/detail';
import ManageCodingSubmissionPage from './manage/question/codingSubmission';
import ManageCourseListPage from './manage/course/list';
import ManageCourseArticleListPage from './manage/course/listArticle';
import ManageCourseLessonPage from './manage/course/lesson';
import ManageHomePage from './manage/home';
import ManageExamAnalyseCodingQuestionPage from './manage/exam/analyseCodingQuestion';
import ManageImportCodingQuestionPage from './manage/question/importCodingQuestion';
import ManageNoticeListPage from './manage/system/list';

function ManagePage() {
  const {path, url} = useRouteMatch();
  const checkAuth = useAuthCheck();
  const routeConfig: ITabLayoutRouteConfig = {
    menus: [
      {title: '首页', icon: <CarTwoTone />, path: `${path}/`},
      {
        title: '考试',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '考试管理',
            submenus: [{path: `${path}/exam/list`, title: '考试列表'}],
          },
        ],
      },
      {
        title: '试卷',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '试卷管理',
            submenus: [
              {path: `${path}/paper/edit/`, title: '新建试卷'},
              {path: `${path}/paper/list`, title: '试卷列表'},
            ],
          },
        ],
      },
      {
        title: '题库',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '题库管理',
            submenus: [
              {path: `${path}/question/${QuestionType.选择题}`, title: '选择题管理'},
              {path: `${path}/question/${QuestionType.填空题}`, title: '填空题管理'},
              {path: `${path}/question/${QuestionType.论述题}`, title: '论述题管理'},
              {path: `${path}/question/${QuestionType.操作题}`, title: '操作题管理'},
              {path: `${path}/question/${QuestionType.编程题}`, title: '编程题管理'},
            ],
          },
        ],
      },
      {
        title: '课程',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '课程管理',
            submenus: [
              {path: `${path}/course/list`, title: '课程列表'},
              {path: `${path}/course/listArticle`, title: '教案列表'},
            ],
          },
        ],
      },
      {
        title: '用户与班级',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '用户与班级',
            submenus: [
              {path: `${path}/group`, title: '班级管理'},
              {path: `${path}/user/${UserRole.学生}`, title: '学生管理'},
              {path: `${path}/user/${UserRole.教师}`, title: '教师管理'},
            ],
          },
        ],
      },
      {
        title: '系统设置',
        icon: <CarTwoTone />,
        submenus: [
          {
            title: '',
            submenus: [{path: `${path}/system/listNotice`, title: '通知管理'}],
          },
        ],
      },
    ],
    routes: [
      {component: ManageGroupPage, path: `${path}/group`, title: '班级管理'},
      {component: ManageImportCodingQuestionPage, path: `${path}/importCodingQuestion`, title: '从致远导入编程题'},
      {component: ManageQuestionPage, path: `${path}/question/:type`, title: '题目管理'},
      {component: ManageUserPage, path: `${path}/user/:role`, title: '用户管理'},

      {component: ManagePaperListPage, path: `${path}/paper/list`, title: '试卷列表'},
      {component: ManagePaperPreviewPage, path: `${path}/paper/preview/:id`, title: '预览试卷'},
      {component: PaperEditPage, path: `${path}/paper/edit/`, title: '新建试卷'},
      {component: PaperEditPage, path: `${path}/paper/edit/:id`, title: '试卷编辑'},

      {component: ManageExamListPage, path: `${path}/exam/list`, title: '考试列表'},
      {component: ManageExamSubmissionPage, path: `${path}/exam/submission/:id`, title: '考试成绩'},
      {component: ManageExamAnalysePage, path: `${path}/exam/analyse/:id`, title: '考试分析'},
      {component: ManageExamAnalyseCodingQuestionPage, path: `${path}/exam/analyseCodingQuestion/:id`, title: '编程题排名'},
      {component: AppExamSubmissionResultPage, path: `${path}/exam/submission/result/:id`, title: '查看答卷'},
      {component: ManageExamSubmissionMarkPage, path: `${path}/exam/submission/mark/:id`, title: '批改答卷'},
      {component: ManageExamUserPage, path: `${path}/exam/user/:id`, title: '考试记录'},
      {component: WorkingIndexPage, path: `${path}/working`, title: '正在开发中'},
      {component: ManageHomePage, path: `${path}/`, title: '首页'},
      {component: AppCodingQuestionDetailPage, path: `${path}/codingQuestion/:id`, title: '题目详情'},
      {component: ManageCodingSubmissionPage, path: `${path}/question/:id/codingSubmission`, title: '提交记录'},

      {component: ManageCourseListPage, path: `${path}/course/list`, title: '课程列表'},
      {component: ManageCourseLessonPage, path: `${path}/course/edit/:id`, title: '编辑课程'},
      {component: ManageCourseArticleListPage, path: `${path}/course/listArticle`, title: '教案列表'},

      {component: ManageNoticeListPage, path: `${path}/system/listNotice`, title: '通知管理'},

      // {component: BPage, path: `${path}/b`, title: '分组 B'},
      // {component: CPage, path: `${path}/c/:id`, title: '分组 C'},
    ],
  };
  const history = useHistory();
  function logout() {
    localStorage.removeItem('Application-Token');
    history.push('/login');
  }
  const auth = useAuth();
  useMount(() => {
    if (!auth.currentUser) checkAuth.check();
    else if (auth.currentUser.role !== UserRole.管理员 && auth.currentUser.role !== UserRole.教师) {
      history.push('/login');
    }
  });
  return (
    <TabLayoutProvider basePath={path}>
      {checkAuth.isChecking && <div>自动登录中</div>}
      {!checkAuth.isChecking && auth.currentUser && <TabLayout routeConfig={routeConfig} customHeader={<LayoutHeader />} logout={logout} />}
    </TabLayoutProvider>
  );
}
export default ManagePage;
