import React from 'react';
import {ExamQuestionSubmitInfo} from '../../pages/app/exam/submit';
import QuestionDisplay from '../Question/QuestionDisplay';
import {ChoiceQuestionDto, QuestionType} from '../../scaffold';
import {Checkbox, Input, Radio, Space} from 'antd';
import Text from 'antd/lib/typography/Text';
import LiveExMdEditor from '../Shared/LiveExMdEditor';
import {useTabLayout} from '../../TabLayout/TabLayoutProvider';

export default function QuestionSubmitInput(props: {info: ExamQuestionSubmitInfo; onChange: (info: ExamQuestionSubmitInfo) => any}) {
  const question = props.info.question;
  const labels = 'ABCDEFGHIJKLMNOPQ';
  const choiceQuestion = question as ChoiceQuestionDto;
  const tabLayout = useTabLayout();
  return (
    <div>
      <QuestionDisplay question={question} hideRightAnswer />
      <div className={'m-t-10'} style={{background: '#EEEFF3', padding: '10px'}}>
        {question.type === QuestionType.编程题 && (
          <div>
            编程题请点击<a onClick={() => tabLayout.goTo('/app/codingQuestion/' + question.id)}>此链接</a>答题
          </div>
        )}
        {question.type === QuestionType.选择题 && (
          <Space>
            <Text>请选择:</Text>
            {choiceQuestion.isMultiple ? (
              <Checkbox.Group value={props.info.userInput.split('')} onChange={e => props.onChange({...props.info, userInput: e.join('')})}>
                {choiceQuestion.options?.map((opt, i) => (
                  <Checkbox key={i} value={labels[i]}>
                    {labels[i]}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            ) : (
              <Radio.Group size={'large'} value={props.info.userInput} onChange={e => props.onChange({...props.info, userInput: e.target.value})}>
                {choiceQuestion.options?.map((opt, i) => (
                  <Radio key={i} value={labels[i]}>
                    {labels[i]}
                  </Radio>
                ))}
              </Radio.Group>
            )}
          </Space>
        )}
        {question.type === QuestionType.填空题 && (
          <Space style={{width: '100%'}}>
            <Text>请输入答案:</Text>
            <Input
              style={{width: 400}}
              size={'large'}
              value={props.info.userInput}
              placeholder={'请输入...'}
              onChange={e => props.onChange({...props.info, userInput: e.target.value})}
            />
          </Space>
        )}
        {question.type === QuestionType.论述题 && (
          <Space style={{width: '100%'}}>
            <Text>请输入答案:</Text>
            <LiveExMdEditor
              value={props.info.userInput}
              placeholder={'请输入...'}
              onChange={e => props.onChange({...props.info, userInput: e})}
              style={{padding: '5px 15px'}}
            />
          </Space>
        )}
      </div>
    </div>
  );
}
